import { SnackbarProps } from "@material-ui/core";
import { IExceptionTelemetry, SeverityLevel } from "@microsoft/applicationinsights-web";
import { Initialization } from "@microsoft/applicationinsights-web/dist-esm/Initialization";
import { useCallback } from "react";
import { useAppInsights } from "./AppInsights";
import { ErrorMessage } from "./ErrorMessage";
import { isProblemDetails, ProblemDetails } from "./ProblemDetails";
import { SetSnack, useSetSnack } from "./SnackContext";
import { SnackState } from "./SnackState";

/**
 * Object that best represents the original error.
 */
export type ErrorSource = ProblemDetails | Error | Response;

export type ShowError = (
    userMessage: ErrorMessage | string,
    source?: ErrorSource,
    snackbarProps?: SnackbarProps
) => Promise<void>;

const defaultErrorSnack: Partial<SnackState> = {
    severity: "error",
};

const defaultAppInsightsException: Partial<IExceptionTelemetry> = {
    severityLevel: SeverityLevel.Error,
};

const tryGetProblemDetails = async (error: Response): Promise<ProblemDetails | undefined> => {
    console.log("[tryGetProblemDetails]", error);
    try {
        return (await error.json()) as ProblemDetails;
    } catch (ex) {
        console.log("[tryGetProblemDetails]", error, ex);
    }
};

const showErrorNoDetails = (options: {
    userMessage: string;
    appInsights: Initialization | undefined;
    setSnack: SetSnack;
    snackbarProps?: SnackbarProps;
}): void => {
    const { userMessage, appInsights, setSnack, snackbarProps } = options;
    appInsights?.trackException &&
        appInsights.trackException({
            ...defaultAppInsightsException,
            properties: { userMessage },
        });
    setSnack({
        ...defaultErrorSnack,
        snackbarProps,
        children: userMessage,
    });
};

const showErrorError = (options: {
    userMessage: string;
    error: Error;
    appInsights: Initialization | undefined;
    setSnack: SetSnack;
    snackbarProps?: SnackbarProps;
}): void => {
    const { userMessage, error, appInsights, setSnack, snackbarProps } = options;
    const properties = {
        userMessage,
        errorName: error.name,
        errorMessage: error.message,
        stackTrace: error.stack,
    };
    appInsights?.trackException &&
        appInsights.trackException({
            ...defaultAppInsightsException,
            exception: error,
            properties,
        });
    setSnack({
        ...defaultErrorSnack,
        snackbarProps,
        children: userMessage,
    });
};

const showProblemDetailsError = (options: {
    userMessage: string;
    problemDetails: ProblemDetails;
    appInsights: Initialization | undefined;
    setSnack: SetSnack;
    snackbarProps?: SnackbarProps;
}): void => {
    const { userMessage, problemDetails, appInsights, setSnack, snackbarProps } = options;
    const properties = {
        userMessage,
        errorStatus: problemDetails.status,
        errorType: problemDetails.type,
        errorTitle: problemDetails.title,
        errorDetail: problemDetails.detail,
        errorException: problemDetails.exception,
    };
    appInsights?.trackException &&
        appInsights.trackException({
            ...defaultAppInsightsException,
            properties,
        });
    setSnack({
        ...defaultErrorSnack,
        snackbarProps,
        children: userMessage,
    });
};

const showObjectError = (options: {
    userMessage: string;
    source: Response;
    appInsights: Initialization | undefined;
    setSnack: SetSnack;
    snackbarProps?: SnackbarProps;
}): void => {
    const { userMessage, source: error, appInsights, setSnack, snackbarProps } = options;
    const properties = {
        userMessage,
        ...error,
    };
    try {
        // not the stupidest thing I've ever done
        throw new Error(userMessage);
    } catch (error) {
        appInsights?.trackException &&
            appInsights.trackException({
                ...defaultAppInsightsException,
                exception: error,
                properties,
            });
    }
    setSnack({
        ...defaultErrorSnack,
        snackbarProps,
        children: userMessage,
    });
};

export const useShowError = (): ShowError => {
    const appInsights = useAppInsights();
    const { setSnack } = useSetSnack();
    const showError: ShowError = useCallback(
        async (userMessage, source, snackbarProps) => {
            if (source === undefined) {
                console.error(userMessage);
                showErrorNoDetails({ userMessage, appInsights, setSnack });
            } else if (source instanceof Error) {
                console.error(userMessage);
                console.log(source);
                showErrorError({ userMessage, error: source, appInsights, setSnack });
            } else if (isProblemDetails(source)) {
                const problemDetails = source;
                const { detail, exception } = problemDetails;
                console.error(userMessage);
                console.log(source);
                console.log(problemDetails);
                console.log(exception || detail);
                showProblemDetailsError({ userMessage, problemDetails, appInsights, setSnack });
            } else if (source instanceof Response) {
                const problemDetails = await tryGetProblemDetails(source);
                if (problemDetails) {
                    showError(userMessage, problemDetails, snackbarProps);
                    // const { detail, exception } = problemDetails;
                    // console.error(userMessage);
                    // console.log(error);
                    // console.log(problemDetails);
                    // console.log(exception || detail);
                    // showProblemDetailsError({ userMessage, problemDetails, appInsights, setSnack });
                    // if (doThrow) {
                    //     throw problemDetails;
                    // }
                } else {
                    console.error(userMessage);
                    console.log(source);
                    showObjectError({ userMessage, source: source, appInsights, setSnack });
                }
            }
        },
        [appInsights, setSnack]
    );
    return showError;
};
