import constate from "constate";
import { FileObject } from "material-ui-dropzone";
import { useState } from "react";
import { useAuth } from "./Auth";

export enum UserFeedbackMood {
    Happy = "happy",
    Sad = "sad",
}

interface UserFeedback {
    mood?: UserFeedbackMood;
    user: string;
    url: string;
    comments: string;
    attachments: FileObject[];
    attachmentsExpanded: boolean;
}

const useUserFeedbackState = (): {
    userFeedback: UserFeedback;
    setUserFeedbackComments: (comments: string) => void;
    setUserFeedbackMood: (mood: UserFeedbackMood | undefined) => void;
    setUserFeedbackAttachments: (attachments: FileObject[]) => void;
    setUserFeedbackAttachmentsExpanded: (attachmentsExpanded: boolean) => void;
    clearUserFeedback: () => void;
} => {
    const { account } = useAuth();
    const defaultFeedback: () => UserFeedback = () => ({
        user: account?.userName || "",
        url: window.location.href,
        comments: "",
        attachments: [],
        attachmentsExpanded: false,
    });

    const [userFeedback, setUserFeedback] = useState<UserFeedback>(defaultFeedback);
    const setUserFeedbackComments = (comments: string) => setUserFeedback((prev) => ({ ...prev, comments }));
    const setUserFeedbackMood = (mood: UserFeedbackMood | undefined) => setUserFeedback((prev) => ({ ...prev, mood }));
    const setUserFeedbackAttachments = (attachments: FileObject[]) =>
        setUserFeedback((prev) => ({ ...prev, attachments }));
    const setUserFeedbackAttachmentsExpanded = (attachmentsExpanded: boolean) =>
        setUserFeedback((prev) => ({ ...prev, attachmentsExpanded }));
    const clearUserFeedback = (): void => setUserFeedback(defaultFeedback);
    return {
        userFeedback,
        setUserFeedbackComments,
        setUserFeedbackMood,
        setUserFeedbackAttachments,
        setUserFeedbackAttachmentsExpanded,
        clearUserFeedback,
    };
};

const [UserFeedbackProvider, useUserFeedback] = constate(useUserFeedbackState);

export { UserFeedbackProvider, useUserFeedback };
