import ArrowDropDownFilledIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import ArrowDropDownSharpIcon from "@material-ui/icons/ArrowDropDownSharp";
import ArrowDropDownTwoToneIcon from "@material-ui/icons/ArrowDropDownTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const arrowDropDownIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return ArrowDropDownFilledIcon;
        case IconStyle.Outlined:
            return ArrowDropDownOutlinedIcon;
        case IconStyle.Rounded:
            return ArrowDropDownRoundedIcon;
        case IconStyle.TwoTone:
            return ArrowDropDownTwoToneIcon;
        case IconStyle.Sharp:
            return ArrowDropDownSharpIcon;
    }
};

export const ArrowDropDownIcon = createIconComponent(arrowDropDownIconSelector);
