import * as Yup from "yup";
import {
    DateParameterTypeKey,
    NumberParameterTypeKey,
    StringParameterTypeKey,
    WebApiChecklistTemplateParameter,
} from "../../Api";

export function getYupObjectSchemaForChecklistParameter({
    isRequired,
    type,
    label,
}: WebApiChecklistTemplateParameter): unknown {
    if (!isRequired) {
        return undefined;
    }

    switch (type) {
        case StringParameterTypeKey:
            return Yup.string().required(`${label} is required.`);

        case DateParameterTypeKey:
            return Yup.date().typeError(`${label} is required.`);

        case NumberParameterTypeKey:
            return Yup.number().typeError(`${label} must be a number.`).required(`${label} is required.`);
    }
}
