import constate from "constate";
import { useEffect, useState } from "react";
import { usePageSubTitleContext } from "./PageSubTitleContext";

const usePageTitle = (): {
    pageTitle: string;
    setPageTitle(pageTitle: string): void;
    clearPageTitle: () => void;
} => {
    const { setPageSubTitle } = usePageSubTitleContext();
    const [pageTitle, setPageTitle] = useState("");
    const clearPageTitle = (): void => {
        if (pageTitle) {
            setPageTitle("");
        }
    };
    useEffect(() => {
        setPageSubTitle("");
    }, [pageTitle, setPageSubTitle]);
    return { pageTitle, setPageTitle, clearPageTitle };
};

const [PageTitleProvider, usePageTitleContext] = constate(usePageTitle);

export { PageTitleProvider, usePageTitleContext };
