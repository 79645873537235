import { Divider, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from "@material-ui/core";
import useEventListener from "@use-it/event-listener";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { KeyboardEventHandler, useState } from "react";
import { RecordMetadata } from "./DataFileTypes";

export interface DataFileSearchByExampleProps {
    /** Possible record datas to search */
    recordMetadatas: RecordMetadata[];
    /** An event emitted when the user chooses a record number */
    onSubmit(recordMetadataId: string, example: Record<string, string>): void;
    /** An event emitted when the user presses page down */
    onMoveFirst(): void;
    /** An event emitted when the user presses page up */
    onMoveLast(): void;
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%",
    },
    formDivContainer: {
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    recordTypeDivider: {
        marginTop: theme.spacing(2),
        marginBottm: theme.spacing(2),
    },
}));

export const DataFileSearchByExample: React.FC<DataFileSearchByExampleProps> = ({
    recordMetadatas,
    onSubmit,
    onMoveFirst,
    onMoveLast,
}) => {
    const classes = useStyles();
    const [recordMetadataId, setRecordMetadataId] = useState(recordMetadatas[0].recordType);

    const handleFormSubmit: (values: Record<string, string>, actions: FormikHelpers<Record<string, string>>) => void = (
        values,
        { setSubmitting }
    ) => {
        setSubmitting(false);
        onSubmit(recordMetadataId, values);
    };

    /**
     * When a form has just one input and the user presses enter while the input is focused, the form is submitted.
     * I'd like to have the same code here whether there is just one input or there are multiple inputs.  For that
     * reason, let's use this keyhandler and preventDefault.
     */
    const handleTextFieldKeydown: (submitForm: () => Promise<void>) => KeyboardEventHandler<HTMLInputElement> = (
        submitForm
    ) => (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            submitForm();
        }
    };

    // const recordNumberErrorMessage = "Record number must be a positive integer";

    const initialValues: Record<string, string> = {};

    const handleDocumentKeydown = (event: KeyboardEvent) => {
        if (event.key === "PageDown") {
            event.preventDefault();
            onMoveFirst();
        } else if (event.key === "PageUp") {
            event.preventDefault();
            onMoveLast();
        }
    };
    useEventListener("keydown", handleDocumentKeydown);

    const handleChangeRecordMetadataId = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        setRecordMetadataId(event.target.value as string);
    };

    const selectedRecordMetadata =
        recordMetadatas.find((rm) => rm.recordType === recordMetadataId) || recordMetadatas[0];

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            // validationSchema={Yup.object({
            //     recordNumber: Yup.number()
            //         .typeError(recordNumberErrorMessage)
            //         .integer(recordNumberErrorMessage)
            //         .positive(recordNumberErrorMessage)
            //         .required("Record number is required"),
            // })}
            // validateOnBlur={false}
        >
            {({ submitForm }) => (
                <Form className={classes.form}>
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                        }}
                    >
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-helper-label">Record Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={recordMetadataId}
                                onChange={handleChangeRecordMetadataId}
                            >
                                {recordMetadatas.map((rm, i) => (
                                    <MenuItem key={i} value={rm.recordType}>
                                        {rm.recordType}
                                    </MenuItem>
                                ))}
                            </Select>
                            {/* <FormHelperText>Some important helper text</FormHelperText> */}
                        </FormControl>
                    </div>
                    <Divider className={classes.recordTypeDivider} />
                    <div className={classes.formDivContainer}>
                        {selectedRecordMetadata.keyFields.map((kf, i) => (
                            <Field key={i} name={kf.name}>
                                {({ field, meta: { error, touched } }: FieldProps): React.ReactNode => (
                                    <TextField
                                        {...field}
                                        value={field.value || ""}
                                        label={kf.label}
                                        error={Boolean(touched && error)}
                                        helperText={touched && error}
                                        autoFocus={true}
                                        onKeyDown={handleTextFieldKeydown(submitForm)}
                                    ></TextField>
                                )}
                            </Field>
                        ))}
                    </div>
                </Form>
            )}
        </Formik>
    );
};
