import UpdateFilledIcon from "@material-ui/icons/Update";
import UpdateOutlinedIcon from "@material-ui/icons/UpdateOutlined";
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded";
import UpdateSharpIcon from "@material-ui/icons/UpdateSharp";
import UpdateTwoToneIcon from "@material-ui/icons/UpdateTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const updateIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return UpdateFilledIcon;
        case IconStyle.Outlined:
            return UpdateOutlinedIcon;
        case IconStyle.Rounded:
            return UpdateRoundedIcon;
        case IconStyle.TwoTone:
            return UpdateTwoToneIcon;
        case IconStyle.Sharp:
            return UpdateSharpIcon;
    }
};

export const UpdateIcon = createIconComponent(updateIconSelector);
