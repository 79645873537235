import { useCallback } from "react";
import { QueryObserverResult, useQuery, UseQueryOptions } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSpinnerEffect } from "../SpinnerContext";
import { useAuthorizedFetch } from "./AuthorizedFetch";
import { cleanseWebApiChecklistTemplate } from "./cleanseWebApiChecklistTemplate";
import { WebApiChecklistTemplate } from "./WebApiTypes";

export function useChecklistTemplates(
    queryConfig?: UseQueryOptions<WebApiChecklistTemplate[]>
): QueryObserverResult<WebApiChecklistTemplate[]> {
    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();
    const apiUrl = "checklist-templates";

    const getChecklistTemplates = useCallback(async () => {
        const response = await authorizedFetch(apiUrl);
        if (response.status === 204) {
            return [];
        } else if (response.status >= 200 && response.status < 300) {
            const json = await response.text();
            try {
                const parsed = JSON.parse(json) as WebApiChecklistTemplate[];
                const cleansed = parsed.map(cleanseWebApiChecklistTemplate);
                return cleansed;
            } catch (ex) {
                showError(ErrorMessage.FailedToParseChecklistTemplates, response);
                throw ex;
            }
        } else {
            showError(ErrorMessage.FailedToLoadChecklistTemplates, response);
            throw new Error(ErrorMessage.FailedToLoadChecklistTemplates);
        }
    }, [authorizedFetch, showError]);

    const query = useQuery(["checklist-templates"], getChecklistTemplates, {
        staleTime: 5 * 60 * 1000,
        ...queryConfig,
    });

    useSpinnerEffect(query.isLoading, apiUrl);

    return query;
}
