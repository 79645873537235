import CancelFilledIcon from "@material-ui/icons/Cancel";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import CancelSharpIcon from "@material-ui/icons/CancelSharp";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const cancelIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return CancelFilledIcon;
        case IconStyle.Outlined:
            return CancelOutlinedIcon;
        case IconStyle.Rounded:
            return CancelRoundedIcon;
        case IconStyle.TwoTone:
            return CancelTwoToneIcon;
        case IconStyle.Sharp:
            return CancelSharpIcon;
    }
};

export const CancelIcon = createIconComponent(cancelIconSelector);
