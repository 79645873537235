import * as React from "react";
import { GuestbookContainer } from "./GuestbookContainer";

/**
 * Routing component for the guestbook.
 */
export const GuestbookPage: React.FC = () => {
    // If needed, we could add logic here to further "route" the user based on the url.
    return <GuestbookContainer />;
};
