import {
    Avatar,
    Badge,
    Button,
    Card,
    CardContent,
    CardHeader,
    createStyles,
    IconButton,
    Link,
    makeStyles,
    Menu,
    MenuItem,
    Theme,
    Toolbar,
    Typography,
} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import AccountCircle from "@material-ui/icons/AccountCircle";
import * as React from "react";
import { MouseEventHandler, useCallback, useMemo } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
    convertRefetchIntervalToQueryConfig,
    useMyActiveChecklistSummaries,
    useUserSettings,
    useVerifiedGravatarUrl,
} from "./Api";
import { AppHeader, AppHeaderButton } from "./AppLayout";
import { useAuth } from "./Auth";
import { shortSiteName } from "./Customization";
import { CloseIcon, FeedbackIcon, MenuIcon, NotificationsActiveIcon, NotificationsNoneIcon } from "./Icons";
import { WineLogoImage } from "./Images";
import { useMainMenuOpen, useSetMainMenuOpen } from "./MainMenuOpenContext";
import { usePageSubTitleContext } from "./PageSubTitleContext";
import { usePageTitleContext } from "./PageTitleContext";
import { Search } from "./Search";
import { UserFeedback } from "./UserFeedback";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            flexGrow: 1,
        },
        logo: {
            height: 48,
            marginRight: theme.spacing(3),
        },
    })
);

export const Notifications: React.FC = () => {
    const { hubPageRefetchIntervalInSeconds } = useUserSettings();
    const queryConfig = useMemo(() => convertRefetchIntervalToQueryConfig(hubPageRefetchIntervalInSeconds), [
        hubPageRefetchIntervalInSeconds,
    ]);
    const { data } = useMyActiveChecklistSummaries(queryConfig);
    const count = useMemo(() => data?.length, [data]);
    const [notificationWindowAnchor, setNotificationWindowAnchor] = React.useState<HTMLButtonElement | null>(null);

    return useMemo(() => {
        const handleNotificationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setNotificationWindowAnchor(event.currentTarget);
        };

        const handleNotificationClose = () => setNotificationWindowAnchor(null);

        return !count ? (
            <IconButton color="inherit">
                <NotificationsNoneIcon />
            </IconButton>
        ) : (
            <>
                <IconButton onClick={handleNotificationClick} color="inherit">
                    <Badge badgeContent={count} color="error" overlap="rectangle" variant="dot">
                        <NotificationsActiveIcon />
                    </Badge>
                </IconButton>
                <Popover
                    open={Boolean(notificationWindowAnchor)}
                    anchorEl={notificationWindowAnchor}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    onClose={handleNotificationClose}
                >
                    <Card>
                        <CardHeader
                            title="Notifications"
                            action={
                                <IconButton aria-label="cancel" onClick={handleNotificationClose}>
                                    <CloseIcon />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <Typography variant="body1" gutterBottom>
                                There are checklist steps assigned to you. You can see them on the{" "}
                                <Link
                                    component={RouterLink}
                                    to={`/hubs/my-tasks`}
                                    onClick={() => handleNotificationClose()}
                                >
                                    &ldquo; My Tasks&rdquo;
                                </Link>{" "}
                                page.
                            </Typography>
                        </CardContent>
                    </Card>
                </Popover>
            </>
        );
    }, [count, notificationWindowAnchor]);
};

export const Header: React.FC = () => {
    const { pageTitle } = usePageTitleContext();
    const { pageSubTitle } = usePageSubTitleContext();

    const { mainMenuOpen } = useMainMenuOpen();
    const { setMainMenuOpen } = useSetMainMenuOpen();
    const { isUserLoggedIn, account, logout, login } = useAuth();
    const { enableSearch, fallbackAvatar } = useUserSettings();

    const history = useHistory();
    const classes = useStyles();
    const [accountMenuAnchor, setAccountMenuAnchor] = React.useState<HTMLButtonElement | null>(null);
    const [feedbackWindowAnchor, setFeedbackWindowAnchor] = React.useState<HTMLButtonElement | null>(null);
    const isAccountMenuOpen = Boolean(accountMenuAnchor);
    const isFeedbackWindowOpen = Boolean(feedbackWindowAnchor);

    const email = account?.userName || "";
    const gravatarUrl = useVerifiedGravatarUrl({ email, fallbackAvatar });

    const accountBox = useMemo(() => {
        const handleAvatarClick: MouseEventHandler<HTMLButtonElement> = (event) => {
            setAccountMenuAnchor(event.currentTarget);
        };

        const handleAccountMenuClose = (): void => {
            setAccountMenuAnchor(null);
        };

        const handleProfileClick = (): void => {
            history.push("/profile");
            setAccountMenuAnchor(null);
        };

        const handleLogoutClick: MouseEventHandler = () => {
            setAccountMenuAnchor(null);
            logout();
            history.push("/");
        };

        const avatar = gravatarUrl ? <Avatar src={gravatarUrl} /> : <AccountCircle />;

        return isUserLoggedIn ? (
            <>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleAvatarClick}
                    color="inherit"
                >
                    {avatar}
                </IconButton>
                <Menu
                    id="menu-appbar"
                    open={isAccountMenuOpen}
                    anchorEl={accountMenuAnchor}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    onClose={handleAccountMenuClose}
                >
                    <MenuItem onClick={handleProfileClick}>My profile</MenuItem>
                    <MenuItem onClick={handleLogoutClick}>Log out</MenuItem>
                </Menu>
            </>
        ) : (
            <>
                <Button color="inherit" onClick={() => login()}>
                    Login
                </Button>
            </>
        );
    }, [gravatarUrl, isUserLoggedIn, isAccountMenuOpen, accountMenuAnchor, history, logout, login]);

    const feedbackToggle = useMemo(() => {
        const handleFeedbackClick = (event: React.MouseEvent<HTMLButtonElement>) =>
            setFeedbackWindowAnchor(event.currentTarget);

        const handleFeedbackClose = () => setFeedbackWindowAnchor(null);

        return (
            <>
                <IconButton
                    aria-label="Send feedback to Twin Oak Solutions"
                    onClick={handleFeedbackClick}
                    color="inherit"
                >
                    <FeedbackIcon />
                </IconButton>

                <Popover
                    open={isFeedbackWindowOpen}
                    anchorEl={feedbackWindowAnchor}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    onClose={handleFeedbackClose}
                >
                    <UserFeedback onClose={handleFeedbackClose} />
                </Popover>
            </>
        );
    }, [setFeedbackWindowAnchor, isFeedbackWindowOpen, feedbackWindowAnchor]);

    const handleAppHeaderButtonClick = useCallback(() => setMainMenuOpen(true), [setMainMenuOpen]);

    const buttonChildren = useMemo(() => <MenuIcon />, []);

    return useMemo(
        () => (
            <AppHeader mainMenuOpen={mainMenuOpen}>
                <Toolbar>
                    <AppHeaderButton onClick={handleAppHeaderButtonClick} mainMenuOpen={mainMenuOpen}>
                        {buttonChildren}
                    </AppHeaderButton>
                    <img src={WineLogoImage} alt="Logo" className={classes.logo} />
                    <Typography variant="h5" className={classes.title}>
                        {pageTitle ? `${shortSiteName} ${pageTitle}` : shortSiteName}
                    </Typography>
                    {enableSearch && isUserLoggedIn ? (
                        <Search />
                    ) : (
                        <Typography variant="body1" className={classes.title}>
                            {pageSubTitle}
                        </Typography>
                    )}
                    {isUserLoggedIn && <Notifications />}
                    {isUserLoggedIn ? feedbackToggle : <></>}
                    {accountBox}
                </Toolbar>
            </AppHeader>
        ),
        [
            mainMenuOpen,
            handleAppHeaderButtonClick,
            buttonChildren,
            classes.logo,
            classes.title,
            pageTitle,
            enableSearch,
            isUserLoggedIn,
            pageSubTitle,
            feedbackToggle,
            accountBox,
        ]
    );
};
