import { useCallback } from "react";
import isEqual from "react-fast-compare";
import { useStorageState } from "react-storage-hooks";
import { useAppConfigQuery } from "../Api/useAppConfigQuery";
import { sessionStorageKeys } from "../sessionStorageKeys";
import { AppConfig } from "./AppConfig";
import { SecurityScheme } from "./SecurityScheme";

const defaultAppConfig = {
    securityScheme: SecurityScheme.Unsecured,
    apiBaseUrl: "",
    authority: "",
    clientId: "",
    scopes: [],
    applicationInsightsInstrumentationKey: "",
};

export const useAppConfig: () => AppConfig = () => {
    const [cache, setCache] = useStorageState<AppConfig>(
        sessionStorage,
        sessionStorageKeys.appConfig,
        defaultAppConfig
    );
    const onSuccess = useCallback(
        (data: AppConfig) => {
            if (!isEqual(data, cache)) {
                setCache(data);
            }
        },
        [cache, setCache]
    );
    const { data } = useAppConfigQuery({ staleTime: Infinity, refetchInterval: 5 * 60 * 1000, onSuccess });
    return data || cache;
};
