import CloseFilledIcon from "@material-ui/icons/Close";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const closeIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return CloseFilledIcon;
        case IconStyle.Outlined:
            return CloseOutlinedIcon;
        case IconStyle.Rounded:
            return CloseRoundedIcon;
        case IconStyle.TwoTone:
            return CloseTwoToneIcon;
        case IconStyle.Sharp:
            return CloseSharpIcon;
    }
};

export const CloseIcon = createIconComponent(closeIconSelector);
