import { Badge, BadgeProps, makeStyles } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
    badgeError: {
        backgroundColor: theme.palette.error.dark,
    },
}));

interface LateBadgeProps {
    isLate: boolean;
    childType: "ChecklistSummaryIcon" | "ChecklistStepIcon";
}

export const LateBadge: React.FC<LateBadgeProps> = ({ children, isLate, childType }) => {
    const classes = useStyles();
    const props: BadgeProps = childType === "ChecklistSummaryIcon" ? {} : { overlap: "circle" };
    return isLate ? (
        <Badge badgeContent={"LATE"} color="error" classes={{ badge: classes.badgeError }} {...props}>
            {children}
        </Badge>
    ) : (
        <>{children}</>
    );
};
