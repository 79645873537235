import * as React from "react";
import { useMemo } from "react";
import { useMenuTree } from "./Api/useMenuTree";
import { useAuth } from "./Auth";
import { Nav } from "./Nav";

const AuthenticatedNavContainer: React.FC = () => {
    const menuTree = useMenuTree();
    return useMemo(() => <Nav menuTree={menuTree} />, [menuTree]);
};

export const NavContainer: React.FC = () => {
    const { isUserLoggedIn } = useAuth();

    return isUserLoggedIn ? <AuthenticatedNavContainer /> : <Nav />;
};
