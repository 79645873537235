import { ThemeOptions } from "@material-ui/core";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

export const shortSiteName = "Bob's Wine Bar";

const customPalette: PaletteOptions = {
    primary: {
        main: "#5A2328",
    },
    secondary: {
        main: "#7E9D7B",
    },
};

export const applyPalette = (themeOptions: ThemeOptions): ThemeOptions => {
    return {
        ...themeOptions,
        palette: {
            ...themeOptions.palette,
            ...customPalette,
        },
    };
};
