import LastPageFilledIcon from "@material-ui/icons/LastPage";
import LastPageOutlinedIcon from "@material-ui/icons/LastPageOutlined";
import LastPageRoundedIcon from "@material-ui/icons/LastPageRounded";
import LastPageSharpIcon from "@material-ui/icons/LastPageSharp";
import LastPageTwoToneIcon from "@material-ui/icons/LastPageTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const lastPageIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return LastPageFilledIcon;
        case IconStyle.Outlined:
            return LastPageOutlinedIcon;
        case IconStyle.Rounded:
            return LastPageRoundedIcon;
        case IconStyle.TwoTone:
            return LastPageTwoToneIcon;
        case IconStyle.Sharp:
            return LastPageSharpIcon;
    }
};

export const LastPageIcon = createIconComponent(lastPageIconSelector);
