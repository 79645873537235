import {
    Button,
    ButtonProps,
    ClickAwayListener,
    createStyles,
    Grid,
    Grow,
    makeStyles,
    MenuItem,
    MenuList,
    Popper,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import * as React from "react";
import { useMemo, useRef, useState } from "react";

export interface SplitButtonOptionProps {
    value: string;
    onClick?: () => void;
}

export type SplitButtonProps = Pick<ButtonProps, "startIcon" | "endIcon" | "disabled" | "variant" | "color"> & {
    value: string;
    options: SplitButtonOptionProps[];
};

const useStyles = makeStyles(() =>
    createStyles({
        gridContainer: {},
        gridItem: {},
        popper: {
            zIndex: 1101,
        },
    })
);

export const SplitButton: React.FC<SplitButtonProps> = ({ value, options, ...buttonProps }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const classes = useStyles();

    return useMemo(() => {
        const handleMenuItemClick = (_event: unknown, index: number) => {
            options[index]?.onClick?.();
            setOpen(false);
        };

        const handleToggle = () => {
            setOpen((prevOpen) => !prevOpen);
        };

        const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
            if (anchorRef.current?.contains(event.target as Node)) {
                return;
            }

            setOpen(false);
        };

        if (options.length < 1) {
            return null;
        }

        return (
            <div>
                <Grid container direction="column" className={classes.gridContainer}>
                    <Grid item xs={12} className={classes.gridItem}>
                        <Button onClick={handleToggle} {...buttonProps} ref={anchorRef}>
                            {value}
                        </Button>
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            className={classes.popper}
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList>
                                                {options.map((option, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        onClick={(event) => handleMenuItemClick(event, index)}
                                                    >
                                                        {option.value}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Grid>
                </Grid>
            </div>
        );
    }, [buttonProps, classes.gridContainer, classes.gridItem, classes.popper, open, options, value]);
};
