import ExpandLessFilledIcon from "@material-ui/icons/ExpandLess";
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandLessSharpIcon from "@material-ui/icons/ExpandLessSharp";
import ExpandLessTwoToneIcon from "@material-ui/icons/ExpandLessTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const expandLessIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return ExpandLessFilledIcon;
        case IconStyle.Outlined:
            return ExpandLessOutlinedIcon;
        case IconStyle.Rounded:
            return ExpandLessRoundedIcon;
        case IconStyle.TwoTone:
            return ExpandLessTwoToneIcon;
        case IconStyle.Sharp:
            return ExpandLessSharpIcon;
    }
};

export const ExpandLessIcon = createIconComponent(expandLessIconSelector);
