import DoneAllFilledIcon from "@material-ui/icons/DoneAll";
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import DoneAllRoundedIcon from "@material-ui/icons/DoneAllRounded";
import DoneAllSharpIcon from "@material-ui/icons/DoneAllSharp";
import DoneAllTwoToneIcon from "@material-ui/icons/DoneAllTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const doneAllIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return DoneAllFilledIcon;
        case IconStyle.Outlined:
            return DoneAllOutlinedIcon;
        case IconStyle.Rounded:
            return DoneAllRoundedIcon;
        case IconStyle.TwoTone:
            return DoneAllTwoToneIcon;
        case IconStyle.Sharp:
            return DoneAllSharpIcon;
    }
};

export const DoneAllIcon = createIconComponent(doneAllIconSelector);
