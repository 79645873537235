import SentimentSatisfiedAltFilledIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentSatisfiedAltOutlinedIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import SentimentSatisfiedAltRoundedIcon from "@material-ui/icons/SentimentSatisfiedAltRounded";
import SentimentSatisfiedAltSharpIcon from "@material-ui/icons/SentimentSatisfiedAltSharp";
import SentimentSatisfiedAltTwoToneIcon from "@material-ui/icons/SentimentSatisfiedAltTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const sentimentSatisfiedAltIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return SentimentSatisfiedAltFilledIcon;
        case IconStyle.Outlined:
            return SentimentSatisfiedAltOutlinedIcon;
        case IconStyle.Rounded:
            return SentimentSatisfiedAltRoundedIcon;
        case IconStyle.TwoTone:
            return SentimentSatisfiedAltTwoToneIcon;
        case IconStyle.Sharp:
            return SentimentSatisfiedAltSharpIcon;
    }
};

export const SentimentSatisfiedAltIcon = createIconComponent(sentimentSatisfiedAltIconSelector);
