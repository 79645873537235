import queryString from "query-string";
import { useCallback, useMemo } from "react";
import { QueryObserverResult, useQuery, UseQueryOptions } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSpinnerEffect } from "../SpinnerContext";
import { useAuthorizedFetch } from "./AuthorizedFetch";

export interface WebApiUser {
    id: string;
    name: string;
    azureADIdentifier: string;
    emailAddress: string;
}

type UsersParameters =
    | {
          userId: string;
      }
    | {
          azureAuthId: string;
      }
    | "self";

export function useUsers(
    parameters?: UsersParameters,
    queryOptions?: UseQueryOptions<WebApiUser[]>
): QueryObserverResult<WebApiUser[], unknown> {
    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();
    const apiUrl = (parameters?: UsersParameters) =>
        parameters === undefined
            ? "users"
            : parameters === "self"
            ? `users?${queryString.stringify({ getSelf: true })}`
            : `users?${queryString.stringify(parameters)}`;

    const getUsers = useCallback(
        async function (parameters?: UsersParameters) {
            const response = await authorizedFetch(apiUrl(parameters));

            if (response.status >= 200 && response.status < 300) {
                return (await response.json()) as WebApiUser[];
            } else {
                showError(ErrorMessage.FailedToLoadUsers, response);
                throw new Error(ErrorMessage.FailedToLoadUsers);
            }
        },
        [authorizedFetch, showError]
    );

    const query = useQuery(["users", ...(parameters ? [parameters] : [])], () => getUsers(parameters), {
        ...(parameters !== "self" ? undefined : { staleTime: Infinity }),
        ...queryOptions,
    });

    useSpinnerEffect(query.isLoading, apiUrl(parameters));

    return query;
}

export function useUserIdToNameMap(): Record<string, string> {
    const { data: users } = useUsers();
    return useMemo(() => (users || []).reduce((acc, value) => ({ ...acc, [value.id]: value.name }), {}), [users]);
}
