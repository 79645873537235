import {
    Box,
    Button,
    Card,
    CardContent,
    createStyles,
    Grid,
    Link,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import ErrorIcon from "@material-ui/icons/Error";
import * as React from "react";
import { SyntheticEvent, useMemo } from "react";
import { getAppConfigUrl, useAppConfigQuery } from "../Api/useAppConfigQuery";
import { createLogger } from "../log";
import { AppConfigContext } from "./AppConfigContext";

export const log = createLogger("AppConfigProvider");

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContent: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
        },
        errorMessage: {
            display: "flex",
            alignItems: "center",
        },
        errorIcon: {
            marginRight: theme.spacing(1),
        },
        reloadButton: {},
    })
);

export const AppConfigProvider: React.FC = ({ children }) => {
    const query = useAppConfigQuery({
        refetchInterval: 5 * 60 * 1000,
        staleTime: Infinity,
    });
    const classes = useStyles();
    const { data, error } = query;
    const handleReloadClick = (event: SyntheticEvent) => {
        event.preventDefault();
        window.location.reload(true);
    };
    const appConfigUrl = useMemo(() => getAppConfigUrl(), []);

    if (data) {
        return <AppConfigContext.Provider value={data}>{children}</AppConfigContext.Provider>;
    } else if (error) {
        return (
            <Box p={1}>
                <Grid container>
                    <Grid item xs={12} xl={6} lg={9}>
                        <Card>
                            <CardContent className={classes.cardContent}>
                                <div className={classes.errorMessage}>
                                    <ErrorIcon fontSize="large" className={classes.errorIcon} />
                                    <Typography>
                                        Failed to load spa configuration from{" "}
                                        <Link href={appConfigUrl}>{appConfigUrl}</Link>.
                                    </Typography>
                                </div>
                                <Box mt={2}>
                                    <Button
                                        variant="contained"
                                        className={classes.reloadButton}
                                        startIcon={<CachedIcon />}
                                        onClick={handleReloadClick}
                                    >
                                        Try Again
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        );
    } else {
        return null;
    }
};
