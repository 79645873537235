import * as React from "react";
import { useMemo } from "react";
import { useAssignChecklistStepMutation, useUsers, WebApiUser } from "../../Api";
import { UserPicker } from "../../UserPicker";

export const AssignStepButton: React.FC<{ canAssign: boolean; checklistId: string; checklistStepId: string }> = ({
    canAssign,
    checklistId,
    checklistStepId,
}) => {
    const assignMutation = useAssignChecklistStepMutation();
    const { data: selfUsers } = useUsers("self");

    return useMemo(() => {
        const selfUser = selfUsers?.[0];
        const handleClick = (user: WebApiUser) => {
            const assigneeName = user.id === selfUser?.id ? "me" : user.name;
            assignMutation.mutateAsync({
                checklistId,
                checklistStepId,
                assigneeId: user.id,
                assigneeName,
            });
        };
        return (
            <UserPicker
                value="Assign to"
                disabled={!canAssign}
                onClick={handleClick}
                color="primary"
                variant="contained"
            />
        );
    }, [assignMutation, canAssign, checklistId, checklistStepId, selfUsers]);
};
