import RateReviewFilledIcon from "@material-ui/icons/RateReview";
import RateReviewOutlinedIcon from "@material-ui/icons/RateReviewOutlined";
import RateReviewRoundedIcon from "@material-ui/icons/RateReviewRounded";
import RateReviewSharpIcon from "@material-ui/icons/RateReviewSharp";
import RateReviewTwoToneIcon from "@material-ui/icons/RateReviewTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const rateReviewIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return RateReviewFilledIcon;
        case IconStyle.Outlined:
            return RateReviewOutlinedIcon;
        case IconStyle.Rounded:
            return RateReviewRoundedIcon;
        case IconStyle.TwoTone:
            return RateReviewTwoToneIcon;
        case IconStyle.Sharp:
            return RateReviewSharpIcon;
    }
};

export const RateReviewIcon = createIconComponent(rateReviewIconSelector);
