import { Box, List, ListSubheader, makeStyles } from "@material-ui/core";
import * as React from "react";
import { CSSProperties } from "react";
import { createLogger } from "../../log";

export const log = createLogger("Inbox");

const inboxBackgroundColors = [
    "rgba(30, 203, 225, .6)",
    "rgb(150, 30, 225, .6)",
    "rgba(225, 52, 30, .6)",
    "rgba(106, 225, 30, .6)",
    "rgba(43, 41, 214, 0.6)",
    "rgba(214, 41, 125, .6)",
    "rgba(212, 214, 41, .6)",
    "rgba(41, 214, 130, .6)",
];

const useStyles = makeStyles((theme) => ({
    binContainer: {
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        borderRadius: "20px",
        boxShadow: "rgba(89, 89, 89, 0.53) 1px 1px 8px 1px",
        transition: "background 0.2s ease 0s",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderWidth: ".4em",
        borderStyle: "solid",
        borderColor: "black",
    },
    binList: {
        width: 360,
        backgroundColor: theme.palette.background.paper,
        position: "relative",
        overflow: "auto",
        height: 300,
        "&::-webkit-scrollbar": {
            width: "1.0em",
        },
        "&::-webkit-scrollbar-track": {
            background: "rgba(0,0,0,.05)",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.15)",
        },
    },
    binListSection: {
        backgroundColor: "inherit",
    },
    binUl: {
        backgroundColor: "inherit",
        padding: 0,
    },
    listSubHeader: {
        zIndex: 2,
        fontWeight: "bold",
        paddingLeft: "20px",
        fontSize: "18px",
    },
}));

interface ChecklistSummaryListProps {
    title: string;
    colorIndex: number;
}

export const ChecklistSummaryList: React.FC<ChecklistSummaryListProps> = (props) => {
    const { title, colorIndex, children } = props;
    const borderColor = inboxBackgroundColors[colorIndex % inboxBackgroundColors.length];
    const style: CSSProperties = {
        borderColor,
    };

    const classes = useStyles();

    const listSubHeaderProps = {
        inset: React.Children.count(children) > 0,
    };

    return (
        <Box className={classes.binContainer} style={style}>
            <List className={classes.binList} aria-labelledby="nested-list-subheader" subheader={<li />}>
                <li className={classes.binListSection}>
                    <ul className={classes.binUl}>
                        <ListSubheader
                            component="div"
                            id="nested-list-subheader"
                            style={{ zIndex: 2 }}
                            className={classes.listSubHeader}
                            {...listSubHeaderProps}
                        >
                            {title}
                        </ListSubheader>
                        {children}
                    </ul>
                </li>
            </List>
        </Box>
    );
};
