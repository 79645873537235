import queryString from "query-string";
import {
    DataFileMenuItemIdentifier,
    qbePrefix,
    RecordAbsolutePosition,
    RecordDirection,
    RecordId,
} from "./DataFileTypes";

export const urlPathSegmentSearchByRecordNumber = "search-by-record-number";
export const urlPathSegmentSearchByExample = "search-by-example";
export const urlPathSegmentAddRecord = "add-record";

export const dataFilePageExactRoutePaths = [
    "/data-files",
    "/data-files/:dfuName/:fileName",
    `/data-files/:dfuName/:fileName/:mode`,
];

export const urlToDataFile: (
    options: DataFileMenuItemIdentifier & {
        recordNumber?: number;
        recordId?: RecordId;
        recordDirection?: RecordDirection;
        recordAbsolutePosition?: RecordAbsolutePosition;
    }
) => string = ({ dfuName, fileName, recordNumber, recordId, recordDirection, recordAbsolutePosition }) => {
    const qs = queryString.stringify({ recordNumber, recordId, recordDirection, recordAbsolutePosition });
    return `/data-files/${encodeURIComponent(dfuName)}/${encodeURIComponent(fileName)}${qs ? `?${qs}` : ""}`;
};

export const urlToDataFileSearchByRecordNumber: (options: DataFileMenuItemIdentifier) => string = ({
    dfuName,
    fileName,
}) =>
    `/data-files/${encodeURIComponent(dfuName)}/${encodeURIComponent(fileName)}/${urlPathSegmentSearchByRecordNumber}`;

export const urlToDataFileSearchByExample: (
    options: DataFileMenuItemIdentifier,
    recordType?: string,
    example?: Record<string, string>
) => string = ({ dfuName, fileName }, recordType, example) => {
    const qbe =
        example === undefined || recordType === undefined
            ? undefined
            : Object.keys(example).reduce((acc, next) => ({ ...acc, [`${qbePrefix}${next}`]: example[next] }), {
                  recordType,
              });

    const qs = qbe === undefined || Object.keys(qbe).length === 0 ? "" : `?${queryString.stringify(qbe)}`;
    return [
        "/data-files/",
        encodeURIComponent(dfuName),
        "/",
        encodeURIComponent(fileName),
        "/",
        urlPathSegmentSearchByExample,
        qs,
    ].join("");
};

export const urlToDataFileAddRecord: (options: DataFileMenuItemIdentifier) => string = ({ dfuName, fileName }) =>
    `/data-files/${encodeURIComponent(dfuName)}/${encodeURIComponent(fileName)}/${urlPathSegmentAddRecord}`;

export const urlToDataFileSearchResultByRecordNumber: (
    options: DataFileMenuItemIdentifier & {
        recordNumber?: number;
        recordId?: RecordId;
        recordDirection?: RecordDirection;
        recordAbsolutePosition?: RecordAbsolutePosition;
    }
) => string = ({ dfuName, fileName, recordNumber, recordId, recordDirection, recordAbsolutePosition }) => {
    const qs = queryString.stringify({ recordNumber, recordId, recordDirection, recordAbsolutePosition });
    return `/data-files/${encodeURIComponent(dfuName)}/${encodeURIComponent(fileName)}${qs ? `?${qs}` : ""}`;
};

export const urlToDataFileAbsolutionPosition: (
    options: DataFileMenuItemIdentifier & {
        recordAbsolutePosition: RecordAbsolutePosition;
    }
) => string = ({ dfuName: dataFile, fileName: file, recordAbsolutePosition }) => {
    const qs = queryString.stringify({ recordAbsolutePosition });
    return `/data-files/${encodeURIComponent(dataFile)}/${encodeURIComponent(file)}?${qs}`;
};

export const canonicalUrlToDataFile: (
    options: DataFileMenuItemIdentifier & {
        recordId?: RecordId;
    }
) => string = ({ dfuName, fileName, recordId }) => {
    const qs = queryString.stringify({ recordId });
    return `/data-files/${encodeURIComponent(dfuName)}/${encodeURIComponent(fileName)}?${qs}`;
};

/*
Possibly urls:

"You figure it out" - all I know is what dfu/file to work on.  Page decides how to initialize mode.
http://localhost:3000/data-files/utcust/UT.CUST

http://localhost:3000/data-files/utcust/UT.CUST/searchByRecordNumber

http://localhost:3000/data-files/utcust/UT.CUST/searchByExample
*/
