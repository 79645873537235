import HomeFilledIcon from "@material-ui/icons/Home";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import HomeSharpIcon from "@material-ui/icons/HomeSharp";
import HomeTwoToneIcon from "@material-ui/icons/HomeTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const homeIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return HomeFilledIcon;
        case IconStyle.Outlined:
            return HomeOutlinedIcon;
        case IconStyle.Rounded:
            return HomeRoundedIcon;
        case IconStyle.TwoTone:
            return HomeTwoToneIcon;
        case IconStyle.Sharp:
            return HomeSharpIcon;
    }
};

export const HomeIcon = createIconComponent(homeIconSelector);
