import * as Yup from "yup";
import { WebApiChecklistTemplateParameter } from "../../Api";
import { getYupObjectSchemaForChecklistParameter } from "./getYupObjectSchemaForChecklistParameter";

export function getYupObjectSchemaForChecklistParameterList(
    parameters: undefined | WebApiChecklistTemplateParameter[]
): unknown {
    return parameters === undefined
        ? undefined
        : Yup.object(
              parameters.reduce(
                  (acc, value) => ({
                      ...acc,
                      [value.name]: getYupObjectSchemaForChecklistParameter(value),
                  }),
                  {}
              )
          );
}
