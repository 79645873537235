import * as React from "react";
import { useUserSettings } from "../Api";
import { DataFileUtilityFieldLayout } from "../UserSettings";

export const useDfuStyles: () => { fieldsContainer: React.CSSProperties } = () => {
    const { dataFileUtilityFieldLayout } = useUserSettings();

    const fieldsContainer: React.CSSProperties =
        dataFileUtilityFieldLayout === DataFileUtilityFieldLayout.Rows
            ? {
                  display: "flex",
                  flexWrap: "wrap",
              }
            : {
                  display: "flex",
                  flexDirection: "column",
              };

    return {
        fieldsContainer,
    };
};
