import ExpandMoreFilledIcon from "@material-ui/icons/ExpandMore";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import ExpandMoreTwoToneIcon from "@material-ui/icons/ExpandMoreTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const expandMoreIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return ExpandMoreFilledIcon;
        case IconStyle.Outlined:
            return ExpandMoreOutlinedIcon;
        case IconStyle.Rounded:
            return ExpandMoreRoundedIcon;
        case IconStyle.TwoTone:
            return ExpandMoreTwoToneIcon;
        case IconStyle.Sharp:
            return ExpandMoreSharpIcon;
    }
};

export const ExpandMoreIcon = createIconComponent(expandMoreIconSelector);
