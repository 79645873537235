import CachedFilledIcon from "@material-ui/icons/Cached";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import CachedRoundedIcon from "@material-ui/icons/CachedRounded";
import CachedSharpIcon from "@material-ui/icons/CachedSharp";
import CachedTwoToneIcon from "@material-ui/icons/CachedTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const cachedIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return CachedFilledIcon;
        case IconStyle.Outlined:
            return CachedOutlinedIcon;
        case IconStyle.Rounded:
            return CachedRoundedIcon;
        case IconStyle.TwoTone:
            return CachedTwoToneIcon;
        case IconStyle.Sharp:
            return CachedSharpIcon;
    }
};

export const CachedIcon = createIconComponent(cachedIconSelector);
