import {
    Box,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { ChecklistSummaryListItemProps } from "../ChecklistTypes";
import { getChecklistStatusDisplayString } from "../getChecklistStatusDisplayString";
import { ChecklistCurrentStepStatus, ChecklistOnlyStatus } from "../Status";
import { ChecklistSummaryAvatar, ChecklistSummaryListItemIcon } from "./ChecklistSummaryIcon";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
    },
    gridContainer: {
        maxWidth: "fit-content",
    },
    stepperBox: {
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(2),
        "& > *": {
            marginRight: theme.spacing(1),
        },
    },
    stepperBoxArrow: {
        fontSize: "40px",
    },
    stepperBoxArrowContainer: {
        display: "flex",
        height: "50px",
        alignSelf: "flex-start",
        alignItems: "center",
    },
}));

const getIconDescription: (props: Pick<ChecklistSummaryListItemProps, "checklistStatus">) => string = (props) => {
    const { checklistStatus } = props;
    switch (checklistStatus) {
        default:
        // We actually skip the created state and go directly to unscheduled.
        // case ChecklistOnlyStatus.ChecklistCreated:
        //     return "ChecklistCreated";
        // eslint-disable-next-line no-fallthrough
        case ChecklistOnlyStatus.ChecklistWaitingForSchedule:
            return "The checklist has not been scheduled yet.";
        case ChecklistOnlyStatus.ChecklistWaitingForStart:
            return "The checklist start date has not arrived yet.";
        case ChecklistCurrentStepStatus.StepWaitingForAssignment:
            return "The checklist has started, but the current step is unassigned.";
        case ChecklistCurrentStepStatus.StepInProgress:
            return "The checklist is being worked on.";
        case ChecklistOnlyStatus.ChecklistCompleted:
            return "The checklist was completed.";
    }
};

export const ChecklistSummaryIconLegend: React.FC<{ omitIntroductoryTextAndTitle?: boolean }> = ({
    omitIntroductoryTextAndTitle,
}) => {
    const classes = useStyles();
    const allStatesInOrder = [
        // ChecklistOnlyStatus.ChecklistCreated,
        ChecklistOnlyStatus.ChecklistWaitingForSchedule,
        ChecklistOnlyStatus.ChecklistWaitingForStart,
        ChecklistCurrentStepStatus.StepWaitingForAssignment,
        ChecklistCurrentStepStatus.StepInProgress,
        ChecklistOnlyStatus.ChecklistCompleted,
    ];

    return (
        <>
            {omitIntroductoryTextAndTitle !== true && (
                <>
                    <Typography variant="h6" gutterBottom>
                        Icon legend
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Below are all the checklist summary icons and explanations of what they mean. The icons and
                        their colors can be customized on the{" "}
                        <Link component={RouterLink} to={"/profile"}>
                            profile page
                        </Link>
                        .
                    </Typography>
                </>
            )}
            <Grid container className={classes.gridContainer}>
                <Grid item xs={12} xl={6}>
                    <Paper className={classes.paper}>
                        <List>
                            {allStatesInOrder
                                .map((checklistStatus) => {
                                    return (
                                        <ListItem key={checklistStatus}>
                                            <ChecklistSummaryListItemIcon
                                                checklistStatus={checklistStatus}
                                                isLate={false}
                                            />
                                            <ListItemText>{getIconDescription({ checklistStatus })}</ListItemText>
                                        </ListItem>
                                    );
                                })
                                .concat(
                                    <React.Fragment key="isLate">
                                        <Box mt={2} mb={2}>
                                            <Divider />
                                        </Box>
                                        <ListItem>
                                            <ChecklistSummaryListItemIcon
                                                checklistStatus={ChecklistCurrentStepStatus.StepInProgress}
                                                isLate={true}
                                            />
                                            <ListItemText>
                                                This step is late. This &ldquo;LATE&rdquo; badge can appear to any of
                                                the icons above.
                                            </ListItemText>
                                        </ListItem>
                                    </React.Fragment>,
                                    <React.Fragment key="explanation">
                                        <Box mt={2} mb={2}>
                                            <Divider />
                                        </Box>
                                        <ListItem>
                                            <ListItemText>
                                                <Typography variant="body1" gutterBottom>
                                                    A checklist progresses through its states in the order shown below.
                                                </Typography>
                                                <Box>
                                                    <Box className={classes.stepperBox}>
                                                        {allStatesInOrder.map((checklistStatus, index) => {
                                                            const ArrowIcon =
                                                                checklistStatus ===
                                                                ChecklistCurrentStepStatus.StepWaitingForAssignment
                                                                    ? SyncAltIcon
                                                                    : ArrowRightAltIcon;
                                                            return (
                                                                <React.Fragment key={`explanation-${checklistStatus}`}>
                                                                    <Box
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                        alignItems="center"
                                                                    >
                                                                        <ChecklistSummaryAvatar
                                                                            checklistStatus={checklistStatus}
                                                                        />
                                                                        <Box mt={1}>
                                                                            <Typography>
                                                                                {getChecklistStatusDisplayString(
                                                                                    checklistStatus
                                                                                )}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>

                                                                    {index < allStatesInOrder.length - 1 && (
                                                                        <Box
                                                                            className={classes.stepperBoxArrowContainer}
                                                                        >
                                                                            <ArrowIcon
                                                                                className={classes.stepperBoxArrow}
                                                                            />
                                                                        </Box>
                                                                    )}
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </Box>
                                                </Box>
                                            </ListItemText>
                                        </ListItem>
                                    </React.Fragment>,
                                    <React.Fragment key="explanation-alternating-status">
                                        <Box mt={2} mb={2}>
                                            <Divider />
                                        </Box>
                                        <ListItem>
                                            <ListItemText>
                                                <Typography variant="body1" gutterBottom>
                                                    As the checklist progresses through steps, the &ldquo;
                                                    {getChecklistStatusDisplayString(
                                                        ChecklistCurrentStepStatus.StepWaitingForAssignment
                                                    )}
                                                    &rdquo; and &ldquo;
                                                    {getChecklistStatusDisplayString(
                                                        ChecklistCurrentStepStatus.StepInProgress
                                                    )}
                                                    &rdquo; statuses reflect the current step, not the overall
                                                    checklist. As a result, the checklist icon will alternate between
                                                    these two states before it is completed.
                                                </Typography>
                                                <Box>
                                                    <Box className={classes.stepperBox}>
                                                        {[
                                                            ChecklistCurrentStepStatus.StepWaitingForAssignment,
                                                            ChecklistCurrentStepStatus.StepInProgress,
                                                        ].map((checklistStatus, index) => {
                                                            return (
                                                                <React.Fragment key={`explanation-${checklistStatus}`}>
                                                                    <Box
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                        alignItems="center"
                                                                    >
                                                                        <ChecklistSummaryAvatar
                                                                            checklistStatus={checklistStatus}
                                                                        />
                                                                        <Box mt={1}>
                                                                            <Typography>
                                                                                {getChecklistStatusDisplayString(
                                                                                    checklistStatus
                                                                                )}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>

                                                                    {index === 0 && (
                                                                        <Box
                                                                            className={classes.stepperBoxArrowContainer}
                                                                        >
                                                                            <SyncAltIcon
                                                                                className={classes.stepperBoxArrow}
                                                                            />
                                                                        </Box>
                                                                    )}
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </Box>
                                                </Box>
                                            </ListItemText>
                                        </ListItem>
                                    </React.Fragment>
                                )}
                        </List>
                        <div></div>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};
