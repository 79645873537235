import { Backdrop, CircularProgress, Fade } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
    })
);

interface SpinnerState {
    spinning: boolean;
}

export const Spinner: React.FC<SpinnerState> = (props) => {
    const { spinning } = props;
    const classes = useStyles();
    return (
        <Fade
            in={spinning}
            style={{
                transitionDelay: spinning ? "800ms" : "0ms",
            }}
            unmountOnExit
        >
            <Backdrop className={classes.backdrop} open={spinning}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Fade>
    );
};
