import { AuthenticationParameters, Configuration, Logger, LogLevel } from "msal";
import * as React from "react";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
    AuthenticationState,
    IAccountInfo,
    IMsalAuthProviderConfig,
    LoginType,
    MsalAuthProvider,
} from "react-aad-msal";
import isEqual from "react-fast-compare";
import { useAppConfig } from "../../AppConfig";
import { diagnosticFeatureFlags } from "../../diagnosticFeatureFlags";
import { createLogger, noopLogger } from "../../log";
import { Auth, AuthAccount } from "../Auth";

const azureAdLog = diagnosticFeatureFlags.logMsal ? createLogger("AzureAD") : noopLogger;
const msalLog = diagnosticFeatureFlags.logMsal ? createLogger("MSAL") : noopLogger;
const msalAuthProviderProviderLog = diagnosticFeatureFlags.logMsal
    ? createLogger("MsalAuthProviderProvider")
    : noopLogger;

const MsalAuthProviderContext = createContext<MsalAuthProvider | undefined>(undefined);
export const MsalAuthProviderProvider: React.FC = ({ children }) => {
    const appConfig = useAppConfig();
    const authProviderRef = useRef<MsalAuthProvider>();
    const [renderFlag, setRenderFlag] = useState(false);
    useEffect(
        function buildAuthProviderEffect() {
            authProviderRef.current = buildAuthProvider(appConfig);
            setRenderFlag(true);
        },
        [appConfig]
    );

    msalAuthProviderProviderLog("render", authProviderRef);
    return useMemo(
        () =>
            authProviderRef.current && renderFlag ? (
                <MsalAuthProviderContext.Provider value={authProviderRef.current}>
                    {children}
                </MsalAuthProviderContext.Provider>
            ) : (
                <></>
            ),
        [renderFlag, children]
    );
};

export function useMsalAuthProvider(): MsalAuthProvider {
    const msalAuthProviderProvider = useContext(MsalAuthProviderContext);
    if (msalAuthProviderProvider === undefined) {
        throw new Error("useMsalAuthProvider was called outside of an MsalAuthProviderProvider");
    }
    return msalAuthProviderProvider;
}

const logger = new Logger(
    (_logLevel, message) => {
        msalLog(message);
    },
    {
        level: LogLevel.Verbose,
        piiLoggingEnabled: false,
    }
);

// According to MS docoumentation, the default lifetime for AzureAD tokens is 1 hour.  I have confirmed that the
// expiration used when running the demo site locally with AzureAD is enabled, the timeout is 1 hour.  I did this
// by logging in and decoding the the jwt in session storage.  This is done by copying the {msal...} setting's
// "accessToken" value into http://jwt.io.
//
// https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-configurable-token-lifetimes#token-lifetime-policy-properties

// You can configure the auth system to refresh more often using the "tokenRenewalOffsetSeconds" value.  This setting
// is "the window of offset needed to renew the token before expiry".  For example, setting it to 60 * 68 - 15 will
// cause it to require renewal after 15 seconds.  You can observe this in the browser devtools' network tab.
// Renewal doesn't actually happen until a token is needed for an api call.

/**
 * See https://github.com/syncweek-react-aad/react-aad
 */
const buildAuthProvider = (props: { authority: string; clientId: string; scopes: string[] }): MsalAuthProvider => {
    azureAdLog("buildAuthProvider", props);

    const config: Configuration = {
        auth: {
            authority: props.authority,
            clientId: props.clientId,
            redirectUri: window.location.origin,
        },
        // Enable logging of MSAL events for easier troubleshooting.
        // This should be disabled in production builds.
        system: {
            logger: logger,
            // tokenRenewalOffsetSeconds: 60 * 60 - 15,
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false,
        },
    };

    const parameters: AuthenticationParameters = {
        scopes: props.scopes,
    };

    const options: IMsalAuthProviderConfig = {
        loginType: LoginType.Redirect,
        tokenRefreshUri: window.location.origin + "/auth.html",
    };

    return new MsalAuthProvider(config, parameters, options);
};

function simplifyAccountInfo(accountInfo: IAccountInfo | null): AuthAccount | null {
    if (!accountInfo) {
        return null;
    }
    const { name, userName } = accountInfo.account;
    return { name, userName };
}

/**
 * An auth provider that uses msal.  Look here for ideas if things go
 * sideways: https://github.com/syncweek-react-aad/react-aad/blob/master/packages/react-aad-msal/src/components/AzureAD.tsx
 */
export const MsalAuthProviderDecorator: React.FC<{ provider: React.Provider<Auth> }> = ({
    provider: Provider,
    children,
}) => {
    const provider = useMsalAuthProvider();

    const [account, setAccount] = useState<AuthAccount | null>(null); // () => simplifyAccountInfo(provider.getAccountInfo()));
    const [authenticationState, setAuthenticationState] = useState(provider.authenticationState);
    const [error, setError] = useState(provider.getError());

    useEffect(() => {
        if (error) {
            console.error(error);
        }
    }, [error]);

    const handleAcountInfo = useCallback(
        (accountInfo: IAccountInfo | null) => {
            const simple = simplifyAccountInfo(accountInfo);
            setAccount((ai) => (isEqual(ai, simple) ? ai : simple));
        },
        [setAccount]
    );

    useEffect(() => {
        provider.registerAuthenticationStateHandler(setAuthenticationState);
        provider.registerAcountInfoHandler(handleAcountInfo);
        provider.registerErrorHandler(setError);

        return () => {
            provider.unregisterAuthenticationStateHandler(setAuthenticationState);
            provider.unregisterAccountInfoHandler(handleAcountInfo);
            provider.unregisterErrorHandler(setError);
        };
    }, [provider, setAuthenticationState, setError, handleAcountInfo]);

    const auth: Auth = useMemo(
        () => ({
            isAccessTokenNeeded: true,
            isUserLoggedIn: authenticationState === AuthenticationState.Authenticated,
            getAccessToken: async () => {
                const accessTokenResponse = await provider.getAccessToken();
                const { accessToken } = accessTokenResponse;
                return accessToken;
            },
            login: () => provider.login(),
            logout: () => {
                if (authenticationState !== AuthenticationState.Authenticated) {
                    return;
                }
                return provider.logout();
            },
            getUserRoles: () => Promise.resolve([]),
            account: account || undefined,
        }),
        [authenticationState, provider, account]
    );

    return <Provider value={auth}>{children}</Provider>;
};
