import queryString from "query-string";
import { ReactNode } from "react";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { snackState } from "./atoms";
import { SnackState } from "./SnackState";

const isSnack = (x: ReactNode | SnackState): x is SnackState =>
    x !== undefined && x !== null && typeof x === "object" && "children" in x;

export interface SetSnack {
    (snack: ReactNode | SnackState): void;
}

const defaultSnack: SnackState = {
    severity: "info",
    children: null,
};

export const useSetSnack: () => { setSnack: SetSnack } = () => {
    const setSnack = useRecoilCallback(
        ({ set }): SetSnack => (snack) => {
            const query = queryString.parse(window.location.search);
            if ("no-snacks" in query) {
                return;
            }
            set(
                snackState,
                isSnack(snack)
                    ? snack
                    : {
                          ...defaultSnack,
                          children: snack,
                      }
            );
        },
        []
    );
    return {
        setSnack,
    };
};

export const useSnack: () => { snack: SnackState | undefined } = () => ({
    snack: useRecoilValue(snackState),
});
