import { QueryObserverResult, useQuery, UseQueryOptions } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSpinnerEffect } from "../SpinnerContext";
import { useAuthorizedFetch } from "./AuthorizedFetch";
import { parseJsonWebApiChecklist } from "./parseJsonWebApiChecklist";
import { WebApiChecklist } from "./WebApiTypes";

export function useChecklist(
    checklistId: string,
    queryConfig?: UseQueryOptions<WebApiChecklist>
): QueryObserverResult<WebApiChecklist> {
    const query = useQuery(["checklist", checklistId], () => getChecklist(checklistId), {
        ...queryConfig,
    });

    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();
    const apiUrl = (checklistId: string) => `checklists/${checklistId}`;

    async function getChecklist(checklistId: string) {
        const response = await authorizedFetch(apiUrl(checklistId));
        if (response.status >= 200 && response.status < 300) {
            const json = await response.text();
            try {
                return parseJsonWebApiChecklist(json);
            } catch (ex) {
                showError(ErrorMessage.FailedToParseChecklist, response);
                throw ex;
            }
        } else {
            showError(ErrorMessage.FailedToLoadChecklist, response);
            throw new Error(ErrorMessage.FailedToLoadChecklist);
        }
    }

    useSpinnerEffect(query.isLoading, apiUrl(checklistId));

    return query;
}
