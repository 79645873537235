import constate from "constate";
import { useState } from "react";
import { Command } from "./Command";

export interface MenuContent {
    title: string;
    isEnabled: boolean;
    isEmphasized: boolean;
    items: MenuItem[];
}

export interface MenuDefinition extends MenuContent {
    library: string;
    name: string;
}

export interface MenuItem {
    text: string;
    shortcut: string;
    command: Command;
    isEnabled: boolean;
    isEmphasized: boolean;
}

interface MenuState {
    menusByKey: Record<string, MenuDefinition>;
    library: string;
    menu: string;
    isLoading: boolean;
    error: string | null;
}

const menuInitialState: MenuState = {
    menusByKey: {},
    library: "",
    menu: "",
    isLoading: false,
    error: null,
};

const useMenu = (): {
    menuState: MenuState;
    setMenuState: React.Dispatch<React.SetStateAction<MenuState>>;
} => {
    const [menuState, setMenuState] = useState(menuInitialState);
    return { menuState, setMenuState };
};

const [MenuProvider, useMenuContext] = constate(useMenu);

export { MenuProvider, useMenuContext };
