import { Divider, FormControl, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import { Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { KeyboardEventHandler, useState } from "react";
import { DataFileFieldContainer } from "./DataFileField";
import { RecordMetadata } from "./DataFileTypes";

export interface DataFileAddProps {
    /** Possible record datas to search */
    recordMetadatas: RecordMetadata[];
    /** An event emitted when the user chooses to save the new record */
    onSubmit(recordMetadataId: string, values: Record<string, string>): void;
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%",
    },
    formDivContainer: {
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    recordTypeDivider: {
        marginTop: theme.spacing(2),
        marginBottm: theme.spacing(2),
    },
}));

export const DataFileAdd: React.FC<DataFileAddProps> = ({ recordMetadatas, onSubmit }) => {
    const classes = useStyles();
    const [recordMetadataId, setRecordMetadataId] = useState(recordMetadatas[0].recordType);

    const handleFormSubmit: (values: Record<string, string>, actions: FormikHelpers<Record<string, string>>) => void = (
        values,
        { setSubmitting }
    ) => {
        setSubmitting(false);
        onSubmit(recordMetadataId, values);
    };

    /**
     * When a form has just one input and the user presses enter while the input is focused, the form is submitted.
     * I'd like to have the same code here whether there is just one input or there are multiple inputs.  For that
     * reason, let's use this keyhandler and preventDefault.
     */
    const handleTextFieldKeydown: (submitForm: () => Promise<void>) => KeyboardEventHandler<HTMLInputElement> = (
        submitForm
    ) => (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            submitForm();
        }
    };

    const initialValues: Record<string, string> = {};

    const handleChangeRecordMetadataId = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        setRecordMetadataId(event.target.value as string);
    };

    const selectedRecordMetadata =
        recordMetadatas.find((rm) => rm.recordType === recordMetadataId) || recordMetadatas[0];

    const { keyFields, fields } = selectedRecordMetadata;
    const keyFieldContent = (handleTextFieldKeydown: KeyboardEventHandler) =>
        keyFields.map((f, i) => (
            <div key={`key-field-${i}`} className={classes.formDivContainer}>
                <DataFileFieldContainer
                    {...f}
                    onKeyDown={handleTextFieldKeydown}
                    autoFocus={keyFields.length > 0 && i === 0}
                    readonly={false}
                    hidden={false}
                />
            </div>
        ));
    const dividerContent = keyFields.length > 0 ? <Divider className={classes.recordTypeDivider} /> : null;
    const fieldContent = (handleTextFieldKeydown: KeyboardEventHandler) =>
        fields.map((f, i) => {
            return (
                <div key={`field-${i}`} className={classes.formDivContainer}>
                    <DataFileFieldContainer
                        {...f}
                        onKeyDown={handleTextFieldKeydown}
                        autoFocus={keyFields.length === 0 && i === 0}
                        readonly={false}
                        hidden={false}
                    />
                </div>
            );
        });

    return (
        <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
            {({ submitForm }) => (
                <Form className={classes.form}>
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                        }}
                    >
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-helper-label">Record Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={recordMetadataId}
                                onChange={handleChangeRecordMetadataId}
                            >
                                {recordMetadatas.map((rm, i) => (
                                    <MenuItem key={i} value={rm.recordType}>
                                        {rm.recordType}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <Divider className={classes.recordTypeDivider} />

                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                        }}
                    >
                        {keyFieldContent(handleTextFieldKeydown(submitForm))}
                    </div>
                    {dividerContent}
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                        }}
                    >
                        {fieldContent(handleTextFieldKeydown(submitForm))}
                    </div>
                </Form>
            )}
        </Formik>
    );
};
