import { createStyles, Divider, makeStyles, Theme } from "@material-ui/core";
import useEventListener from "@use-it/event-listener";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import * as React from "react";
import { useUserSettings } from "../Api";
import { DataFileField } from "./DataFileField";
import { DataFileMode, FieldMetadata, KeyFieldMetadata } from "./DataFileTypes";
import { useDfuStyles } from "./useDfuStyles";

// If we ever decide to make everything use generic values, this placeholder will help.
export type Values = Record<string, unknown>;

/**
 * TODO: Consider setting up a union type with separate types for "have a current record" and "do not have a current
 * record".
 */
export interface DataFileEditProps {
    /** Mode of data file operation */
    mode: DataFileMode;
    /** Name of the data file */
    dataFile: string;
    /** Name of file */
    file: string;
    /** Opaque identifier for the current record.  If undefined, there is no current record. */
    recordId?: string;
    /** Identitifier for the record metadata describing the current data file record. */
    recordMetadataId: string;
    /** Field metadata */
    fields: FieldMetadata[];
    /** Key field metadata */
    keyFields: KeyFieldMetadata[];
    /** The initial record data */
    values: Values;

    /** An event emitted when the user requests to move to the next record */
    onMoveNext(values?: Values): void;
    /** An event emitted when the user requests to move to the previous record */
    onMovePrevious(values?: Values): void;
    /** An event emitted when the user requests to save the current record with no navigation */
    onSave(values: Values): void;
}

// interface DataFileBaseProps {
//     /** Name of the data file */
//     dataFile: string;
//     /** Format in use */
//     recordFormat: string;
//     /** Key field metadata */
//     keyFields: KeyFieldDefinition[];
//     /**An event emitted when the user requests to move to the next record. */
//     onMoveNext(values?: Values): void;
//     /** Called when the user requests to move to the previous record. */
//     onMovePrevious(values?: Values): void;
// }

// export interface DataFileSearchProps extends DataFileBaseProps {
//     /** Discrimintor field used to distinguish types */
//     mode: DataFileMode.Search;
// }

// export interface DataFileCreateProps extends DataFileBaseProps {
//     /** Discrimintor field used to distinguish types */
//     mode: DataFileMode.Create;
//     /** Field metadata */
//     fields: FieldDefinition[];
// }

// export interface DataFileUpdateProps extends DataFileCreateProps {
//     /** Discrimintor field used to distinguish types */
//     mode: DataFileMode.Create;
//     /** Opaque identifier for the current record.  If undefined, there is no current record. */
//     recordId?: string;
//     /** The initial record data */
//     values: Values;
// }

// export type DataFileProps = DataFileSearchProps | DataFileCreateProps | DataFileUpdateProps;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageRow: {
            marginBottom: theme.spacing(2),
        },
    })
);

const DataFileForm: React.FC<DataFileEditProps> = ({
    recordId,
    fields,
    keyFields,
    values: initialValues,
    onMoveNext,
    onMovePrevious,
    onSave,
}) => {
    const { values } = useFormikContext<Values>();
    const classes = useStyles();
    const mode: DataFileMode = recordId === undefined ? DataFileMode.Add : DataFileMode.Edit;

    const { dataFileUtilityFieldLabelLayout } = useUserSettings();

    /** Has any of the data changed? */
    const isFormDirty = () => [...keyFields, ...fields].some((f) => initialValues[f.name] !== values[f.name]);

    /** Returns record data, but only if anything has changed. */
    const getValuesIfDirty = () => (isFormDirty() ? values : undefined);

    const handleKeydown = (event: KeyboardEvent) => {
        if (event.key === "PageDown") {
            event.preventDefault();
            onMoveNext(getValuesIfDirty());
        } else if (event.key === "PageUp") {
            event.preventDefault();
            onMovePrevious(getValuesIfDirty());
        } else if (event.key === "Enter") {
            event.preventDefault();
            onSave(values);
        }
    };
    useEventListener("keydown", handleKeydown);

    const keyFieldContent = keyFields.map((f, i) => {
        const props = {
            ...f,
            autoFocus: keyFields.length > 0 && i === 0,
            readonly: mode === DataFileMode.Edit,
            hidden: false,
        };
        return (
            <div key={`key-field-${i}`} className={classes.pageRow}>
                <DataFileField {...{ ...props, dataFileUtilityFieldLabelLayout }} />
            </div>
        );
    });
    const dividerContent = keyFields.length > 0 ? <Divider className={classes.pageRow} /> : null;
    const fieldContent = fields.map((f, i) => {
        const props = {
            ...f,
            autoFocus: keyFields.length === 0 && i === 0,
        };
        return (
            <div key={`field-${i}`} className={classes.pageRow}>
                <DataFileField {...{ ...props, dataFileUtilityFieldLabelLayout }} />
            </div>
        );
    });

    const dfuStyles = useDfuStyles();

    return (
        <Form>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                }}
            >
                {keyFieldContent}
            </div>
            {dividerContent}
            <div style={dfuStyles.fieldsContainer}>{fieldContent}</div>
        </Form>
    );
};

export const renderDataFileHeader = ({
    className,
    mode,
    dataFile,
    file,
}: { className: string; mode: DataFileMode } & Pick<DataFileEditProps, "dataFile" | "file">): React.ReactElement => (
    <table className={className}>
        <tbody>
            <tr>
                <td colSpan={2} width="100%">
                    WORK WITH DATA IN A FILE
                </td>
                <td>Mode:</td>
                <td>{mode}</td>
            </tr>
            <tr>
                <td width="1%">Format:</td>
                <td>{file}</td>
                <td>File:</td>
                <td>{dataFile}</td>
            </tr>
        </tbody>
    </table>
);

/** Using tables for layout is not optimal, so let's hang on to this code in case we need to refactor. */
// const renderDataFileHeaderWithoutUsingTables1 = ({
//     className,
//     mode,
//     dataFile,
//     recordFormat,
// }: { className: string; mode: DataFileMode } & Pick<DataFileProps<unknown>, "dataFile" | "recordFormat">) => (
//     <div className={className}>
//         <div style={{ display: "flex", justifyContent: "space-between" }}>
//             <div>WORK WITH DATA IN A FILE</div>
//             <div style={{ display: "flex" }}>
//                 <div>Mode:</div>
//                 <div>{mode}</div>
//             </div>
//         </div>
//         <div style={{ display: "flex", justifyContent: "space-between" }}>
//             <div style={{ display: "flex" }}>
//                 <div>Format:</div>
//                 <div>{recordFormat}</div>
//             </div>
//             <div style={{ display: "flex" }}>
//                 <div>File:</div>
//                 <div>{dataFile}</div>
//             </div>
//         </div>
//     </div>
// );

/** Using tables for layout is not optimal, so let's hang on to this code in case we need to refactor. */
// const renderDataFileHeaderWithoutUsingTables2 = ({
//     className,
//     mode,
//     dataFile,
//     recordFormat,
// }: { className: string; mode: DataFileMode } & Pick<DataFileProps<unknown>, "dataFile" | "recordFormat">) => (
//     <div className={className}>
//         <div style={{ display: "flex", justifyContent: "space-between" }}>
//             <div style={{ display: "flex", flexDirection: "column" }}>
//                 <div>WORK WITH DATA IN A FILE</div>
//                 <div style={{ display: "flex" }}>
//                     <div>Format:</div>
//                     <div>{recordFormat}</div>
//                 </div>
//             </div>
//             <div style={{ display: "flex", flexDirection: "column" }}>
//                 <div style={{ display: "flex" }}>
//                     <div>Mode:</div>
//                     <div>{mode === DataFileMode.Create ? "ENTRY" : "CHANGE"}</div>
//                 </div>
//                 <div style={{ display: "flex" }}>
//                     <div>File:</div>
//                     <div>{dataFile}</div>
//                 </div>
//             </div>
//         </div>
//     </div>
// );

const renderDataFileInstructions = ({ className }: { className: string }) => (
    <>
        <div className={className}>
            Press the <kbd>PageDown</kbd> key to move to the next record. Press the <kbd>PageUp</kbd> key to move to the
            previous record. If you made any changes, they will be saved.
        </div>
        <div className={className}>
            Press the <kbd>Enter</kbd> key to save changes without moving to another record.
        </div>
    </>
);

export const DataFileEdit: React.FC<DataFileEditProps> = (props) => {
    const { mode, dataFile, file, values } = props;
    const classes = useStyles();

    return (
        <>
            {renderDataFileHeader({ className: classes.pageRow, dataFile, file, mode })}
            <Formik
                enableReinitialize
                initialValues={values}
                onSubmit={(values, { setSubmitting }: FormikHelpers<Record<string, unknown>>) => {
                    setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                    }, 500);
                }}
            >
                <DataFileForm {...props} />
            </Formik>
            {renderDataFileInstructions({ className: classes.pageRow })}
        </>
    );
};
