import queryString, { StringifiableRecord } from "query-string";

interface InboxData {
    title: string;
    apiUrl: string;
}

const myTasksInboxes: InboxData[] = [
    {
        title: "Not completed",
        filter: "notCompleted",
    },
    {
        title: "Completed",
        filter: "completed",
    },
    {
        title: "All",
        filter: "all",
    },
].map(({ title, filter }) => ({
    title,
    apiUrl: `checklist-summaries/mylist?filter=${filter}`,
}));

const departmentInboxes: InboxData[] = [
    {
        title: "Utility",
        dept: "utility",
    },
    {
        title: "Computer",
        dept: "computer",
    },
    {
        title: "Administration",
        dept: "administration",
    },
    {
        title: "Mail room",
        dept: "mailRoom",
    },
].map(({ title, dept }) => ({
    title,
    apiUrl: `checklist-summaries/hub?dept=${dept}`,
}));

// // Use this code to generate the list further below.
// const supervisorInboxes: InboxData[] = ["active", "completed", "all"]
//     .map((filter) =>
//         ["late", "onTime", "all"].map((lateFilter) => ({
//             title: `filter: ${filter} lateFilter: ${lateFilter}`,
//             apiUrl: `checklist-summaries?filter=${filter}&lateFilter=${lateFilter}`,
//         }))
//     )
//     .flat();

// console.log("const supervisorInboxes: InboxData[] = ", JSON.stringify(supervisorInboxes, null, 4));

interface ChecklistSummariesQuery {
    isScheduled?: boolean;
    isStarted?: boolean;
    IsAssigned?: boolean;
    isCompleted?: boolean;
    isLate?: boolean;
}

const urlToChecklistSummariesApi: (query?: ChecklistSummariesQuery) => string = (query) => {
    const qs = query ? queryString.stringify(query as StringifiableRecord) : "";
    return `checklist-summaries${qs ? `?${qs}` : ""}`;
};

const activeQueryParameters: ChecklistSummariesQuery = {
    isStarted: true,
    isCompleted: false,
};

const completedQueryParameters: ChecklistSummariesQuery = {
    isCompleted: true,
};

const lateQueryParameters: ChecklistSummariesQuery = {
    isLate: true,
};

const onTimeQueryParameters: ChecklistSummariesQuery = {
    isLate: false,
};

const supervisorInboxes: InboxData[] = [
    {
        title: "Active late",
        apiUrl: urlToChecklistSummariesApi({ ...activeQueryParameters, ...lateQueryParameters }),
    },
    {
        title: "Active on-time",
        apiUrl: urlToChecklistSummariesApi({ ...activeQueryParameters, ...onTimeQueryParameters }),
    },
    {
        title: "Active",
        apiUrl: urlToChecklistSummariesApi({ ...activeQueryParameters }),
    },
    {
        title: "Completed late",
        apiUrl: urlToChecklistSummariesApi({ ...completedQueryParameters, ...lateQueryParameters }),
    },
    {
        title: "Completed on-time",
        apiUrl: urlToChecklistSummariesApi({ ...completedQueryParameters, ...onTimeQueryParameters }),
    },
    {
        title: "Completed",
        apiUrl: urlToChecklistSummariesApi({ ...completedQueryParameters }),
    },
    {
        title: "Late",
        apiUrl: urlToChecklistSummariesApi({ ...lateQueryParameters }),
    },
    {
        title: "On-time",
        apiUrl: urlToChecklistSummariesApi({ ...onTimeQueryParameters }),
    },
    {
        title: "All",
        apiUrl: urlToChecklistSummariesApi(),
    },
];

const unscheduledInboxes: InboxData[] = [
    {
        title: "Unscheduled",
        apiUrl: "checklist-summaries?isScheduled=false",
    },
    {
        title: "Scheduled, but not started",
        apiUrl: "checklist-summaries?isScheduled=true&isStarted=false",
    },
];

export interface HubPageData {
    spaUrlParam: string;
    pageTitle: string;
    menuItemText: string;
    inboxes: InboxData[];
    showBadgeWithCountFromUrl?: string;
}

/**
 * Static data describing the hub pages.
 */
const hubPages: HubPageData[] = [
    {
        spaUrlParam: "my-tasks",
        pageTitle: "My Tasks",
        menuItemText: "My Tasks",
        inboxes: myTasksInboxes,
        showBadgeWithCountFromUrl: "checklist-summaries/mylist?filter=notCompleted",
    },
    {
        spaUrlParam: "departments",
        pageTitle: "Hub",
        menuItemText: "Hub",
        inboxes: departmentInboxes,
        showBadgeWithCountFromUrl: "checklist-summaries/hub",
    },
    {
        spaUrlParam: "supervisor",
        pageTitle: "Supervisor",
        menuItemText: "Supervisor",
        inboxes: supervisorInboxes,
    },
    {
        spaUrlParam: "unscheduled",
        pageTitle: "Unscheduled",
        menuItemText: "Unscheduled",
        inboxes: unscheduledInboxes,
        showBadgeWithCountFromUrl: "checklist-summaries?isScheduled=false",
    },
];

export const useHubPages: () => HubPageData[] = () => hubPages;

export const getHubPages: () => HubPageData[] = () => hubPages;
