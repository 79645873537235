import { QueryKey, useMutation, useQueryClient } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSetSnack } from "../SnackContext";
import { useSpinner } from "../SpinnerContext";
import { useAuthorizedFetch } from "./AuthorizedFetch";
import { parseJsonWebApiChecklist } from "./parseJsonWebApiChecklist";

interface Parameters {
    checklistId: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useAutoScheduleChecklistMutation() {
    const queryCache = useQueryClient();

    const mutation = useMutation(autoScheduleChecklist, {
        onSuccess: (data, mutationVariables) => {
            const queryKey: QueryKey = ["checklist", mutationVariables.checklistId];
            if (data) {
                queryCache.setQueryData(queryKey, data);
            } else {
                queryCache.invalidateQueries(queryKey);
            }
        },
    });

    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();
    const { setSnack } = useSetSnack();
    const { startSpinner } = useSpinner();

    async function autoScheduleChecklist({ checklistId }: Parameters) {
        const apiUrl = `checklists/${checklistId}/steps/schedule`;
        const stopSpinner = startSpinner(apiUrl);
        try {
            const response = await authorizedFetch(apiUrl, {
                method: "POST",
            });
            if (response.status >= 200 && response.status < 300) {
                setSnack("Auto scheduled checklist.");
                const json = await response.text();
                try {
                    return parseJsonWebApiChecklist(json);
                } catch (ex) {
                    showError(ErrorMessage.FailedToParseChecklist, response);
                    throw ex;
                }
            } else {
                showError(ErrorMessage.FailedToAutoScheduleChecklist, response);
            }
        } finally {
            stopSpinner();
        }
    }

    return mutation;
}
