import LocalLibraryFilledIcon from "@material-ui/icons/LocalLibrary";
import LocalLibraryOutlinedIcon from "@material-ui/icons/LocalLibraryOutlined";
import LocalLibraryRoundedIcon from "@material-ui/icons/LocalLibraryRounded";
import LocalLibrarySharpIcon from "@material-ui/icons/LocalLibrarySharp";
import LocalLibraryTwoToneIcon from "@material-ui/icons/LocalLibraryTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const localLibraryIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return LocalLibraryFilledIcon;
        case IconStyle.Outlined:
            return LocalLibraryOutlinedIcon;
        case IconStyle.Rounded:
            return LocalLibraryRoundedIcon;
        case IconStyle.TwoTone:
            return LocalLibraryTwoToneIcon;
        case IconStyle.Sharp:
            return LocalLibrarySharpIcon;
    }
};

export const LocalLibraryIcon = createIconComponent(localLibraryIconSelector);
