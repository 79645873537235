import PriorityHighFilledIcon from "@material-ui/icons/PriorityHigh";
import PriorityHighOutlinedIcon from "@material-ui/icons/PriorityHighOutlined";
import PriorityHighRoundedIcon from "@material-ui/icons/PriorityHighRounded";
import PriorityHighSharpIcon from "@material-ui/icons/PriorityHighSharp";
import PriorityHighTwoToneIcon from "@material-ui/icons/PriorityHighTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const priorityHighIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return PriorityHighFilledIcon;
        case IconStyle.Outlined:
            return PriorityHighOutlinedIcon;
        case IconStyle.Rounded:
            return PriorityHighRoundedIcon;
        case IconStyle.TwoTone:
            return PriorityHighTwoToneIcon;
        case IconStyle.Sharp:
            return PriorityHighSharpIcon;
    }
};

export const PriorityHighIcon = createIconComponent(priorityHighIconSelector);
