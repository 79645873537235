import * as React from "react";
import { useEffect, useState } from "react";
import { DataFileList } from "./DataFileList";
import { DataFileMenuItemIdentifier } from "./DataFileTypes";

const arrayOneThrough = (lastNumber: number): number[] => Array.from(Array(lastNumber).keys()).map((x) => x + 1);

export const mockLoadDataFileList = async (): Promise<DataFileMenuItemIdentifier[]> => {
    // Simulate a slow api call.
    await new Promise((r) => setTimeout(r, 2000));
    return arrayOneThrough(10).map((i) => ({ dfuName: `SOME-DFU-${i}`, fileName: `SOME-FILE-${i}` }));
};

export const DataFileListContainer: React.FC = () => {
    const [dataFiles, setDataFiles] = useState<DataFileMenuItemIdentifier[]>();
    useEffect(() => {
        const async = async () => {
            setDataFiles(await mockLoadDataFileList());
        };
        async();
    }, []);
    return (
        <>
            {!dataFiles && <p>loading...</p>}
            {dataFiles && <DataFileList dataFiles={dataFiles}></DataFileList>}
        </>
    );
};
