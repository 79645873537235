import FlagFilledIcon from "@material-ui/icons/Flag";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import FlagRoundedIcon from "@material-ui/icons/FlagRounded";
import FlagSharpIcon from "@material-ui/icons/FlagSharp";
import FlagTwoToneIcon from "@material-ui/icons/FlagTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const flagIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return FlagFilledIcon;
        case IconStyle.Outlined:
            return FlagOutlinedIcon;
        case IconStyle.Rounded:
            return FlagRoundedIcon;
        case IconStyle.TwoTone:
            return FlagTwoToneIcon;
        case IconStyle.Sharp:
            return FlagSharpIcon;
    }
};

export const FlagIcon = createIconComponent(flagIconSelector);
