import ArrowDownwardFilledIcon from "@material-ui/icons/ArrowDownward";
import ArrowDownwardOutlinedIcon from "@material-ui/icons/ArrowDownwardOutlined";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import ArrowDownwardSharpIcon from "@material-ui/icons/ArrowDownwardSharp";
import ArrowDownwardTwoToneIcon from "@material-ui/icons/ArrowDownwardTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const arrowDownwardIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return ArrowDownwardFilledIcon;
        case IconStyle.Outlined:
            return ArrowDownwardOutlinedIcon;
        case IconStyle.Rounded:
            return ArrowDownwardRoundedIcon;
        case IconStyle.TwoTone:
            return ArrowDownwardTwoToneIcon;
        case IconStyle.Sharp:
            return ArrowDownwardSharpIcon;
    }
};

export const ArrowDownwardIcon = createIconComponent(arrowDownwardIconSelector);
