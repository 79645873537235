import MenuBookFilledIcon from "@material-ui/icons/MenuBook";
import MenuBookOutlinedIcon from "@material-ui/icons/MenuBookOutlined";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import MenuBookSharpIcon from "@material-ui/icons/MenuBookSharp";
import MenuBookTwoToneIcon from "@material-ui/icons/MenuBookTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const menuBookIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return MenuBookFilledIcon;
        case IconStyle.Outlined:
            return MenuBookOutlinedIcon;
        case IconStyle.Rounded:
            return MenuBookRoundedIcon;
        case IconStyle.TwoTone:
            return MenuBookTwoToneIcon;
        case IconStyle.Sharp:
            return MenuBookSharpIcon;
    }
};

export const MenuBookIcon = createIconComponent(menuBookIconSelector);
