import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { useScheduleChecklistMutation } from "../../Api";
import { ChecklistProps } from "../ChecklistTypes";
import { DateTimePicker } from "../DateTimePicker";
import { ParameterFieldValue } from "../Parameters";
import { BadgeForScheduling } from "./BadgeForScheduling";
import { ChecklistProperty, ChecklistPropertyLabel, ChecklistPropertyValue } from "./ChecklistProperty";

export const useStyles = makeStyles((theme) => ({
    propertyGrid: {
        marginTop: theme.spacing(0),
    },
}));

const HeaderGridItem: React.FC = ({ children }) => (
    <Grid item xs={12} sm={6} lg={4} xl={3}>
        {children}
    </Grid>
);

export const ChecklistHeaderFC: React.FC<
    Pick<ChecklistProps, "parameters" | "isScheduled" | "startDate" | "checklistId" | "dueDate">
> = ({ parameters, isScheduled, startDate, checklistId, dueDate }) => {
    const classes = useStyles();
    const scheduleChecklistMutation = useScheduleChecklistMutation();
    return (
        <>
            <Grid container spacing={0} className={classes.propertyGrid}>
                <HeaderGridItem>
                    <ChecklistProperty centerVertically>
                        <ChecklistPropertyLabel>Start date</ChecklistPropertyLabel>
                        <ChecklistPropertyValue omitTypography>
                            <BadgeForScheduling needsScheduling={!isScheduled && startDate === undefined}>
                                <DateTimePicker
                                    initialValue={startDate}
                                    onSubmit={(date) =>
                                        scheduleChecklistMutation.mutateAsync({ checklistId, dueDate, startDate: date })
                                    }
                                />
                            </BadgeForScheduling>
                        </ChecklistPropertyValue>
                    </ChecklistProperty>
                </HeaderGridItem>
                <HeaderGridItem>
                    <ChecklistProperty centerVertically>
                        <ChecklistPropertyLabel>Needed by</ChecklistPropertyLabel>
                        <ChecklistPropertyValue omitTypography>
                            <BadgeForScheduling needsScheduling={!isScheduled && dueDate === undefined}>
                                <DateTimePicker
                                    initialValue={dueDate}
                                    onSubmit={(date) =>
                                        scheduleChecklistMutation.mutateAsync({ checklistId, dueDate: date, startDate })
                                    }
                                />
                            </BadgeForScheduling>
                        </ChecklistPropertyValue>
                    </ChecklistProperty>
                </HeaderGridItem>
            </Grid>
            <Box mt={2} mb={1}>
                {/* <Divider /> */}
                <Typography variant="h6">Parameters</Typography>
            </Box>
            <Grid container spacing={0} className={classes.propertyGrid}>
                {parameters.map((param, index) => (
                    <HeaderGridItem key={index}>
                        <ChecklistProperty>
                            <ChecklistPropertyLabel>{param.label}</ChecklistPropertyLabel>
                            <ChecklistPropertyValue omitTypography={param.type === "html"}>
                                <ParameterFieldValue {...param} />
                            </ChecklistPropertyValue>
                        </ChecklistProperty>
                    </HeaderGridItem>
                ))}
            </Grid>
        </>
    );
};

export const ChecklistHeader = React.memo(ChecklistHeaderFC);
