import { Theme, ThemeOptions, useMediaQuery } from "@material-ui/core";
import { createMuiTheme, ThemeProvider as MaterialUiThemeProvider } from "@material-ui/core/styles";
import constate from "constate";
import * as React from "react";
import { useState } from "react";
import { applyPalette } from "./Customization";

const applyDarkModePreference = (themeOptions: ThemeOptions, prefersDarkMode: boolean): ThemeOptions => ({
    ...themeOptions,
    palette: {
        ...themeOptions.palette,
        type: prefersDarkMode ? "dark" : "light",
    },
});

const useThemeOptions = (): {
    theme: Theme;
    themeOptions: ThemeOptions;
    setThemeOptions: React.Dispatch<React.SetStateAction<ThemeOptions>>;
} => {
    const [themeOptions, setThemeOptions] = useState<ThemeOptions>({});
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", { noSsr: true });
    const theme = React.useMemo(
        () => createMuiTheme(applyDarkModePreference(applyPalette(themeOptions), prefersDarkMode)),
        [themeOptions, prefersDarkMode]
    );
    return { theme, themeOptions, setThemeOptions };
};

const [ThemeOptionsProvider, useThemeContext] = constate(useThemeOptions);

export { useThemeContext };

export const ThemeOptionsConsumer: React.FC = (props) => {
    const { theme } = useThemeContext();
    return <MaterialUiThemeProvider theme={theme}>{props.children}</MaterialUiThemeProvider>;
};

export const ThemeProvider: React.FC = (props) => (
    <ThemeOptionsProvider>
        <ThemeOptionsConsumer>{props.children}</ThemeOptionsConsumer>
    </ThemeOptionsProvider>
);
