import { createContext } from "react";
import { AppConfig } from "./AppConfig";
import { SecurityScheme } from "./SecurityScheme";

export const defaultAppConfig = {
    securityScheme: SecurityScheme.Unsecured,
    apiBaseUrl: "",
    authority: "",
    clientId: "",
    scopes: [],
    applicationInsightsInstrumentationKey: "",
};

export const AppConfigContext = createContext<AppConfig>(defaultAppConfig);
