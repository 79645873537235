import constate from "constate";
import { useState } from "react";

const useCommand = (): {
    command: string;
    setCommand(command: string): void;
    clearCommand: () => void;
} => {
    const [command, setCommand] = useState("");
    const clearCommand = (): void => {
        if (command) {
            setCommand("");
        }
    };
    return { command, setCommand, clearCommand };
};

const [CommandProvider, useCommandContext] = constate(useCommand);

export { CommandProvider, useCommandContext };
export { CommandBufferProvider, useCommandBufferContext };

const useCommandBuffer = (): {
    commandBuffer: string;
    setCommandBuffer(command: string): void;
    clearCommandBuffer: () => void;
} => {
    const [commandBuffer, setCommandBuffer] = useState("");
    const clearCommandBuffer = (): void => {
        if (commandBuffer) {
            setCommandBuffer("");
        }
    };
    return { commandBuffer, setCommandBuffer, clearCommandBuffer };
};

const [CommandBufferProvider, useCommandBufferContext] = constate(useCommandBuffer);
