import { Avatar, Box, createStyles, ListItemIcon, makeStyles, SvgIconProps, Theme } from "@material-ui/core";
import * as React from "react";
import { useUserSettings } from "../../Api";
import { ChecklistSummaryIconColors } from "../../UserSettings";
import { ChecklistSummaryListItemProps } from "../ChecklistTypes";
import { getChecklistStepIcon } from "../getChecklistStepIcon";
import { LateBadge } from "../LateBadge";
import { ChecklistCurrentStepStatus, ChecklistOnlyStatus } from "../Status";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        badgeContainer: {
            // Add space for the "LATE" badge so it doesn't overrun the checklist summary details.
            marginRight: "3ch",
        },
        avatarGrey: {
            backgroundColor: theme.palette.grey.A200,
        },
        avatarSuccess: {
            backgroundColor: theme.palette.success.dark,
        },
        iconSuccess: {
            backgroundColor: theme.palette.success.dark,
        },
        avatarInfo: {
            backgroundColor: theme.palette.info.dark,
        },
        iconInfo: {
            backgroundColor: theme.palette.info.dark,
        },
        avatarError: {
            backgroundColor: theme.palette.warning.dark,
        },
        iconError: {
            backgroundColor: theme.palette.warning.dark,
        },
        badgeError: {
            backgroundColor: theme.palette.error.dark,
        },
        iconGrey: {
            backgroundColor: theme.palette.grey.A200,
        },

        backgroundColorSuccess: {
            backgroundColor: theme.palette.success.dark,
        },
        backgroundColorInfo: {
            backgroundColor: theme.palette.info.dark,
        },
        backgroundColorWarn: {
            backgroundColor: theme.palette.warning.dark,
        },
    })
);

export const ChecklistSummaryIcon: React.FC<SvgIconProps & Pick<ChecklistSummaryListItemProps, "checklistStatus">> = ({
    checklistStatus,
    ...iconProps
}) => {
    const Icon = getChecklistStepIcon(checklistStatus);
    return <Icon {...iconProps} />;
};

export const ChecklistSummaryAvatar: React.FC<Pick<ChecklistSummaryListItemProps, "checklistStatus">> = (props) => {
    const { checklistStatus } = props;
    const { checklistSummaryIconColors } = useUserSettings();
    const classes = useStyles();

    let avatarClassName: string;
    let iconClassName: string;
    if (checklistSummaryIconColors === ChecklistSummaryIconColors.OnlyUseGrey) {
        avatarClassName = classes.avatarGrey;
        iconClassName = classes.iconGrey;
    } else {
        switch (checklistStatus) {
            case ChecklistCurrentStepStatus.StepWaitingForAssignment:
                avatarClassName = classes.backgroundColorWarn;
                iconClassName = classes.backgroundColorWarn;
                break;
            case ChecklistOnlyStatus.ChecklistCreated:
            case ChecklistOnlyStatus.ChecklistWaitingForSchedule:
            case ChecklistOnlyStatus.ChecklistWaitingForStart:
            case ChecklistCurrentStepStatus.StepInProgress:
                avatarClassName = classes.backgroundColorInfo;
                iconClassName = classes.backgroundColorInfo;
                break;
            case ChecklistOnlyStatus.ChecklistCompleted:
                avatarClassName = classes.backgroundColorSuccess;
                iconClassName = classes.backgroundColorSuccess;
                break;
        }
    }

    return (
        <Avatar className={avatarClassName}>
            <ChecklistSummaryIcon checklistStatus={checklistStatus} className={iconClassName} />
        </Avatar>
    );
};

const ChecklistSummaryListItemIconFC: React.FC<Pick<ChecklistSummaryListItemProps, "checklistStatus" | "isLate">> = (
    props
) => {
    const { checklistStatus, isLate } = props;
    const classes = useStyles();

    return (
        <ListItemIcon>
            <Box className={classes.badgeContainer}>
                <LateBadge isLate={isLate} childType="ChecklistSummaryIcon">
                    <ChecklistSummaryAvatar checklistStatus={checklistStatus} />
                </LateBadge>
            </Box>
        </ListItemIcon>
    );
};

export const ChecklistSummaryListItemIcon = React.memo(ChecklistSummaryListItemIconFC);
