export interface ProblemDetails {
    /**
     * The HTTP status code([RFC7231], Section 6) generated by the origin server for this occurrence of the problem.
     */
    status: number;
    /**
     * A URI reference [RFC3986] that identifies the problem type. This specification encourages that, when
     * dereferenced, it provide human-readable documentation for the problem type (e.g., using HTML
     * [W3C.REC-html5-20141028]). When this member is not present, its value is assumed to be "about:blank".
     */
    type: string;
    /**
     * A short, human-readable summary of the problem type.  It SHOULD NOT change from occurrence to occurrence of the
     * problem, except for purposes of localization(e.g., using proactive content negotiation; see[RFC7231], Section
     * 3.4).
     */
    title: string;
    /** A human-readable explanation specific to this occurrence of the problem. */
    detail?: string;
    /** A developer-readable explataion of an exception's detail. */
    exception?: string;
}

export const isProblemDetails = (variableToCheck: unknown): variableToCheck is ProblemDetails =>
    (variableToCheck as ProblemDetails).status !== undefined &&
    (variableToCheck as ProblemDetails).type !== undefined &&
    (variableToCheck as ProblemDetails).title !== undefined;
