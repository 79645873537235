import { IconStyle } from "./Icons/IconStyle";

export enum MainMenuLayout {
    Responsive = "Responsive",
    Manual = "Manual",
}

export enum WorkflowMainMenuLayout {
    Flat = "Flat",
    Nested = "Nested",
    Hidden = "Hidden",
}

/** Color scheme used on checklist summary icons. */
export enum ChecklistSummaryIconColors {
    UseAllStatusColors = "UseAllStatusColors",
    OnlyUseGrey = "OnlyUseGrey",
}

/** Color scheme used on the checklist step icons. */
export enum ChecklistStepIconColors {
    OnlyCurrentStepHasColor = "OnlyCurrentStepHasColor",
    CurrentStepAndCompletedStepsHaveColor = "CurrentStepAndCompletedStepsHaveColor",
    AllStepsHaveColor = "AllStepsHaveColor",
}

export enum ChecklistSummaryCompletedIconEnum {
    TwoChecks = "TwoChecks",
}

export enum ChecklistStepNotReadyIconEnum {
    UncheckedCheckbox = "UncheckedCheckbox",
}

export enum ChecklistStepWaitingForAssignmentIconEnum {
    ExclamationPoint = "ExclamationPoint",
    Hourglass = "Hourglass",
    AlarmClock = "AlarmClock",
    Hand = "Hand",
}

export enum ChecklistStepInProgressIconEnum {
    RunningPerson = "RunningPerson",
    ExclationPoint = "ExclamationPoint",
    PaperAirplane = "PaperAirplane",
}

export enum ChecklistStepCompletedIconEnum {
    OneCheck = "OneCheck",
    CheckedCheckbox = "CheckedCheckbox",
}

export enum MenuImplementation {
    GreenMenu = "GreenMenu",
    Links = "Links",
    Buttons = "Buttons",
}

export enum ScreenImplementation {
    GreenScreen = "GreenScreen",
    Custom = "Custom",
}

export enum ReportSplitDirection {
    Vertical = "Vertical",
    Horizontal = "Horizontal",
}

export enum ProfilePageLayout {
    Card = "Card",
    Table = "Table",
}

export enum DataFileUtilityTabOrientation {
    Vertical = "Vertical",
    Horizontal = "Horizontal",
    Hidden = "Hidden",
}

export enum DataFileUtilityFieldLayout {
    Rows = "Rows",
    SingleColumn = "SingleColumn",
}

export enum DataFileUtilityFieldLabelLayout {
    NameOnly = "NameOnly",
    NameAndMaxLength = "NameAndMaxLength",
}

export enum ScreenListPageLayout {
    ListOfLinks = "List of links",
    Thumbnails = "Thumbnails",
}

export enum FallbackAvatar {
    GeometricPattern = "identicon",
    Monsterid = "monsterid",
    Wavatar = "wavatar",
    Retro = "retro",
    Robohash = "robohash",
}

export const fallbackAvatarNames: Record<FallbackAvatar, string> = {
    [FallbackAvatar.GeometricPattern]: "geometric pattern",
    [FallbackAvatar.Monsterid]: "monster",
    [FallbackAvatar.Wavatar]: "face",
    [FallbackAvatar.Retro]: "arcade",
    [FallbackAvatar.Robohash]: "robot",
};

export const fallbackAvatarDescriptions: Record<FallbackAvatar, string> = {
    [FallbackAvatar.GeometricPattern]: "a geometric pattern based on an email hash",
    [FallbackAvatar.Monsterid]: "a generated 'monster' with different colors, faces, etc",
    [FallbackAvatar.Wavatar]: "generated faces with differing features and backgrounds",
    [FallbackAvatar.Retro]: "awesome generated, 8-bit arcade-style pixelated faces",
    [FallbackAvatar.Robohash]: "a generated robot with different colors, faces, etc",
};

export enum MonacoTheme {
    OperatingSystem = "OperatingSystem",
    Dark = "vs-dark",
    Light = "vs",
    HighContrast = "hc-black",
    Active4D = "Active4D",
    AllHallowsEve = "All Hallows Eve",
    Amy = "Amy",
    BirdsOfParadise = "Birds of Paradise",
    Blackboard = "Blackboard",
    BrillianceBlack = "Brilliance Black",
    BrillianceDull = "Brilliance Dull",
    ChromeDevTools = "Chrome DevTools",
    CloudsMidnight = "Clouds Midnight",
    Clouds = "Clouds",
    Cobalt = "Cobalt",
    Dawn = "Dawn",
    Dreamweaver = "Dreamweaver",
    Eiffel = "Eiffel",
    EspressoLibre = "Espresso Libre",
    GitHub = "GitHub",
    IDLE = "IDLE",
    Katzenmilch = "Katzenmilch",
    KuroirTheme = "Kuroir Theme",
    LAZY = "LAZY",
    MagicWBAmiga = "MagicWB (Amiga)",
    MerbivoreSoft = "Merbivore Soft",
    Merbivore = "Merbivore",
    MonokaiBright = "Monokai Bright",
    Monokai = "Monokai",
    NightOwl = "Night Owl",
    OceanicNext = "Oceanic Next",
    PastelsOnDark = "Pastels on Dark",
    SlushAndPoppies = "Slush and Poppies",
    SolarizedDark = "Solarized-dark",
    SolarizedLight = "Solarized-light",
    SpaceCadet = "SpaceCadet",
    Sunburst = "Sunburst",
    TextmateMacClassic = "Textmate (Mac Classic)",
    TomorrowNightBlue = "Tomorrow-Night-Blue",
    TomorrowNightBright = "Tomorrow-Night-Bright",
    TomorrowNightEighties = "Tomorrow-Night-Eighties",
    TomorrowNight = "Tomorrow-Night",
    Tomorrow = "Tomorrow",
    Twilight = "Twilight",
    UpstreamSunburst = "Upstream Sunburst",
    VibrantInk = "Vibrant Ink",
    Xcode = "Xcode_default",
    Zenburnesque = "Zenburnesque",
    IPlastic = "iPlastic",
    IdleFingers = "idleFingers",
    KrTheme = "krTheme",
    MonoIndustrial = "monoindustrial",
}

export const monacoThemeDescriptions: Partial<Record<MonacoTheme, string>> = {
    [MonacoTheme.OperatingSystem]: "Operating system preference",
    [MonacoTheme.Dark]: "Dark",
    [MonacoTheme.Light]: "Light",
    [MonacoTheme.HighContrast]: "High contrast",
};

export interface UserSettings {
    mainMenuLayout: MainMenuLayout;
    workflowMainMenuLayout: WorkflowMainMenuLayout;
    checklistSummaryIconColors: ChecklistSummaryIconColors;
    checklistStepIconColors: ChecklistStepIconColors;
    checklistSummaryCompletedIcon: ChecklistSummaryCompletedIconEnum;
    checklistStepNotReadyIcon: ChecklistStepNotReadyIconEnum;
    checklistStepWaitingForAssignmentIcon: ChecklistStepWaitingForAssignmentIconEnum;
    checklistStepInProgressIcon: ChecklistStepInProgressIconEnum;
    checklistStepCompletedIcon: ChecklistStepCompletedIconEnum;
    hubPageRefetchIntervalInSeconds: number;
    checklistPageRefetchIntervalInSeconds: number;
    enableThreeStateBulkToggleOnChecklistDetail: boolean;
    profilePageLayout: ProfilePageLayout;
    dataFileUtilityTabOrientation: DataFileUtilityTabOrientation;
    dataFileUtilityFieldLayout: DataFileUtilityFieldLayout;
    dataFileUtilityFieldLabelLayout: DataFileUtilityFieldLabelLayout;
    textboxOverwriteMode: boolean;
    screenListPageLayout: ScreenListPageLayout;
    iconStyle: IconStyle;
    menuScreenLinksAreClickable: boolean;
    wobblyZoom: boolean;
    enableBrowserSpellCheck: boolean;
    fallbackAvatar: FallbackAvatar;
    enableMonaco: boolean;
    monacoTheme: MonacoTheme;
    showApiDashboardInMenu: boolean;
    showMenuListInMenu: boolean;
    showScreenListInMenu: boolean;
    screenImplementation: ScreenImplementation;
    menuImplementation: MenuImplementation;
    enableSearch: boolean;
    reportSplitDirection: ReportSplitDirection;
}

export const defaultUserSettings: UserSettings = {
    mainMenuLayout: MainMenuLayout.Manual,
    workflowMainMenuLayout: WorkflowMainMenuLayout.Nested,
    checklistSummaryIconColors: ChecklistSummaryIconColors.UseAllStatusColors,
    checklistStepIconColors: ChecklistStepIconColors.OnlyCurrentStepHasColor,
    checklistSummaryCompletedIcon: ChecklistSummaryCompletedIconEnum.TwoChecks,
    checklistStepNotReadyIcon: ChecklistStepNotReadyIconEnum.UncheckedCheckbox,
    checklistStepWaitingForAssignmentIcon: ChecklistStepWaitingForAssignmentIconEnum.ExclamationPoint,
    checklistStepInProgressIcon: ChecklistStepInProgressIconEnum.RunningPerson,
    checklistStepCompletedIcon: ChecklistStepCompletedIconEnum.OneCheck,
    hubPageRefetchIntervalInSeconds: 60,
    checklistPageRefetchIntervalInSeconds: 0,
    enableThreeStateBulkToggleOnChecklistDetail: true,
    profilePageLayout: ProfilePageLayout.Card,
    dataFileUtilityTabOrientation: DataFileUtilityTabOrientation.Vertical,
    dataFileUtilityFieldLayout: DataFileUtilityFieldLayout.Rows,
    dataFileUtilityFieldLabelLayout: DataFileUtilityFieldLabelLayout.NameOnly,
    textboxOverwriteMode: false,
    screenListPageLayout: ScreenListPageLayout.ListOfLinks,
    iconStyle: IconStyle.TwoTone,
    menuScreenLinksAreClickable: false,
    wobblyZoom: false,
    enableBrowserSpellCheck: false,
    fallbackAvatar: FallbackAvatar.Robohash,
    enableMonaco: false,
    monacoTheme: MonacoTheme.OperatingSystem,
    showApiDashboardInMenu: false,
    showMenuListInMenu: false,
    showScreenListInMenu: false,
    screenImplementation: ScreenImplementation.Custom,
    menuImplementation: MenuImplementation.Links,
    enableSearch: false,
    reportSplitDirection: ReportSplitDirection.Vertical,
};

/**
 * Convert the given object into a valid UserSettings object by populating missing properties with defaults and
 * removing extraneous properties.
 * @param userSettings object to be transformed into a valid UserSettings object
 */
export const cleanseUserSettings = (userSettings?: UserSettings | Record<string, unknown>): UserSettings => {
    if (!defaultUserSettings) {
        return defaultUserSettings;
    }

    // fill in missing properties
    const filled = {
        ...defaultUserSettings,
        ...userSettings,
    };

    // Remove excess properties.
    const {
        mainMenuLayout,
        workflowMainMenuLayout,
        checklistSummaryIconColors,
        checklistStepIconColors,
        checklistSummaryCompletedIcon,
        checklistStepNotReadyIcon,
        checklistStepWaitingForAssignmentIcon,
        checklistStepInProgressIcon,
        checklistStepCompletedIcon,
        hubPageRefetchIntervalInSeconds,
        checklistPageRefetchIntervalInSeconds,
        enableThreeStateBulkToggleOnChecklistDetail,
        profilePageLayout,
        dataFileUtilityTabOrientation,
        dataFileUtilityFieldLayout,
        dataFileUtilityFieldLabelLayout,
        textboxOverwriteMode,
        screenListPageLayout,
        iconStyle,
        menuScreenLinksAreClickable,
        wobblyZoom,
        enableBrowserSpellCheck,
        fallbackAvatar,
        enableMonaco,
        monacoTheme,
        showApiDashboardInMenu,
        showMenuListInMenu,
        showScreenListInMenu,
        screenImplementation,
        menuImplementation,
        enableSearch,
        reportSplitDirection,
    } = filled;
    const filledAndPruned = {
        mainMenuLayout,
        workflowMainMenuLayout,
        checklistSummaryIconColors,
        checklistStepIconColors,
        checklistSummaryCompletedIcon,
        checklistStepNotReadyIcon,
        checklistStepWaitingForAssignmentIcon,
        checklistStepInProgressIcon,
        checklistStepCompletedIcon,
        hubPageRefetchIntervalInSeconds,
        checklistPageRefetchIntervalInSeconds,
        enableThreeStateBulkToggleOnChecklistDetail,
        profilePageLayout,
        dataFileUtilityTabOrientation,
        dataFileUtilityFieldLayout,
        dataFileUtilityFieldLabelLayout,
        textboxOverwriteMode,
        screenListPageLayout,
        iconStyle,
        menuScreenLinksAreClickable,
        wobblyZoom,
        enableBrowserSpellCheck,
        fallbackAvatar,
        enableMonaco,
        monacoTheme,
        showApiDashboardInMenu,
        showMenuListInMenu,
        showScreenListInMenu,
        screenImplementation,
        menuImplementation,
        enableSearch,
        reportSplitDirection,
    };

    try {
        if (userSettings !== undefined && JSON.stringify(userSettings) !== JSON.stringify(filledAndPruned)) {
            console.warn("cleanseUserSettings", { before: userSettings, after: filledAndPruned });
        }
    } catch (ex) {
        console.error("Failed to cleanse", userSettings);
    }

    return filledAndPruned;
};
