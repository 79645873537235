import SearchFilledIcon from "@material-ui/icons/Search";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import SearchSharpIcon from "@material-ui/icons/SearchSharp";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const searchIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return SearchFilledIcon;
        case IconStyle.Outlined:
            return SearchOutlinedIcon;
        case IconStyle.Rounded:
            return SearchRoundedIcon;
        case IconStyle.TwoTone:
            return SearchTwoToneIcon;
        case IconStyle.Sharp:
            return SearchSharpIcon;
    }
};

export const SearchIcon = createIconComponent(searchIconSelector);
