import { TextField } from "@material-ui/core";
import * as React from "react";
import { ChecklistParameterEditorProps } from "./ChecklistParameterEditorProps";

interface StringChecklistParameterEditorProps extends ChecklistParameterEditorProps<string> {
    textArea?: boolean;
}

const stringEditorInputProps = { autoComplete: "off" };

export const StringChecklistParameterEditor: React.FC<StringChecklistParameterEditorProps> = ({
    label,
    value,
    name,
    autoFocus,
    onChange,
    onBlur,
    error,
    helperText,
    textArea,
}) => {
    const extraProps = textArea
        ? {
              multiline: true,
              fullWidth: true,
          }
        : undefined;
    return (
        <TextField
            {...{ label, value, name, autoFocus, onChange, onBlur, error, helperText }}
            {...extraProps}
            margin="normal"
            inputProps={stringEditorInputProps}
        ></TextField>
    );
};
