import DynamicFeedFilledIcon from "@material-ui/icons/DynamicFeed";
import DynamicFeedOutlinedIcon from "@material-ui/icons/DynamicFeedOutlined";
import DynamicFeedRoundedIcon from "@material-ui/icons/DynamicFeedRounded";
import DynamicFeedSharpIcon from "@material-ui/icons/DynamicFeedSharp";
import DynamicFeedTwoToneIcon from "@material-ui/icons/DynamicFeedTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const dynamicFeedIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return DynamicFeedFilledIcon;
        case IconStyle.Outlined:
            return DynamicFeedOutlinedIcon;
        case IconStyle.Rounded:
            return DynamicFeedRoundedIcon;
        case IconStyle.TwoTone:
            return DynamicFeedTwoToneIcon;
        case IconStyle.Sharp:
            return DynamicFeedSharpIcon;
    }
};

export const DynamicFeedIcon = createIconComponent(dynamicFeedIconSelector);
