import CssBaseline from "@material-ui/core/CssBaseline";
import { ConfirmProvider } from "material-ui-confirm";
import * as React from "react";
import { Suspense, useEffect, useMemo } from "react";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useRouteMatch } from "react-router";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ClearQueryCacheOnAuthChange } from "./Api";
import { queryClient } from "./Api/queryClient";
import { AppConfigProvider } from "./AppConfig";
import { AppInsights, AppInsightsProvider } from "./AppInsights";
import { AppFrame, AppHeaderSpacer, AppMain } from "./AppLayout";
import { AuthProvider, RequireAuth } from "./Auth";
import {
    checklistCreationTemplatePickerExactPageRoutePath,
    checklistDetailPageRoutePath,
    hubPageRoutePath,
} from "./Checklist";
import { checklistCreationParameterExactPageRoutePath } from "./Checklist/checklistRouting";
import { CommandBufferProvider, CommandProvider } from "./CommandContext";
import { dataFilePageExactRoutePaths } from "./DataFile";
import { diagnosticFeatureFlags } from "./diagnosticFeatureFlags";
import { GuestbookPage } from "./Guestbook";
import { Header } from "./Header";
import {
    ApiDashboard,
    ChecklistCreationParameterPage,
    ChecklistCreationTemplatePickerPage,
    ChecklistDetailPage,
    DataFilePage,
    Explorer,
    Help,
    Home,
    HubPage,
    MenuListPage,
    MenuPage,
    ProcedurePage,
    Profile,
    ReportListPage,
    ReportPage,
    ScreenListPage,
    ScreenPage,
} from "./Lazy";
import { MenuProvider } from "./MenuContext";
import { NavContainer } from "./NavContainer";
import { PageSubTitleProvider } from "./PageSubTitleContext";
import { PageTitleProvider } from "./PageTitleContext";
import { SnackContainer } from "./SnackContainer";
import { SpinnerContainer } from "./SpinnerContainer";
import { SpinnerProvider, useSpinner } from "./SpinnerContext";
import { ThemeProvider } from "./ThemeContext";
import { UserFeedbackProvider } from "./UserFeedbackContext";

/** Setup all the providers used across the entire app. */
export const AppContextProviders: React.FC = ({ children }) => {
    return (
        <QueryClientProvider client={queryClient}>
            <SpinnerProvider>
                <Suspense fallback={<SuspendFallback />}>
                    <AppConfigProvider>
                        <AppInsightsProvider>
                            <AppInsights>
                                <AuthProvider>
                                    <BrowserRouter>
                                        <ThemeProvider>
                                            <MenuProvider>
                                                <CommandProvider>
                                                    <CommandBufferProvider>
                                                        <PageSubTitleProvider>
                                                            <PageTitleProvider>
                                                                <UserFeedbackProvider>
                                                                    <ConfirmProvider>{children}</ConfirmProvider>
                                                                </UserFeedbackProvider>
                                                            </PageTitleProvider>
                                                        </PageSubTitleProvider>
                                                    </CommandBufferProvider>
                                                </CommandProvider>
                                            </MenuProvider>
                                        </ThemeProvider>
                                    </BrowserRouter>
                                    <ClearQueryCacheOnAuthChange />
                                </AuthProvider>
                            </AppInsights>
                        </AppInsightsProvider>
                    </AppConfigProvider>
                    <SnackContainer />
                    <SpinnerContainer />
                </Suspense>
            </SpinnerProvider>
            {diagnosticFeatureFlags.reactQueryDevtools && <ReactQueryDevtools />}
        </QueryClientProvider>
    );
};

const SuspendFallback: React.FC = () => {
    const { startSpinner } = useSpinner();
    useEffect(() => {
        const stopSpinner = startSpinner("SuspendFallback");
        return (): void => stopSpinner();
    }, [startSpinner]);
    return <></>;
};

export const App: React.FC = () => {
    const menuMatch = useRouteMatch({
        path: "/menus/:library/:menu",
    });
    const screenMatch = useRouteMatch({
        path: "/screens/:library/:screen",
    });
    const procedureMatch = useRouteMatch({
        path: "/procedures/:library/:procedure",
    });
    const explorerMatch = useRouteMatch({
        path: "/explorer",
    });
    const reportListMatch = useRouteMatch({
        path: "/reports",
    });

    return useMemo(() => {
        const isUnpaddedPage = Boolean(
            menuMatch || screenMatch || procedureMatch || explorerMatch || (reportListMatch && !reportListMatch.isExact)
        );

        return (
            <>
                <CssBaseline />
                <AppFrame>
                    <Header />
                    <NavContainer />

                    <AppMain unpadded={isUnpaddedPage}>
                        <AppHeaderSpacer />
                        <Suspense fallback={<SuspendFallback />}>
                            <Switch>
                                <Route exact path="/">
                                    <Home />
                                </Route>
                                <Route exact path="/help">
                                    <Help />
                                </Route>
                                <Route exact path="/profile">
                                    <RequireAuth>
                                        <Profile />
                                    </RequireAuth>
                                </Route>
                                <Route exact path="/menus">
                                    <MenuListPage />
                                </Route>
                                <Route exact path="/menus/:library/:menu">
                                    <RequireAuth>
                                        <MenuPage />
                                    </RequireAuth>
                                </Route>
                                <Route exact path="/procedures/:library/:procedure">
                                    <RequireAuth>
                                        <ProcedurePage />
                                    </RequireAuth>
                                </Route>
                                <Route exact path="/screens">
                                    <ScreenListPage />
                                </Route>
                                <Route exact path="/screens/:library/:screen">
                                    <RequireAuth>
                                        <ScreenPage />
                                    </RequireAuth>
                                </Route>
                                <Route path="/api-dashboard">
                                    <RequireAuth>
                                        <ApiDashboard />
                                    </RequireAuth>
                                </Route>
                                <Route exact path="/reports">
                                    <RequireAuth>
                                        <ReportListPage />
                                    </RequireAuth>
                                </Route>
                                <Route path="/reports/:name*">
                                    <RequireAuth>
                                        <ReportPage />
                                    </RequireAuth>
                                </Route>
                                <Route path="/explorer/:path*">
                                    <RequireAuth>
                                        <Explorer />
                                    </RequireAuth>
                                </Route>
                                <Route exact path="/guestbook">
                                    <GuestbookPage />
                                </Route>
                                {dataFilePageExactRoutePaths.map((p) => (
                                    <Route key={p} exact path={p}>
                                        <RequireAuth>
                                            <DataFilePage />
                                        </RequireAuth>
                                    </Route>
                                ))}
                                <Route exact path={checklistCreationTemplatePickerExactPageRoutePath}>
                                    <RequireAuth>
                                        <ChecklistCreationTemplatePickerPage />
                                    </RequireAuth>
                                </Route>
                                <Route exact path={checklistCreationParameterExactPageRoutePath}>
                                    <RequireAuth>
                                        <ChecklistCreationParameterPage />
                                    </RequireAuth>
                                </Route>
                                <Route path={checklistDetailPageRoutePath}>
                                    <RequireAuth>
                                        <ChecklistDetailPage />
                                    </RequireAuth>
                                </Route>
                                <Route path={hubPageRoutePath}>
                                    <RequireAuth>
                                        <HubPage />
                                    </RequireAuth>
                                </Route>
                            </Switch>
                        </Suspense>
                    </AppMain>
                </AppFrame>
            </>
        );
    }, [menuMatch, screenMatch, procedureMatch, explorerMatch, reportListMatch]);
};
