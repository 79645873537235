import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core";
import { yellow } from "@material-ui/core/colors";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import html2canvas from "html2canvas";
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";
import { useRef, useState } from "react";
import * as React from "react";
import { useAuthorizedFetch } from "./Api";
import { useShowError } from "./ErrorContext";
import { ErrorMessage } from "./ErrorMessage";
import { CloseIcon, ExpandMoreIcon, SendIcon, SentimentSatisfiedAltIcon, SentimentVeryDissatisfiedIcon } from "./Icons";
import { useIsMounted } from "./isMounted";
import { createLogger } from "./log";
import { useSetSnack } from "./SnackContext";
import { useSpinner } from "./SpinnerContext";
import { UserFeedbackMood, useUserFeedback } from "./UserFeedbackContext";

const log = createLogger("UserFeedback");

const readFile = (file: Blob): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event?.target?.result);
        };
        reader.onerror = (event) => {
            reader.abort();
            reject(event);
        };
        reader.readAsDataURL(file);
    });
};

const convertBlobToFileObject = (blob: Blob, fileName: string): Promise<FileObject> => {
    const file = new File([blob], fileName, { type: "image/png" });
    return convertFileToFileObject(file);
};

const convertFileToFileObject = async (file: File): Promise<FileObject> => {
    const data = await readFile(file);
    return {
        file,
        data,
    };
};

interface UserFeedbackProps {
    onClose(): void;
}

const useStyles = makeStyles({
    mood: {},
    highlightedMood: {
        borderRadius: "50%",
        backgroundColor: yellow[600],
    },
});

const UserFeedbackCardHeader = withStyles((_theme: Theme) =>
    createStyles({
        root: {
            paddingBottom: 0,
        },
    })
)(CardHeader);

const UserFeedbackCard = withStyles((_theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 650,
        },
    })
)(Card);

export const UserFeedback: React.FC<UserFeedbackProps> = (props) => {
    const {
        userFeedback,
        setUserFeedbackMood,
        setUserFeedbackComments,
        setUserFeedbackAttachments,
        setUserFeedbackAttachmentsExpanded,
        clearUserFeedback,
    } = useUserFeedback();
    const { mood, comments, attachmentsExpanded } = userFeedback;
    const { authorizedFetch } = useAuthorizedFetch();

    const [submitting, setSubmitting] = useState(false);
    const commentsRef = useRef<HTMLDivElement>(null);
    const classes = useStyles();
    const { startSpinner } = useSpinner();
    const showError = useShowError();
    const { setSnack } = useSetSnack();
    const isMounted = useIsMounted();

    const [includeScreenRender, setIncludeScreenRender] = useState(true);

    const isSendEnabled = !submitting && (includeScreenRender || comments || mood || userFeedback.attachments.length);

    // eslint-disable-next-line @typescript-eslint/ban-types
    const handleAttachmentsAccordionChange = (event: React.ChangeEvent<{}>, isExpanded: boolean): void =>
        setUserFeedbackAttachmentsExpanded(isExpanded);

    const handleMoodChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, moodChosen: UserFeedbackMood) => void = (
        _event,
        moodChosen
    ) => {
        const newMood = moodChosen === mood ? undefined : moodChosen;
        setUserFeedbackMood(newMood);
        commentsRef.current?.focus();
    };

    const handleCloseClick: (event: React.MouseEvent) => void = (_event) => {
        clearUserFeedback();
        props.onClose();
    };

    const handleAttachScreenRenderClickAsync = async (): Promise<void> => {
        const root = document.querySelector("#root");
        if (!root) {
            return;
        }
        const canvas = await html2canvas(root as HTMLElement);
        const blob = await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve));
        if (blob) {
            const fileInfo = await convertBlobToFileObject(blob, "screen-render.png");
            setUserFeedbackAttachments([...userFeedback.attachments, fileInfo]);
            setIncludeScreenRender(false);
        }
    };

    const handleAttachScreenRenderClick: (event: React.MouseEvent) => void = (_event) => {
        handleAttachScreenRenderClickAsync();
    };

    const handleDropzoneAdd = (newFiles: FileObject[]): void => {
        setUserFeedbackAttachments([...userFeedback.attachments, ...newFiles]);
    };

    const handleDropzoneDelete = (deletedFileObject: FileObject, _index: number): void => {
        setUserFeedbackAttachments(userFeedback.attachments.filter((fo) => fo !== deletedFileObject));
    };

    const submitFeedback = async (): Promise<void> => {
        const root = document.querySelector("#root");
        if (!root) {
            return;
        }
        clearUserFeedback();
        setSubmitting(true);
        props.onClose();

        const apiUrl = "/user-feedback";
        const stopSpinner = startSpinner(apiUrl);
        try {
            const formdata = new FormData();

            if (includeScreenRender) {
                const canvas = await html2canvas(root as HTMLElement);
                const blob = await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve));
                if (blob) {
                    formdata.append("files", blob, "screen-rendering.png");
                }
            }
            userFeedback.attachments.forEach((attachment) => {
                formdata.append("files", attachment.file);
            });
            formdata.append("mood", mood || "");
            formdata.append("comments", comments);
            formdata.append("url", window.location.href);

            const response = await authorizedFetch(apiUrl, {
                method: "POST",
                body: formdata,
            });
            if (response.status >= 200 && response.status < 300) {
                setSnack("Feedback submitted.");
            } else {
                showError(ErrorMessage.FailedToSubmitFeedback, response);
            }
        } finally {
            stopSpinner();
            if (isMounted()) {
                setSubmitting(false);
            }
        }
    };

    return (
        // <Card style={{ maxWidth: 650 }}>
        <UserFeedbackCard>
            <UserFeedbackCardHeader
                title="Send us your feedback"
                action={
                    <IconButton aria-label="cancel" onClick={handleCloseClick}>
                        <CloseIcon />
                    </IconButton>
                }
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>How was your experience?</Grid>
                            <Grid item>
                                <ToggleButtonGroup
                                    value={mood}
                                    exclusive
                                    onChange={handleMoodChange}
                                    aria-label="text alignment"
                                >
                                    <ToggleButton value="happy" aria-label="happy">
                                        <SentimentSatisfiedAltIcon
                                            className={
                                                mood === UserFeedbackMood.Happy ? classes.highlightedMood : classes.mood
                                            }
                                        />
                                    </ToggleButton>
                                    <ToggleButton value="sad" aria-label="sad">
                                        <SentimentVeryDissatisfiedIcon
                                            className={
                                                mood === UserFeedbackMood.Sad ? classes.highlightedMood : classes.mood
                                            }
                                        />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="subtitle1" color="textSecondary">
                                    Other ways to contact us
                                </Typography>
                                <List>
                                    <ListItem>
                                        Email:&nbsp;
                                        <Link href="mailto:support@TwinOakSolutions.com">
                                            support@TwinOakSolutions.com
                                        </Link>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            type="text"
                            label="Tell us why?"
                            multiline={true}
                            rows={1}
                            rowsMax={20}
                            inputRef={commentsRef}
                            value={comments}
                            onChange={(e) => {
                                setUserFeedbackComments(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion
                            elevation={3}
                            onChange={handleAttachmentsAccordionChange}
                            expanded={attachmentsExpanded}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Attach files</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Button onClick={handleAttachScreenRenderClick} variant="outlined">
                                            Capture screenshot
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DropzoneAreaBase
                                            fileObjects={userFeedback.attachments}
                                            onAdd={handleDropzoneAdd}
                                            onDelete={handleDropzoneDelete}
                                            onAlert={(message, variant) => log(`${variant}: ${message}`)}
                                            showFileNames={true}
                                            showAlerts={["error", "info"]}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions style={{ marginBottom: "14px" }}>
                <Grid container justify="flex-end" spacing={2}>
                    <Grid item xs={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SendIcon />}
                            onClick={() => {
                                submitFeedback();
                            }}
                            disabled={!isSendEnabled}
                        >
                            Send
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
        </UserFeedbackCard>
    );
};
