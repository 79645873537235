import ScheduleFilledIcon from "@material-ui/icons/Schedule";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import ScheduleRoundedIcon from "@material-ui/icons/ScheduleRounded";
import ScheduleSharpIcon from "@material-ui/icons/ScheduleSharp";
import ScheduleTwoToneIcon from "@material-ui/icons/ScheduleTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const scheduleIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return ScheduleFilledIcon;
        case IconStyle.Outlined:
            return ScheduleOutlinedIcon;
        case IconStyle.Rounded:
            return ScheduleRoundedIcon;
        case IconStyle.TwoTone:
            return ScheduleTwoToneIcon;
        case IconStyle.Sharp:
            return ScheduleSharpIcon;
    }
};

export const ScheduleIcon = createIconComponent(scheduleIconSelector);
