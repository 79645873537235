import {
    BooleanParameterTypeKey,
    DateListParameterTypeKey,
    DateParameterTypeKey,
    NumberParameterTypeKey,
    StringParameterTypeKey,
    TextAreaParameterTypeKey,
    WebApiChecklistTemplateParameterTypeKey,
} from "../../Api";

export function getChecklistParameterDefaultValue(type: WebApiChecklistTemplateParameterTypeKey): unknown {
    switch (type) {
        default:
        case StringParameterTypeKey:
        case TextAreaParameterTypeKey:
            return "";
        case DateParameterTypeKey:
        case NumberParameterTypeKey:
            return null;
        case BooleanParameterTypeKey:
            return false;
        case DateListParameterTypeKey:
            return [];
    }
}
