export enum ErrorMessage {
    FailedToSpaConfig = "Failed to load spa configuration from web api.",
    FailedToLoadMenus = "Could not load menus.",
    FailedToLoadUserPreferences = "Failed to load user preferences.",
    FailedToSaveUserPreferences = "Failed to save user preferences.",
    FailedToLoadScreenDefinition = "Failed to load screen definition.",
    FailedToLoadScreenList = "Failed to load screen list.",
    MissingUserSettingName = "Cannot store user setting, because setting name was not provided.",
    FailedToRunProcedure = "Failed to run procedure.",
    FailedToSubmitFeedback = "Failed to submit feedback.",
    FailedToSaveDataFile = "Failed to save data file.",
    FailedToLoadChecklistSummaries = "Failed to load checklist summaries.",
    FailedToParseChecklistSummaries = "Failed to parse checklist summaries.",
    FailedToLoadChecklistTemplates = "Failed to load checklist templates.",
    FailedToParseChecklistTemplates = "Failed to parse checklist templates.",
    FailedToLoadChecklist = "Failed to load checklist.",
    FailedToParseChecklist = "Failed to parse checklist.",
    FailedToAssignStep = "Failed to assign step.",
    FailedToCompleteStep = "Failed to complete step.",
    FailedToLoadUsers = "Failed to load users.",
    FailedToStartChecklist = "Failed to start checklist.",
    FailedToScheduleChecklist = "Failed to schedule checklist.",
    FailedToAutoScheduleChecklist = "Failed to auto schedule checklist.",
    FailedToScheduleStep = "Failed to schedule step.",
    FailedToLoadDataFile = "Failed to load data file.",
    FailedToLoadDataFileList = "Failed to load data file list.",
    FailedToLoadReportList = "Failed to load report list.",
    FailedToParseReportList = "Failed to parse report list.",
}
