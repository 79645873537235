import { Box, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { PropsWithChildren } from "react";

export const useStyles = makeStyles((theme) => ({
    stepProperty: { display: "block" },
    stepPropertyLabel: {
        marginRight: theme.spacing(1),
        fontWeight: "bolder",
    },
}));

const ChecklistPropertyFC: React.FC<PropsWithChildren<{ centerVertically?: boolean }>> = ({
    children,
    centerVertically,
}) => {
    const classes = useStyles();

    return centerVertically ? (
        <Box display="flex" alignItems="center" height="100%">
            {children}
        </Box>
    ) : (
        <div className={classes.stepProperty}>{children}</div>
    );
};

export const ChecklistProperty = React.memo(ChecklistPropertyFC);

const ChecklistPropertyLabelFC: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
    const classes = useStyles();
    return (
        <Typography variant="body1" display="inline" className={classes.stepPropertyLabel}>
            {children}:
        </Typography>
    );
};

export const ChecklistPropertyLabel = React.memo(ChecklistPropertyLabelFC);

const ChecklistPropertyValueFC: React.FC<PropsWithChildren<{ omitTypography?: boolean }>> = ({
    omitTypography,
    children,
}) =>
    omitTypography ? (
        <>{children}</>
    ) : (
        <Typography variant="body1" display="inline">
            {children}
        </Typography>
    );

export const ChecklistPropertyValue = React.memo(ChecklistPropertyValueFC);
