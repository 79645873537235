import { isPseudoStepData, PseudoStepData, StepData } from "./ChecklistTypes";
import {
    PseudoChecklistCompletedStepId,
    PseudoChecklistCreateStepId,
    PseudoChecklistScheduleStepId,
    PseudoChecklistStartStepId,
} from "./Detail/PseudoChecklistStepIds";
import {
    ChecklistCurrentStepStatus,
    ChecklistNotCurrentStepStatus,
    ChecklistOnlyStatus,
    ChecklistStepStatus,
} from "./Status";

export function getStepStatusOfChecklistStepProp(step: StepData | PseudoStepData): ChecklistStepStatus {
    if (isPseudoStepData(step)) {
        switch (step.checklistStepId) {
            case PseudoChecklistCreateStepId:
                return ChecklistOnlyStatus.ChecklistCreated;
            case PseudoChecklistScheduleStepId:
                return ChecklistOnlyStatus.ChecklistWaitingForSchedule;
            case PseudoChecklistStartStepId:
                return ChecklistOnlyStatus.ChecklistWaitingForStart;
            case PseudoChecklistCompletedStepId:
                return ChecklistOnlyStatus.ChecklistCompleted;
        }
    } else {
        const { completedDate, isCurrentStep, assignedToUserId } = step;
        if (completedDate !== undefined) {
            return ChecklistNotCurrentStepStatus.StepCompleted;
        } else if (!isCurrentStep) {
            return ChecklistNotCurrentStepStatus.StepWaitingForPrerequisites;
        } else if (assignedToUserId === undefined) {
            return ChecklistCurrentStepStatus.StepWaitingForAssignment;
        } else {
            return ChecklistCurrentStepStatus.StepInProgress;
        }
    }
}
