import { Command, DataFileUtilityCommandKey, MenuCommandKey, ProcedureCommandKey } from "../Command";
import { urlToDataFile } from "../DataFile";

export const menuPageHref: (props: { library: string; menu: string }) => string = ({ library, menu }) =>
    `/menus/${library}/${menu}`;

export const procedurePageHref: (props: { library: string; procedure: string }) => string = ({ library, procedure }) =>
    `/procedures/${library}/${procedure}`;

export const dfuPageHref: (props: { dfuName: string; fileName: string }) => string = ({
    dfuName: dataFile,
    fileName: file,
}) => urlToDataFile({ dfuName: dataFile, fileName: file });

export const convertMenuCommandToHref = (command: Command): string | undefined => {
    return command.type === ProcedureCommandKey
        ? procedurePageHref(command)
        : command.type === MenuCommandKey
        ? menuPageHref(command)
        : command.type === DataFileUtilityCommandKey
        ? dfuPageHref(command)
        : undefined;
};
