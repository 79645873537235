import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core";
import { KeyboardDateTimePicker, KeyboardDateTimePickerProps, MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as React from "react";
import { KeyboardEventHandler, ReactNode, useCallback, useEffect, useState } from "react";
import { diagnosticFeatureFlags } from "../diagnosticFeatureFlags";
import { createLogger, noopLogger } from "../log";

const log = diagnosticFeatureFlags.logDateTimePicker ? createLogger("DateTimePicker") : noopLogger;

export const useStyles = makeStyles((_theme) => ({
    dateTimePickerCompact: {
        "& input.MuiInputBase-input": {
            width: "18ch",
        },
        "& .MuiInputAdornment-positionEnd": {
            marginLeft: 0,
        },
    },
}));

export const DateTimePicker: React.FC<
    Omit<KeyboardDateTimePickerProps, "value" | "onSubmit" | "onChange"> & {
        initialValue?: Date;
        onSubmit(date?: Date): void;
    }
> = ({ initialValue, onSubmit, ...props }) => {
    const [value, setValue] = useState(initialValue || null);
    const [error, setError] = useState<ReactNode>();

    useEffect(
        function setInitialValueEffect() {
            if (value !== initialValue) {
                setValue(initialValue || null);
            }
        },
        [initialValue, value]
    );

    useEffect(
        function logValueChangedEffect() {
            log("value changed", value);
        },
        [value]
    );

    const handleSubmitOld = useCallback(() => {
        const changed = value?.valueOf() !== initialValue?.valueOf();
        if (changed && !error) {
            log("handleSubmit triggering callback");
            onSubmit(value || undefined);
        } else {
            log("handleSubmit no callback because either there is an error or there is no change", {
                changed,
                error,
                value,
                initialValue,
            });
        }
    }, [value, error, initialValue, onSubmit]);

    // TODO: Figure out wtf is going on with handleSubmitOld.  When I call it directly from the picker event handlers,
    // the sequencing gets screwed up.  For example, the setValue call made in onChange doesn't take effect until after
    // onClose is fired.  I suspect there is a stale closure somewhere, maybe on value or initialValue.
    const [submitFlag, setSubmitFlag] = useState(false);
    const handleSubmit = () => setSubmitFlag(true);
    useEffect(
        function submitFlagEffect() {
            if (!submitFlag) {
                return;
            }
            setSubmitFlag(false);
            handleSubmitOld();
        },
        [submitFlag, handleSubmitOld]
    );

    const handleKeydown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            setSubmitFlag(true);
        }
    };

    const classes = useStyles();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
                variant="dialog"
                DialogProps={{ disableBackdropClick: true }}
                clearable
                ampm={true}
                value={value}
                format="M/d/yyyy h:mm a"
                mask="__/__/____ __:__ _M"
                className={classes.dateTimePickerCompact}
                minutesStep={15}
                {...props}
                onBlur={(event) => {
                    log("onBlur", event);
                    handleSubmit();
                    props.onBlur?.(event);
                }}
                onClose={() => {
                    log("onClose", { value });
                    handleSubmit();
                    props.onClose?.();
                }}
                onChange={(date, _value) => {
                    log("onChange", { date, _value });
                    setValue(date);
                }}
                onError={(error, value) => {
                    log("onError", { error, value });
                    setError(error);
                    props.onError?.(error, value);
                }}
                onKeyDown={handleKeydown}
            />
        </MuiPickersUtilsProvider>
    );
};
