import ZoomOutFilledIcon from "@material-ui/icons/ZoomOut";
import ZoomOutOutlinedIcon from "@material-ui/icons/ZoomOutOutlined";
import ZoomOutRoundedIcon from "@material-ui/icons/ZoomOutRounded";
import ZoomOutSharpIcon from "@material-ui/icons/ZoomOutSharp";
import ZoomOutTwoToneIcon from "@material-ui/icons/ZoomOutTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const zoomOutIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return ZoomOutFilledIcon;
        case IconStyle.Outlined:
            return ZoomOutOutlinedIcon;
        case IconStyle.Rounded:
            return ZoomOutRoundedIcon;
        case IconStyle.TwoTone:
            return ZoomOutTwoToneIcon;
        case IconStyle.Sharp:
            return ZoomOutSharpIcon;
    }
};

export const ZoomOutIcon = createIconComponent(zoomOutIconSelector);
