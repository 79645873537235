import queryString, { StringifiableRecord } from "query-string";
import { useCallback } from "react";
import { useAppConfig } from "../AppConfig";
import { useAuth } from "../Auth";

type RequestInitFunc = (init: RequestInit | undefined, accessToken: string) => RequestInit;

/**
 * Add the current access token to a fetch init parameter.
 * @param init The init argument used with a call to fetch.
 */
const withAuthHeaders: RequestInitFunc = (init, accessToken) => {
    const headers = { Authorization: `Bearer ${accessToken}` };
    if (!init) {
        init = { headers };
    } else if (!init.headers) {
        init.headers = headers;
    } else {
        init.headers = {
            ...init.headers,
            ...headers,
        };
    }
    return init;
};

/**
 * An easy, logical way to call TwinOak apis asynchronously across the network
 * @param relativeUrlPath Relative url path to the api to be called.
 * @param init Controls the behavior of the request.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 * @see https://fetch.spec.whatwg.org/
 */
export const useAuthorizedFetch = (): { authorizedFetch: FetchFunc } => {
    const { apiBaseUrl } = useAppConfig();
    const { isAccessTokenNeeded, getAccessToken } = useAuth();
    const authorizedFetch: FetchFunc = useCallback(
        async function (relativeUrlPath, init): Promise<Response> {
            const qs = init?.query === undefined ? "" : `?${queryString.stringify(init?.query)}`;
            const apiBaseUrlObject = new URL(apiBaseUrl);
            const url = new URL(`${relativeUrlPath}${qs}`, apiBaseUrlObject).toString();
            if (isAccessTokenNeeded) {
                const accessToken = await getAccessToken();
                init = await withAuthHeaders(init, accessToken);
            }
            return fetch(url, init);
        },
        [apiBaseUrl, isAccessTokenNeeded, getAccessToken]
    );
    return { authorizedFetch };
};

export interface ExtendedRequestInit extends RequestInit {
    query?: StringifiableRecord;
}

export type FetchFunc = (input: string, init?: ExtendedRequestInit) => Promise<Response>;
