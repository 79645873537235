import CheckBoxOutlineBlankFilledIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlineBlankRoundedIcon from "@material-ui/icons/CheckBoxOutlineBlankRounded";
import CheckBoxOutlineBlankSharpIcon from "@material-ui/icons/CheckBoxOutlineBlankSharp";
import CheckBoxOutlineBlankTwoToneIcon from "@material-ui/icons/CheckBoxOutlineBlankTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const checkBoxOutlineBlankIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return CheckBoxOutlineBlankFilledIcon;
        case IconStyle.Outlined:
            return CheckBoxOutlineBlankOutlinedIcon;
        case IconStyle.Rounded:
            return CheckBoxOutlineBlankRoundedIcon;
        case IconStyle.TwoTone:
            return CheckBoxOutlineBlankTwoToneIcon;
        case IconStyle.Sharp:
            return CheckBoxOutlineBlankSharpIcon;
    }
};

export const CheckBoxOutlineBlankIcon = createIconComponent(checkBoxOutlineBlankIconSelector);
