import { SvgIconProps } from "@material-ui/core";
import {
    ChecklistStepAllCompletedIconContainer,
    ChecklistStepCompletedIconContainer,
    ChecklistStepCreatedIconContainer,
    ChecklistStepInProgressIconContainer,
    ChecklistStepWaitingForAssignmentIconContainer,
    ChecklistStepWaitingForPrerequisitesIconContainer,
    ChecklistStepWaitingForScheduleIconContainer,
    ChecklistStepWaitingForStartIconContainer,
} from "./ChecklistStepIcon";
import {
    ChecklistCurrentStepStatus,
    ChecklistNotCurrentStepStatus,
    ChecklistOnlyStatus,
    ChecklistStepStatus,
} from "./Status";

export function getChecklistStepIcon(status: ChecklistStepStatus): React.FC<SvgIconProps> {
    switch (status) {
        default:
        case ChecklistOnlyStatus.ChecklistCreated:
            return ChecklistStepCreatedIconContainer;

        case ChecklistOnlyStatus.ChecklistWaitingForSchedule:
            return ChecklistStepWaitingForScheduleIconContainer;

        case ChecklistOnlyStatus.ChecklistWaitingForStart:
            return ChecklistStepWaitingForStartIconContainer;

        case ChecklistOnlyStatus.ChecklistCompleted:
            return ChecklistStepAllCompletedIconContainer;

        case ChecklistNotCurrentStepStatus.StepWaitingForPrerequisites:
            return ChecklistStepWaitingForPrerequisitesIconContainer;

        case ChecklistCurrentStepStatus.StepWaitingForAssignment:
            return ChecklistStepWaitingForAssignmentIconContainer;

        case ChecklistCurrentStepStatus.StepInProgress:
            return ChecklistStepInProgressIconContainer;

        case ChecklistNotCurrentStepStatus.StepCompleted:
            return ChecklistStepCompletedIconContainer;
    }
}
