import { Typography } from "@material-ui/core";
import TimelineDot from "@material-ui/lab/TimelineDot";
import * as React from "react";
import { ChecklistStepWaitingForPrerequisitesIconContainer } from "../ChecklistStepIcon";
import { BadgeForScheduling } from "./BadgeForScheduling";

export const SchedulingWallOfText: React.FC = () => (
    <>
        <Typography variant="body1" gutterBottom>
            Work cannot begin on this checklist until the schedule is complete. Fields on this page that need to be set
            in order to complete the schedule are noted with a &ldquo;warning&rdquo; icon like this:
        </Typography>
        <div>
            <BadgeForScheduling needsScheduling={true}>
                <TimelineDot>
                    <ChecklistStepWaitingForPrerequisitesIconContainer />
                </TimelineDot>
            </BadgeForScheduling>
        </div>
        <Typography variant="body1" gutterBottom>
            A completely scheduled checklist has a checklist start date, a checklist needed by date, and a needed by for
            every step. After the checklist needed by date is set, click the &ldquo;Auto Schedule Steps&rdquo; button
            above to fill in step needed by dates. Needed by are automatically chosen based on the checklist needed by
            date and a schedule built into the underlying checklist template. If you had set some step needed by dates
            manually and beflore clicking on Auto Schedule, the dates you manually enetered will not change.
        </Typography>
    </>
);
