import FirstPageFilledIcon from "@material-ui/icons/FirstPage";
import FirstPageOutlinedIcon from "@material-ui/icons/FirstPageOutlined";
import FirstPageRoundedIcon from "@material-ui/icons/FirstPageRounded";
import FirstPageSharpIcon from "@material-ui/icons/FirstPageSharp";
import FirstPageTwoToneIcon from "@material-ui/icons/FirstPageTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const firstPageIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return FirstPageFilledIcon;
        case IconStyle.Outlined:
            return FirstPageOutlinedIcon;
        case IconStyle.Rounded:
            return FirstPageRoundedIcon;
        case IconStyle.TwoTone:
            return FirstPageTwoToneIcon;
        case IconStyle.Sharp:
            return FirstPageSharpIcon;
    }
};

export const FirstPageIcon = createIconComponent(firstPageIconSelector);
