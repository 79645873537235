import { useCallback } from "react";
import { QueryObserverResult, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { AppConfig } from "../AppConfig/AppConfig";
import { ErrorMessage } from "../ErrorMessage";
import { ApiResponseError } from "./ApiResponseError";
import { createApiResponseError } from "./createApiResponseError";

const devConfigUrl = "https://localhost:5001/config/spa";

/**
 * Get the app config url from <div id=root data-config-url="http://...">.  This the expected structure in an Azure
 * hosted site.
 */
const getAppConfigUrlFromHtml = (): string => {
    const root = document.getElementById("root");
    if (!root) {
        throw new Error("Could not find *[id='root']");
    }
    const { configUrl } = root.dataset;
    if (!configUrl) {
        throw new Error("Could not load configUrl from *[id='root']");
    }
    return configUrl;
};

/**
 * Get the url used to load app config.  On development environmnets, the url is hard coded.  In Azure hosted
 * environments, it is embedded in index.html.
 */
export const getAppConfigUrl = (): string => {
    const configUrl = getAppConfigUrlFromHtml();
    const magicConfigUrlString = "a30a7270-a1f4-41a5-9eef-dc6d5325ad09";
    return getAppConfigUrlFromHtml() === magicConfigUrlString ? devConfigUrl : configUrl;
};

async function getAppConfig() {
    const response = await fetch(getAppConfigUrl());
    if (response.status >= 200 && response.status < 300) {
        return (await response.json()) as AppConfig;
    } else {
        throw await createApiResponseError(ErrorMessage.FailedToSpaConfig, response);
    }
}

export const AppConfigQueryKey = "config/spa";

export const useAppConfigQuery: (
    queryOptions?: UseQueryOptions<AppConfig>
) => QueryObserverResult<AppConfig, ApiResponseError> = (queryConfig) => {
    return useQuery<AppConfig, ApiResponseError>([AppConfigQueryKey], getAppConfig, queryConfig);
};

export const useFetchAppConfig: () => {
    fetchAppConfig(): Promise<AppConfig>;
} = () => {
    const queryCache = useQueryClient();
    const fetchAppConfig = useCallback(
        () => queryCache.fetchQuery([AppConfigQueryKey], getAppConfig, { staleTime: Infinity }),
        [queryCache]
    );
    return { fetchAppConfig };
};
