import { TextField } from "@material-ui/core";
import useEventListener from "@use-it/event-listener";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { KeyboardEventHandler } from "react";
import * as Yup from "yup";

export interface DataFileSearchByRecordNumberProps {
    /** An event emitted when the user chooses a record number */
    onSubmit(recordNumber: number): void;
    /** An event emitted when the user presses page down */
    onMoveFirst(): void;
    /** An event emitted when the user presses page up */
    onMoveLast(): void;
}

export interface DataFileSearchByRecordNumberValues {
    recordNumber: string;
}

export const DataFileSearchByRecordNumber: React.FC<DataFileSearchByRecordNumberProps> = ({
    onSubmit,
    onMoveFirst,
    onMoveLast,
}) => {
    function handleFormSubmit(
        values: DataFileSearchByRecordNumberValues,
        { setSubmitting }: FormikHelpers<DataFileSearchByRecordNumberValues>
    ) {
        setSubmitting(false);
        onSubmit(parseInt(values.recordNumber));
    }

    /**
     * When a form has just one input and the user presses enter while the input is focused, the form is submitted.
     * I'd like to have the same code here whether there is just one input or there are multiple inputs.  For that
     * reason, let's use this keyhandler and preventDefault.
     */
    const handleTextFieldKeydown: (submitForm: () => Promise<void>) => KeyboardEventHandler<HTMLInputElement> = (
        submitForm
    ) => (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            submitForm();
        }
    };

    const recordNumberErrorMessage = "Record number must be a positive integer";

    const initialValues: DataFileSearchByRecordNumberValues = { recordNumber: "" };

    const handleDocumentKeydown = (event: KeyboardEvent) => {
        if (event.key === "PageDown") {
            event.preventDefault();
            onMoveFirst();
        } else if (event.key === "PageUp") {
            event.preventDefault();
            onMoveLast();
        }
    };
    useEventListener("keydown", handleDocumentKeydown);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={Yup.object({
                recordNumber: Yup.number()
                    .typeError(recordNumberErrorMessage)
                    .integer(recordNumberErrorMessage)
                    .positive(recordNumberErrorMessage)
                    .required("Record number is required"),
            })}
            validateOnBlur={false}
        >
            {({ submitForm }) => (
                <Form>
                    <Field name="recordNumber">
                        {({ field, meta: { error, touched } }: FieldProps): React.ReactNode => (
                            <TextField
                                {...field}
                                label="Record number"
                                error={Boolean(touched && error)}
                                helperText={touched && error}
                                autoFocus={true}
                                onKeyDown={handleTextFieldKeydown(submitForm)}
                            ></TextField>
                        )}
                    </Field>
                </Form>
            )}
        </Formik>
    );
};
