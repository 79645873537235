import { makeStyles } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
    requiredAsterisk: {
        color: theme.palette.error.dark,
    },
}));

export const ChecklistParameterEditorFieldLabel: React.FC<{ label: string; required?: boolean }> = ({
    label,
    required,
}) => {
    const classes = useStyles();
    if (!required) {
        return <>{label}</>;
    } else {
        return (
            <>
                {label} <span className={classes.requiredAsterisk}>*</span>
            </>
        );
    }
};
