import CloudUploadFilledIcon from "@material-ui/icons/CloudUpload";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import CloudUploadRoundedIcon from "@material-ui/icons/CloudUploadRounded";
import CloudUploadSharpIcon from "@material-ui/icons/CloudUploadSharp";
import CloudUploadTwoToneIcon from "@material-ui/icons/CloudUploadTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const cloudUploadIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return CloudUploadFilledIcon;
        case IconStyle.Outlined:
            return CloudUploadOutlinedIcon;
        case IconStyle.Rounded:
            return CloudUploadRoundedIcon;
        case IconStyle.TwoTone:
            return CloudUploadTwoToneIcon;
        case IconStyle.Sharp:
            return CloudUploadSharpIcon;
    }
};

export const CloudUploadIcon = createIconComponent(cloudUploadIconSelector);
