/* eslint-disable @typescript-eslint/no-redeclare */
type MenuCommandKey = "menu";
export const MenuCommandKey = "menu";
interface MenuCommand {
    type: MenuCommandKey;
    library: string;
    menu: string;
}

type ProcedureCommandKey = "procedure";
export const ProcedureCommandKey = "procedure";
interface ProcedureCommand {
    type: ProcedureCommandKey;
    library: string;
    procedure: string;
}

type ScreenCommandKey = "screen";
export const ScreenCommandKey = "screen";
interface ScreenCommand {
    type: ScreenCommandKey;
    library: string;
    screen: string;
    screenBuffer: string;
    stepPath: number[];
}

type EndOfJobCommandKey = "endofjob";
export const EndOfJobCommandKey = "endofjob";
interface EndOfJobCommand {
    type: EndOfJobCommandKey;
}

type PromptInputCommandKey = "promptinput";
export const PromptInputCommandKey = "promptinput";
interface PromptInputCommand {
    type: PromptInputCommandKey;
    stepPath: number[];
}

type PromptOutputCommandKey = "promptoutput";
export const PromptOutputCommandKey = "promptoutput";
interface PromptOutputCommand {
    type: PromptOutputCommandKey;
    message: string;
    stepPath: number[];
}

type DataFileUtilityCommandKey = "dfu";
export const DataFileUtilityCommandKey = "dfu";
interface DataFileUtilityCommand {
    type: DataFileUtilityCommandKey;
    dfuName: string;
    fileName: string;
}

export type Command =
    | MenuCommand
    | ProcedureCommand
    | ScreenCommand
    | EndOfJobCommand
    | PromptInputCommand
    | PromptOutputCommand
    | DataFileUtilityCommand;
