import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useAppConfig } from "../AppConfig";
import { dateTimeReviver } from "../dateTimeReviver";
import { useShowError } from "../ErrorContext";
import { useSetSnack } from "../SnackContext";
import { Guestbook } from "./Guestbook";
import { GuestbookEntry } from "./types";

/**
 * Type that represents the guestbook entries or the status of their load operation.
 *
 * For more advanced implementations, you should consider using a library like react-query.
 */
type GuestbookEntries = "loading" | "error" | GuestbookEntry[];

/**
 * A json reviver function that produces date types for the appropriate fields.
 */
const guestbookJsonReviver = dateTimeReviver(["createDate", "lastModificationDate"]);

/**
 * Behavioral component for the guestbook.
 */
export const GuestbookContainer: React.FC = () => {
    // The base url where our WebApi project is hosted.  For example, this demo uses https://demo-twinoak-com.azurewebsites.net
    const { apiBaseUrl } = useAppConfig();

    // If loaded, the guestbook entries.  Otherwise, the status of the loading process.
    const [guestbookEntries, setGuestbookEntries] = useState<GuestbookEntries>("loading");

    // A hook used to show "toast" style messages in a way that is consistent with the rest of the spa.
    const { setSnack } = useSetSnack();

    // A hook used to show an error in a way that is consistent with the rest of the spa.  `showError` can deal with a
    // variety of inputs, including response objects and ProblemDetail objects returned by our APIs (see RFC 7807 for
    // details).
    const showError = useShowError();

    // Use the web api to refresh our list of guestbook entries.
    const loadGuestbook = useCallback(
        async function () {
            const response = await fetch(`${apiBaseUrl}/guestbook-entries`);
            if (!response.ok) {
                setGuestbookEntries("error");
                return;
            }

            // Here's a simple way to get the payload.
            // const entries: GuestbookEntryModel[] = await response.json();

            // If we need to work with dates, we'll need custom serialization.
            const json = await response.text();
            const entries: GuestbookEntry[] = JSON.parse(json, guestbookJsonReviver);

            setGuestbookEntries(entries);
        },
        [apiBaseUrl]
    );

    // Load the guestbook when this component mounts.
    useEffect(() => {
        loadGuestbook();
    }, [loadGuestbook]);

    // Use the web api to save a new guestbook entry.
    const handleSaveSignature = React.useCallback(
        function (visitorName: string) {
            async function saveSignature() {
                const response = await fetch(`${apiBaseUrl}/guestbook-entries`, {
                    method: "POST",
                    body: JSON.stringify({ visitorName }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.status >= 200 && response.status < 300) {
                    setSnack("Signature saved.");
                    await loadGuestbook();
                } else {
                    showError("Failed to save signature.", response);
                }
            }
            saveSignature();
        },
        [apiBaseUrl, loadGuestbook, setSnack, showError]
    );

    return guestbookEntries === "loading" ? (
        <>Loading...</>
    ) : guestbookEntries === "error" ? (
        <>Error!</>
    ) : (
        <Guestbook entries={guestbookEntries} onSaveSignature={handleSaveSignature} />
    );
};
