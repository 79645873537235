import { useCallback } from "react";
import isEqual from "react-fast-compare";
import { QueryObserverResult, useQuery, UseQueryOptions } from "react-query";
import { useStorageState } from "react-storage-hooks";
import { useRecoilState } from "recoil";
import { defaultMenuTree, MenuTree, menuTreeState } from "../atoms";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { MenuContent, MenuDefinition } from "../MenuContext";
import { sessionStorageKeys } from "../sessionStorageKeys";
import { useSpinnerEffect } from "../SpinnerContext";
import { useAuthorizedFetch } from "./AuthorizedFetch";

export function convertMenuTreeToList(menuTree: MenuTree): MenuDefinition[] {
    return Object.keys(menuTree).flatMap((library) =>
        Object.keys(menuTree[library]).map((name) => {
            const { title, items, isEnabled, isEmphasized } = menuTree[library][name];
            return { library, name, title, items, isEnabled, isEmphasized };
        })
    );
}

function convertMenusToMenuTree(menus: MenuDefinition[]): MenuTree {
    return menus.reduce((acc, value) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { library, name, ...menuContent } = value;
        return { ...acc, [value.library]: { ...acc[value.library], [value.name]: menuContent } };
    }, {} as MenuTree);
}

export function useMenuTreeQuery(
    queryOptions?: UseQueryOptions<MenuTree>
): QueryObserverResult<Record<string, Record<string, MenuContent>>> {
    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();
    const apiUrl = "menus";

    const getMenuTree = useCallback(async () => {
        const response = await authorizedFetch(apiUrl);
        if (response.status >= 200 && response.status < 300) {
            const menus = (await response.json()) as MenuDefinition[];
            return convertMenusToMenuTree(menus);
        } else {
            showError(ErrorMessage.FailedToLoadChecklistTemplates, response);
            throw new Error(ErrorMessage.FailedToLoadChecklistTemplates);
        }
    }, [authorizedFetch, showError]);

    const query = useQuery(["menu-tree"], getMenuTree, queryOptions);

    useSpinnerEffect(query.isLoading, apiUrl);

    return query;
}

export function useMenuTree(): MenuTree {
    const [cache, setCache] = useStorageState<MenuTree>(sessionStorage, sessionStorageKeys.menuTree, defaultMenuTree);
    const [menuTree, setMenuTree] = useRecoilState(menuTreeState);
    const onSuccess = useCallback(
        (data: MenuTree) => {
            if (!isEqual(data, cache)) {
                setCache(data);
            }
            if (!isEqual(data, menuTree)) {
                setMenuTree(data);
            }
        },
        [cache, setCache, menuTree, setMenuTree]
    );
    const { data } = useMenuTreeQuery({ staleTime: Infinity, refetchInterval: 5 * 60 * 1000, onSuccess });

    return data || cache;
}
