import { User, UserManager, UserManagerEvents, UserManagerSettings } from "oidc-client";
import * as React from "react";
import { useEffect, useState } from "react";
import { useAppConfig } from "../../AppConfig";
import { createLogger } from "../../log";
import { Auth } from "../Auth";

const log = createLogger("OIDC");

const signInPathname = "/signin-oidc";
const signOutPathname = "/signout-callback-oidc";

export const OidcAuthProviderDecorator: React.FC<{ provider: React.Provider<Auth> }> = ({
    provider: Provider,
    children,
}) => {
    const appConfig = useAppConfig();

    const [userManager] = useState(() => {
        const url = window.location.origin;

        const settings: UserManagerSettings = {
            authority: appConfig.authority,
            client_id: appConfig.clientId,
            redirect_uri: url + signInPathname,
            post_logout_redirect_uri: url + signOutPathname,
            response_type: "code",
            scope: [
                ...appConfig.scopes,
                ...(appConfig.scopes.includes("openid") ? [] : ["openid"]),
                ...(appConfig.scopes.includes("profile") ? [] : ["profile"]),
            ].join(" "),

            popup_redirect_uri: url,
            popup_post_logout_redirect_uri: url,

            silent_redirect_uri: url,
            automaticSilentRenew: false,
            validateSubOnSilentRenew: true,

            filterProtocolClaims: true,
            loadUserInfo: true,
            revokeAccessTokenOnSignout: true,
        };

        log("creating a new user manager", settings);

        return new UserManager(settings);
    });

    const [user, setUser] = useState<User>();

    const handleUserLoaded: UserManagerEvents.UserLoadedCallback = (user: User) => {
        log("UserLoaded event", user);
        setUser(user);
    };
    const handleUserUnloaded: UserManagerEvents.UserUnloadedCallback = () => {
        log("UserUnloaded");
    };
    const handleSilentRenewError: UserManagerEvents.SilentRenewErrorCallback = (error: Error) => {
        log("SilentRenewError", error);
    };
    const handleUserSignedOut: UserManagerEvents.UserSignedOutCallback = () => {
        log("UserSignedOut");
    };
    const handleUserSessionChanged: UserManagerEvents.UserSessionChangedCallback = () => {
        log("UserSessionChanged");
    };

    useEffect(
        function registeringEventHandlersEffect() {
            log("registering event handlers", userManager);

            userManager.events.addUserLoaded(handleUserLoaded);
            userManager.events.addUserUnloaded(handleUserUnloaded);
            userManager.events.addSilentRenewError(handleSilentRenewError);
            userManager.events.addUserSignedOut(handleUserSignedOut);
            userManager.events.addUserSessionChanged(handleUserSessionChanged);

            const loadUser: () => Promise<void> = async () => {
                const user = await userManager.getUser();
                log("loadUser", user);
                if (user) {
                    setUser(user);
                }
            };

            loadUser();

            return () => {
                log("deregistering event handlers", userManager);

                userManager.events.removeUserLoaded(handleUserLoaded);
                userManager.events.removeUserUnloaded(handleUserUnloaded);
                userManager.events.removeSilentRenewError(handleSilentRenewError);
                userManager.events.removeUserSignedOut(handleUserSignedOut);
                userManager.events.removeUserSessionChanged(handleUserSessionChanged);
            };
        },
        [userManager]
    );

    const { pathname } = window.location;

    useEffect(
        function signinRedirectEffect() {
            if (userManager && pathname === signInPathname) {
                userManager.signinRedirectCallback();
            }
        },
        [userManager, pathname]
    );

    const userExpired = user?.expired;
    const oidcAuth = React.useMemo<Auth>(() => {
        const result = {
            isUserLoggedIn: userExpired === false,
            login: (): void => {
                userManager.signinRedirect();
            },
            logout: (): void => {
                userManager.signoutRedirect();
            },
            getUserRoles: (): Promise<string[]> => Promise.resolve([]),
            isAccessTokenNeeded: true,
            getAccessToken: async (): Promise<string> => {
                const user = await userManager.getUser();
                if (!user) {
                    return "";
                }
                const { access_token } = user;
                return access_token;
            },
            account: { name: "tbd", userName: "tbd" },
        };
        log("oidcAuth memo, should refresh rarely", result);
        return result;
    }, [userManager, userExpired]);

    log("isUserLoggedIn", user?.expired === false);

    return <Provider value={oidcAuth}>{children}</Provider>;
};
