import { createStyles, makeStyles } from "@material-ui/core";
import * as React from "react";
import RichTextEditor from "react-rte";
import {
    BooleanParameterTypeKey,
    DateListParameterTypeKey,
    DateParameterTypeKey,
    HtmlParameterTypeKey,
    NumberParameterTypeKey,
    StringParameterTypeKey,
    TextAreaParameterTypeKey,
    WebApiChecklistTemplateParameterValue,
} from "../../Api";
import { formatDate } from "../../formatDate";
import { useRteStyles } from "../../useRteStyles";

const useStyles = makeStyles(() =>
    createStyles({
        textArea: {
            whiteSpace: "pre",
        },
    })
);

export const ParameterFieldValue: React.FC<WebApiChecklistTemplateParameterValue> = (props) => {
    const classes = useStyles();
    const rteClasses = useRteStyles();

    if (props.value === undefined) {
        return null;
    }

    switch (props.type) {
        case StringParameterTypeKey:
            return <>{props.value}</>;
        case NumberParameterTypeKey:
            return <>{props.value}</>;
        case DateParameterTypeKey:
            return <>{formatDate(props.value)}</>;
        case BooleanParameterTypeKey:
            return <>{props.value?.toString()}</>;
        case DateListParameterTypeKey:
            return <>{props.value.map((date) => formatDate(date)).join(", ")}</>;
        case TextAreaParameterTypeKey:
            return <span className={classes.textArea}>{props.value}</span>;
        case HtmlParameterTypeKey:
            return (
                <RichTextEditor
                    value={RichTextEditor.createValueFromString(props.value || "", "html")}
                    readOnly
                    className={rteClasses.readOnlyRteUncontained}
                />
            );
    }
};
