import * as React from "react";
import { SecurityScheme, useAppConfig } from "../AppConfig";
import { Auth } from "./Auth";
import { MsalAuthProviderDecorator, MsalAuthProviderProvider } from "./AzureAd";
import { OidcAuthProviderDecorator } from "./Oidc";
import { unsecuredAuth } from "./Unsecured";

const context = React.createContext<Auth>(unsecuredAuth);
const { Provider } = context;

export const AuthProvider: React.FC = ({ children }) => {
    const appConfig = useAppConfig();
    if (appConfig.securityScheme === SecurityScheme.AzureAD) {
        return (
            <MsalAuthProviderProvider>
                <MsalAuthProviderDecorator provider={Provider}>{children}</MsalAuthProviderDecorator>
            </MsalAuthProviderProvider>
        );
    }
    if (appConfig.securityScheme === SecurityScheme.Oidc) {
        return <OidcAuthProviderDecorator provider={Provider}>{children}</OidcAuthProviderDecorator>;
    }
    return <Provider value={unsecuredAuth}>{children}</Provider>;
};

export const useAuth: () => Auth = () => React.useContext(context);
