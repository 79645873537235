import DeleteForeverFilledIcon from "@material-ui/icons/DeleteForever";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import DeleteForeverSharpIcon from "@material-ui/icons/DeleteForeverSharp";
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const deleteForeverIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return DeleteForeverFilledIcon;
        case IconStyle.Outlined:
            return DeleteForeverOutlinedIcon;
        case IconStyle.Rounded:
            return DeleteForeverRoundedIcon;
        case IconStyle.TwoTone:
            return DeleteForeverTwoToneIcon;
        case IconStyle.Sharp:
            return DeleteForeverSharpIcon;
    }
};

export const DeleteForeverIcon = createIconComponent(deleteForeverIconSelector);
