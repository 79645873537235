import { Auth } from "../Auth";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop: () => void = () => {};

export const unsecuredAuth: Auth = {
    isUserLoggedIn: true,
    login: noop,
    logout: noop,
    getUserRoles: () => Promise.resolve([]),
    isAccessTokenNeeded: false,
    getAccessToken: () => Promise.reject(),
};
