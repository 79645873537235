import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
    useAttachFileToChecklistStepMutation,
    useAutoScheduleChecklistMutation,
    useCompleteChecklistStepMutation,
    useUserSettings,
} from "../../Api";
import { formatDateTime } from "../../formatDate";
import { CloudUploadIcon, DoneIcon, DynamicFeedIcon, ZoomInIcon, ZoomOutIcon } from "../../Icons";
import { ChecklistProps, isPseudoStepData, PseudoStepData, StepData } from "../ChecklistTypes";
import { convertStepDataToDisplayProps } from "../convertStepDataToDisplayProps";
import { UserDisplayName } from "../UserDisplayName";
import { AssignStepButton } from "./AssignStepButton";
import { ChecklistHeader } from "./ChecklistHeader";
import { ChecklistStepContent } from "./ChecklistStepContent";
import { ChecklistStepTimelineItem } from "./ChecklistStepTimelineItem";
import {
    PseudoChecklistCompletedStepId,
    PseudoChecklistCreateStepId,
    PseudoChecklistScheduleStepId,
    PseudoChecklistStartStepId,
} from "./PseudoChecklistStepIds";
import { SchedulingWallOfText } from "./SchedulingWallOfText";

export const useStyles = makeStyles((theme) => ({
    checklistButtonBar: {
        display: "flex",
        flexGrow: 1,
        "& > *": {
            marginRight: theme.spacing(2),
        },
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    hiddenFileInput: { display: "none" },
}));

const StepActionsContentFC: React.FC<
    {
        onChecklistStepCompleted(checklistStepId: string): void;
    } & StepData
> = (props) => {
    const {
        checklistId,
        checklistStepId,
        name: checklistStepName,
        canAssign,
        canComplete,
        canAttachItem,
        onChecklistStepCompleted,
    } = props;

    const completeMutation = useCompleteChecklistStepMutation();
    const attachMutation = useAttachFileToChecklistStepMutation();
    // const { onChecklistStepCompleted } = useChecklistContext();
    const classes = useStyles();

    const handleFileChange = async (files: FileList | null) => {
        console.log(files);

        if (!files) {
            return;
        }
        await attachMutation.mutateAsync({ checklistId, checklistStepId, file: files[0] });
    };

    const labelId = `attach-file-button-${checklistStepId}`;
    return (
        <>
            <input
                className={classes.hiddenFileInput}
                id={labelId}
                type="file"
                onChange={(event) => handleFileChange(event.target.files)}
            />
            <label htmlFor={labelId}>
                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    component="span"
                    disabled={!canAttachItem}
                >
                    Attach file
                </Button>
            </label>
            <AssignStepButton {...{ canAssign, checklistId, checklistStepId }} />
            <Button
                color="primary"
                variant="contained"
                startIcon={<DoneIcon />}
                onClick={() =>
                    completeMutation.mutateAsync(
                        {
                            checklistId,
                            checklistStepId,
                            checklistStepName,
                        },
                        {
                            onSuccess: () => {
                                onChecklistStepCompleted(checklistStepId);
                            },
                        }
                    )
                }
                disabled={!canComplete}
            >
                Complete step
            </Button>
        </>
    );
};

const StepActionsContent = React.memo(StepActionsContentFC);

enum BulkToggleMode {
    OnlyCurrentStepIsExpanded = "onlyCurrentStepIsExpanded",
    AllAreExpanded = "allAreExpanded",
    AllAreCollapsed = "allAreCollapsed",
}

const getNextBulkToggleState = (currentState?: BulkToggleMode) => {
    switch (currentState) {
        case undefined:
        case BulkToggleMode.AllAreCollapsed:
            return BulkToggleMode.OnlyCurrentStepIsExpanded;
        case BulkToggleMode.OnlyCurrentStepIsExpanded:
            return BulkToggleMode.AllAreExpanded;
        case BulkToggleMode.AllAreExpanded:
            return BulkToggleMode.AllAreCollapsed;
    }
};

/** Get the subtitle content for regular steps.  All the pseudo steps provide their own custom subtitle. */
function getSubtitleForRegularStep({
    dueDate,
    completedDate,
    assignedToUserId,
    isChecklistScheduled,
}: Pick<StepData, "dueDate" | "completedDate" | "assignedToUserId"> & {
    isChecklistScheduled: boolean;
}): JSX.Element | null {
    if (isChecklistScheduled) {
        return !dueDate ? (
            <>needs needed by date</>
        ) : !completedDate && !assignedToUserId ? (
            <>needed by {formatDateTime(dueDate)}</>
        ) : !completedDate && assignedToUserId ? (
            <>
                assigned to <UserDisplayName userId={assignedToUserId} />
            </>
        ) : completedDate && assignedToUserId ? (
            <>
                completed by <UserDisplayName userId={assignedToUserId} /> on {formatDateTime(completedDate)}
            </>
        ) : null;
    } else {
        return !dueDate ? <>unscheduled</> : <>needed by {formatDateTime(dueDate)}</>;
    }
}

export const Checklist: React.FC<ChecklistProps> = (props) => {
    const autoScheduleChecklistMutation = useAutoScheduleChecklistMutation();
    const classes = useStyles();
    const {
        checklistId,
        createDate,
        completedDate,
        scheduledByUserId,
        isScheduled,
        isStarted,
        startDate,
        dueDate,
        steps: originalSteps,
        parameters,
        currentStepId,
    } = props;
    const isChecklistScheduled = isScheduled;

    const userSettings = useUserSettings();
    const enableThreeStateBulkToggleOnChecklistDetail = useMemo(
        () => (isStarted ? userSettings.enableThreeStateBulkToggleOnChecklistDetail : false),
        [isStarted, userSettings.enableThreeStateBulkToggleOnChecklistDetail]
    );

    const createStep: PseudoStepData = useMemo(
        () => ({
            checklistStepId: PseudoChecklistCreateStepId,
            name: "Created",
            completedDate: createDate,
        }),
        [createDate]
    );

    const stepIds = useMemo(
        () => [
            PseudoChecklistCreateStepId,
            PseudoChecklistScheduleStepId,
            PseudoChecklistStartStepId,
            ...originalSteps.map((s) => s.checklistStepId),
            PseudoChecklistCompletedStepId,
        ],
        [originalSteps]
    );
    const [expanded, setExpanded] = useState(stepIds.map((stepId) => stepId === currentStepId));

    // We don't know when the scheduling happened, so let's use the checklist creation date.
    const scheduleDate = createDate;
    const scheduleStep: PseudoStepData = useMemo(
        () => ({
            checklistStepId: PseudoChecklistScheduleStepId,
            name: "Schedule pending",
            completedName: "Scheduled",
            completedDate: isScheduled ? scheduleDate : undefined,
            subtitle:
                isScheduled && scheduledByUserId ? (
                    <>
                        by <UserDisplayName userId={scheduledByUserId} />
                    </>
                ) : undefined,
            expandedContent: <SchedulingWallOfText />,
        }),
        [isScheduled, scheduleDate, scheduledByUserId]
    );

    const startStep: PseudoStepData = useMemo(
        () => ({
            checklistStepId: PseudoChecklistStartStepId,
            name: "Start pending",
            completedName: "Started",
            completedDate: isStarted ? startDate : undefined,
            subtitle: startDate ? `on ${formatDateTime(startDate)}` : undefined,
        }),
        [isStarted, startDate]
    );

    const completedStep: PseudoStepData = useMemo(
        () => ({
            checklistStepId: PseudoChecklistCompletedStepId,
            name: "Checklist incomplete",
            completedName: "Checklist complete",
            completedDate: completedDate,
            subtitle:
                dueDate !== undefined && completedDate === undefined ? `due on ${formatDateTime(dueDate)}` : undefined,
        }),
        [completedDate, dueDate]
    );

    const steps: (PseudoStepData | StepData)[] = useMemo(
        () => [createStep, scheduleStep, startStep, ...originalSteps, completedStep],
        [completedStep, createStep, originalSteps, scheduleStep, startStep]
    );

    const allAreCollapsed = useMemo(() => !steps.some((c, i) => expanded[i]), [steps, expanded]);

    const allAreExpanded = useMemo(() => !steps.some((c, i) => !expanded[i]), [steps, expanded]);

    const toggleAllResult = useMemo(() => !allAreExpanded, [allAreExpanded]);

    const currentState: BulkToggleMode | undefined = useMemo(() => {
        const onlyCurrentStepIsExpanded = !steps.some(
            (step, index) => (step.checklistStepId === currentStepId) !== expanded[index]
        );
        return onlyCurrentStepIsExpanded
            ? BulkToggleMode.OnlyCurrentStepIsExpanded
            : allAreExpanded
            ? BulkToggleMode.AllAreExpanded
            : allAreCollapsed
            ? BulkToggleMode.AllAreCollapsed
            : undefined;
    }, [steps, allAreCollapsed, allAreExpanded, currentStepId, expanded]);

    const nextBuldToggleState = useMemo(() => getNextBulkToggleState(currentState), [currentState]);
    const fancyToggleLabel = useMemo(
        () =>
            nextBuldToggleState === BulkToggleMode.OnlyCurrentStepIsExpanded
                ? "Only expand current step"
                : nextBuldToggleState === BulkToggleMode.AllAreExpanded
                ? "Expand all steps"
                : "Collapse all steps",
        [nextBuldToggleState]
    );
    const bulkToggle = useCallback(() => {
        switch (nextBuldToggleState) {
            case BulkToggleMode.AllAreCollapsed:
                setExpanded((prevState) => prevState.map(() => false));
                break;
            case BulkToggleMode.OnlyCurrentStepIsExpanded:
                setExpanded((prevState) => prevState.map((_, i) => steps[i].checklistStepId === currentStepId));
                break;
            case BulkToggleMode.AllAreExpanded:
                setExpanded((prevState) => prevState.map(() => true));
                break;
        }
    }, [setExpanded, currentStepId, nextBuldToggleState, steps]);

    // Whenever the current step changes, ensure the current step is expanded.
    useEffect(
        function currentStepChangedEffect() {
            setExpanded((prevExpanded) => {
                const newExpanded = stepIds.map((stepId, i) => (currentStepId === stepId ? true : prevExpanded[i]));
                const changed = JSON.stringify(newExpanded) !== JSON.stringify(prevExpanded);
                return changed ? newExpanded : prevExpanded;
            });
        },
        [currentStepId, setExpanded, stepIds]
    );

    const handleChecklistStepCompleted = useCallback(
        (completedChecklistStepId: string) => {
            setExpanded((prevExpanded) => {
                const newExpanded = stepIds.map((stepId, index) =>
                    stepId === completedChecklistStepId ? false : prevExpanded[index]
                );
                const changed = JSON.stringify(newExpanded) !== JSON.stringify(prevExpanded);
                return changed ? newExpanded : prevExpanded;
            });
        },
        [setExpanded, stepIds]
    );

    const renderedSteps = useMemo(
        () =>
            steps.map((step, index) => {
                const scrollIntoView = step.checklistStepId === currentStepId;
                const extraProps = isPseudoStepData(step)
                    ? step.expandedContent
                        ? {
                              expanded: expanded[index],
                              setExpanded: (newValue: boolean) =>
                                  setExpanded((prevState) =>
                                      prevState.map((expanded, i) => (i === index ? newValue : expanded))
                                  ),
                              expandedContent: step.expandedContent,
                          }
                        : undefined
                    : {
                          expanded: expanded[index],
                          setExpanded: (newValue: boolean) =>
                              setExpanded((prevState) =>
                                  prevState.map((expanded, i) => (i === index ? newValue : expanded))
                              ),
                          expandedContent: <ChecklistStepContent {...{ ...step }} />,
                          actionsContent: (
                              <StepActionsContent {...step} onChecklistStepCompleted={handleChecklistStepCompleted} />
                          ),
                          subtitle: getSubtitleForRegularStep({ ...step, isChecklistScheduled }),
                      };
                return (
                    <ChecklistStepTimelineItem
                        key={step.checklistStepId}
                        {...convertStepDataToDisplayProps({
                            checklistDueDate: dueDate,
                            isChecklistScheduled,
                            currentStepId,
                            step,
                        })}
                        {...extraProps}
                        noConnector={index === steps.length - 1}
                        scrollIntoView={scrollIntoView}
                    />
                );
            }),
        [currentStepId, dueDate, expanded, handleChecklistStepCompleted, isChecklistScheduled, steps]
    );

    return (
        <>
            <ChecklistHeader {...{ parameters, isScheduled, startDate, checklistId, dueDate }} />
            <Box className={classes.checklistButtonBar}>
                {enableThreeStateBulkToggleOnChecklistDetail ? (
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={toggleAllResult ? <ZoomInIcon /> : <ZoomOutIcon />}
                        onClick={bulkToggle}
                    >
                        {fancyToggleLabel}
                    </Button>
                ) : (
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={toggleAllResult ? <ZoomInIcon /> : <ZoomOutIcon />}
                        onClick={() => setExpanded((prevState) => prevState.map((_) => toggleAllResult))}
                    >
                        {toggleAllResult ? "Expand all steps" : "Collapse all steps"}
                    </Button>
                )}
                {!isScheduled && (
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<DynamicFeedIcon />}
                        onClick={() => autoScheduleChecklistMutation.mutateAsync({ checklistId })}
                        disabled={dueDate === undefined && !isScheduled}
                    >
                        Auto Schedule Steps
                    </Button>
                )}
            </Box>
            <Timeline>{renderedSteps}</Timeline>
        </>
    );
};
