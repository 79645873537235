type LogFunction = (...args: unknown[]) => void;

interface LoggerFactory {
    (name: unknown, basis?: LogFunction): LogFunction;
}

/**
 * Create a function like `console.log` that adds context to the output.
 * @param messagePrefix Data to be included in all my log messages.
 */
export const createLogger: LoggerFactory = (messagePrefix, basis) =>
    (basis || console.log).bind(undefined, `[${messagePrefix}]`);

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noopLogger: (...args: unknown[]) => void = () => {};
