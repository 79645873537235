import { Box, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { forwardRef, ReactNode } from "react";

export const useStyles = makeStyles(() => ({
    stepTitleContainer: { display: "flex", alignItems: "baseline" },
    stepTitleContainerTabular: {
        display: "flex",
        flexGrow: 1,
        alignItems: "baseline",
        justifyContent: "space-between",
    },
}));

export interface StepTitleProps {
    title: ReactNode;
    subtitle?: ReactNode;
    body?: ReactNode;
    useTabularLayout: boolean;
}

/** Display step title in a consistent way. */
export const ChecklistStepTitle = forwardRef<HTMLElement, StepTitleProps>(
    ({ title, subtitle, body, useTabularLayout }, ref) => {
        const classes = useStyles();
        return (
            <>
                <Box className={useTabularLayout ? classes.stepTitleContainerTabular : classes.stepTitleContainer}>
                    <Typography variant="h6" display="inline" component="span" ref={ref}>
                        {title}
                    </Typography>
                    {subtitle && (
                        <Typography variant="body1" display="inline" component="span">
                            {!useTabularLayout && <span>&nbsp;&mdash; </span>}
                            {subtitle}
                        </Typography>
                    )}
                </Box>
                {body !== undefined && <div>{body}</div>}
            </>
        );
    }
);

ChecklistStepTitle.displayName = "StepTitle";
