import queryString from "query-string";
import * as React from "react";
import { useParams } from "react-router-dom";
import { createLogger } from "../log";
import { DataFileContainer } from "./DataFileContainer";
import { DataFileListContainer } from "./DataFileListContainer";
import { DataFileMode, RecordAbsolutePosition, RecordDirection } from "./DataFileTypes";
import {
    urlPathSegmentAddRecord,
    urlPathSegmentSearchByExample,
    urlPathSegmentSearchByRecordNumber,
} from "./urlHelpers";

export const log = createLogger("DataFilePage");

const determineModeFromUrl: (params: { modeFromUrl: string; search: string }) => DataFileMode = ({
    modeFromUrl,
    search,
}) => {
    log("[determineModeFromUrl]", { modeFromUrl, search });

    if (modeFromUrl === urlPathSegmentSearchByExample && search.length === 0) {
        return DataFileMode.SearchByExample;
    }
    if (modeFromUrl === urlPathSegmentSearchByRecordNumber) {
        return DataFileMode.SearchByRecordNumber;
    }
    if (modeFromUrl === urlPathSegmentAddRecord) {
        return DataFileMode.Add;
    }
    return DataFileMode.Edit;
};

interface DataFilePageRouteParams {
    dfuName: string;
    fileName: string;
    mode: string;
}

export const DataFilePage: React.FC = () => {
    const { dfuName, fileName, mode: modeFromUrl } = useParams<DataFilePageRouteParams>();
    const query = queryString.parse(window.location.search);
    if (!dfuName) {
        return <DataFileListContainer />;
    } else {
        const recordNumber = typeof query.recordNumber === "string" ? parseInt(query.recordNumber) : undefined;
        const recordId = query.recordId?.toString() || undefined;
        const recordDirection = query.recordDirection as RecordDirection | undefined;
        const recordAbsolutePosition = query.recordAbsolutePosition as RecordAbsolutePosition | undefined;
        const mode = determineModeFromUrl({ modeFromUrl, search: window.location.search });

        return (
            <DataFileContainer
                {...{ dfuName, fileName, mode, recordNumber, recordId, recordDirection, recordAbsolutePosition }}
            />
        );
    }
};
