/**
 * It's likely we will neglect to parse some json object "date" properties as dates, so this typeguard will help make
 * functions that use dates, like formatDate, to be more resilient to bad input.
 * @param date An object that might be a date.
 */
export function isDateObject(date: unknown): date is Date {
    return Object.prototype.toString.call(date) === "[object Date]";
}

/**
 * Format a date object as a date, with no time.  If the date provided is not really a date object, just return the
 * original obejct.
 */
export const formatDate: (date: Date) => string = (date) => (isDateObject(date) ? date.toLocaleDateString() : date);

/**
 * Format a date object as a time, with no date.  If the date provided is not really a date object, just return the
 * original obejct.
 */
export const formatTime: (date: Date) => string = (date) => (isDateObject(date) ? time(date) : date);

const time: (date: Date) => string = (date) => {
    const rawHours = date.getHours();
    const rawMinutes = date.getMinutes();
    const ampm = rawHours >= 12 ? "pm" : "am";
    const rawHoursMod12 = rawHours % 12;
    const hours = rawHoursMod12 === 0 ? 12 : rawHoursMod12;
    const minutes = rawMinutes.toString().padStart(2, "0");
    return hours + ":" + minutes + " " + ampm;
};

/** Returns a formatted date and time.  e.g. "10/7/2020 at 12:55 pm" */
export const formatDateTime: (date: Date) => string = (date) => `${formatDate(date)} at ${formatTime(date)}`;
