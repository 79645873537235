import * as React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import { App, AppContextProviders } from "./App";
import "./wdyr";
import { setupWdyr } from "./wdyr";

(async function bootstrapReact() {
    const root = document.getElementById("root");

    await setupWdyr();

    ReactDOM.render(
        <RecoilRoot>
            <AppContextProviders>
                <App />
            </AppContextProviders>
        </RecoilRoot>,
        root
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA

    // Comment this out so we can use service workers in storybook.
    // serviceWorker.unregister();
})();
