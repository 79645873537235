import queryString from "query-string";
import * as React from "react";
import { QueryKey, useMutation, useQueryClient } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSetSnack } from "../SnackContext";
import { useAuthorizedFetch } from "./AuthorizedFetch";
import { parseJsonWebApiChecklist } from "./parseJsonWebApiChecklist";

interface Parameters {
    checklistTemplateId: string;
    checklistTemplateName?: string;
    parameters: Record<string, unknown>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useCreateChecklistMutation() {
    const queryCache = useQueryClient();

    const mutation = useMutation(createChecklist, {
        onSuccess: (data) => {
            if (!data) {
                return;
            }
            const queryKey: QueryKey = ["checklist", data.id];
            queryCache.setQueryData(queryKey, data);
        },
    });

    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();
    const { setSnack } = useSetSnack();

    async function createChecklist({ checklistTemplateId, checklistTemplateName, parameters }: Parameters) {
        const qs = queryString.stringify({ templateId: checklistTemplateId });
        const apiUrl = `checklists?${qs}`;
        const response = await authorizedFetch(apiUrl, {
            method: "POST",
            body: JSON.stringify(parameters),
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.status >= 200 && response.status < 300) {
            setSnack(
                checklistTemplateName ? (
                    <>Started checklist: &ldquo;{checklistTemplateName}&rdquo;.</>
                ) : (
                    `Started checklist.`
                )
            );
            const json = await response.text();
            try {
                return parseJsonWebApiChecklist(json);
            } catch (ex) {
                showError(ErrorMessage.FailedToParseChecklist, response);
                throw ex;
            }
        } else {
            showError(ErrorMessage.FailedToStartChecklist, response);
        }
    }

    return mutation;
}
