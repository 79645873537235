import { Box, Button, Link } from "@material-ui/core";
import * as React from "react";
import { useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useHubPages } from "../../Api";
import { useShowError } from "../../ErrorContext";
import { AddIcon } from "../../Icons";
import { usePageTitleContext } from "../../PageTitleContext";
import { urlToCreateChecklist } from "../checklistRouting";
import { ChecklistSummaryIconLegend } from "./ChecklistSummaryIconLegend";
import { HubGrid } from "./HubGrid";
import { InboxContainer } from "./InboxContainer";

export const hubPageRoutePath = "/hubs/:hubPageId";

export const urlToHubPage: (hubPageId: string) => string = (hubPageId) => `/hubs/${hubPageId}`;

export const HubPage: React.FC = () => {
    const { hubPageId } = useParams<{ hubPageId: string }>();
    const pages = useHubPages();
    const showError = useShowError();
    const { setPageTitle } = usePageTitleContext();
    const page = pages.find((p) => p.spaUrlParam === hubPageId);
    const pageTitle = page?.pageTitle;

    useEffect(() => {
        setPageTitle(pageTitle ? `Hub - ${pageTitle}` : "Hub");
    }, [setPageTitle, pageTitle]);

    if (!pages) {
        return null;
    }

    if (!page) {
        showError(`Could not find hub page ${hubPageId}.`);
        return null;
    }

    return (
        <>
            <Box mb={3}>
                <Link component={RouterLink} to={urlToCreateChecklist()}>
                    <Button variant="contained" color="primary" startIcon={<AddIcon />}>
                        Start a new checklist
                    </Button>
                </Link>
            </Box>
            <HubGrid>
                {page.inboxes.map((inbox, index) => (
                    <InboxContainer key={index} colorIndex={index} {...inbox} />
                ))}
            </HubGrid>
            <ChecklistSummaryIconLegend />
        </>
    );
};
