import ErrorFilledIcon from "@material-ui/icons/Error";
import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutlined";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import ErrorSharpIcon from "@material-ui/icons/ErrorSharp";
import ErrorTwoToneIcon from "@material-ui/icons/ErrorTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const errorIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return ErrorFilledIcon;
        case IconStyle.Outlined:
            return ErrorOutlinedIcon;
        case IconStyle.Rounded:
            return ErrorRoundedIcon;
        case IconStyle.TwoTone:
            return ErrorTwoToneIcon;
        case IconStyle.Sharp:
            return ErrorSharpIcon;
    }
};

export const ErrorIcon = createIconComponent(errorIconSelector);
