import { createStyles, fade, InputBase, makeStyles, Theme } from "@material-ui/core";
import { Autocomplete, AutocompleteChangeReason } from "@material-ui/lab";
import * as H from "history";
import debounce from "lodash.debounce";
import queryString from "query-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useChecklistSummariesImperative, useChecklistTemplates } from "./Api";
import { convertMenuTreeToList, useMenuTree } from "./Api/useMenuTree";
import { urlToChecklist } from "./Checklist/checklistRouting";
import { Command, MenuCommandKey, ProcedureCommandKey } from "./Command";
import { useShowError } from "./ErrorContext";
import { SearchIcon } from "./Icons";
import { menuPageHref, procedurePageHref } from "./Menu/convertMenuCommandToHref";
import { MenuDefinition, MenuItem } from "./MenuContext";

export enum SearchResultType {
    NoMatch = "NoMatch",
    Checklist = "Checklist",
    Menu = "Menu",
    MenuItem = "MenuItem",
}

export interface ChecklistSearchResult {
    label: string;
    type: SearchResultType.Checklist;
    checklistId: string;
}

export interface MenuSearchResult {
    label: string;
    type: SearchResultType.Menu;
    menu: MenuDefinition;
}

export interface MenuItemSearchResult {
    label: string;
    type: SearchResultType.MenuItem;
    menu: MenuDefinition;
    menuItem: MenuItem;
}

export interface NoMatchSearchResult {
    label: string;
    type: SearchResultType.NoMatch;
}

export type SearchResult = MenuSearchResult | MenuItemSearchResult | NoMatchSearchResult | ChecklistSearchResult;

const searchResultTypeOrder: Record<SearchResultType, number> = {
    NoMatch: 0,
    Checklist: 1,
    Menu: 2,
    MenuItem: 3,
};

export const compareSearchResult: (a: SearchResult, b: SearchResult) => number = (a, b) => {
    if (a.type !== b.type) {
        return searchResultTypeOrder[a.type] - searchResultTypeOrder[b.type];
    }
    return a.label.localeCompare(b.label);
};

const convertMenuToSearchResult: (menu: MenuDefinition) => MenuSearchResult = (menu) => {
    return { label: menu.title, type: SearchResultType.Menu, menu };
};

const convertMenuItemToSearchResult: (options: {
    menuItem: MenuItem;
    menu: MenuDefinition;
}) => MenuItemSearchResult = ({ menu, menuItem }) => {
    return { label: menuItem.text, type: SearchResultType.MenuItem, menu, menuItem };
};

// TODO: Add dfu support
const invokeCommand: (props: { command: Command; history: H.History }) => void = ({ command, history }) => {
    if (command.type === MenuCommandKey) {
        const { library, menu } = command;
        const href = menuPageHref({ library, menu });
        href && history.push(href);
    } else if (command.type === ProcedureCommandKey) {
        const { library, procedure } = command;
        const href = procedurePageHref({ library, procedure });
        href && history.push(href);
    } else {
        console.error("invokeCommand - dont know how to invoke", command);
    }
};

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            position: "relative",
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            "&:hover": {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(3),
                width: "auto",
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        inputRoot: {
            color: "inherit",
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create("width"),
            width: "100%",
            [theme.breakpoints.up("md")]: {
                width: "20ch",
            },
        },
    })
);

type SearchFunc = (value: string) => Promise<SearchResult[]>;

const useSearchChecklists: () => { searchChecklists: SearchFunc } = () => {
    const { data: checklistTemplates } = useChecklistTemplates();
    const { queryChecklistSummaries } = useChecklistSummariesImperative();
    const parameterNames = useMemo(() => {
        return (checklistTemplates || [])
            .map((ct) => ct.parameters)
            .flat()
            .map((p) => p.name)
            .filter((v, i, r) => r.indexOf(v) === i);
    }, [checklistTemplates]);
    const searchChecklists: SearchFunc = useCallback(
        async (value) => {
            const listArrays = await Promise.all(
                parameterNames.map((p) => {
                    const qs = queryString.stringify({ [`parameters.${p}`]: value });
                    return queryChecklistSummaries(`checklist-summaries?${qs}`);
                })
            );

            return listArrays
                .flat()
                .filter((v, i, r) => r.findIndex((elt) => elt.checklistId === v.checklistId) === i)
                .sort((a, b) => (a.checklistName > b.checklistName ? 1 : -1))
                .map(
                    (cs) =>
                        ({
                            label: cs.checklistName,
                            type: SearchResultType.Checklist,
                            checklistId: cs.checklistId,
                        } as ChecklistSearchResult)
                );
        },
        [parameterNames, queryChecklistSummaries]
    );
    return { searchChecklists };
};

const useSearchMenus: () => { searchMenus: SearchFunc } = () => {
    const menuTree = useMenuTree();

    const match = useRouteMatch<{ library: string; menu: string }>("/menus/:library/:menu");

    const searchMenus: SearchFunc = useCallback(
        (inputValue) => {
            const library = match?.params?.library;
            const menu = match?.params?.menu;

            const currentMenu = library && menu && menuTree[library][menu];

            const menuDefinitions = convertMenuTreeToList(menuTree);

            let matches: SearchResult[] = [];

            const number = Number(inputValue);
            const matchingMenuItem =
                currentMenu && !isNaN(number)
                    ? currentMenu.items.find((i) => i.shortcut === number.toString())
                    : undefined;
            if (library && menu && currentMenu && matchingMenuItem) {
                matches = [
                    ...matches,
                    convertMenuItemToSearchResult({
                        menu: { ...currentMenu, name: menu, library },
                        menuItem: matchingMenuItem,
                    }),
                ];
            } else {
                const searchTerms = inputValue.split(/\s/).filter((x) => x);
                menuDefinitions.forEach((menu) => {
                    if (
                        menu.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                        searchTerms.every((term) => menu.title.toLowerCase().indexOf(term.toLowerCase()) >= 0)
                    ) {
                        matches = [...matches, convertMenuToSearchResult(menu)];
                    }
                    menu.items.forEach((menuItem) => {
                        if (
                            searchTerms.every((term) => menuItem.text.toLowerCase().indexOf(term.toLowerCase()) !== -1)
                        ) {
                            matches = [...matches, convertMenuItemToSearchResult({ menu, menuItem })];
                        }
                    });
                });
            }

            const m = matches.sort(compareSearchResult);

            return new Promise((resolve) => resolve(m));
        },
        [menuTree, match]
    );
    return { searchMenus };
};

function useDebounce<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    const setDebouncedValueDebounced = useMemo(() => debounce(setDebouncedValue, delay), [delay]);

    useEffect(() => {
        setDebouncedValueDebounced(value);

        return () => setDebouncedValueDebounced.cancel();
    }, [value, delay, setDebouncedValueDebounced]);

    return debouncedValue;
}

const useSearchTheUniverse: () => { searchTheUniverse: SearchFunc } = () => {
    const { searchChecklists } = useSearchChecklists();
    const { searchMenus } = useSearchMenus();

    const searchTheUniverse: SearchFunc = useCallback(
        async (value) => (await Promise.all<SearchResult[]>([searchChecklists(value), searchMenus(value)])).flat(),
        [searchMenus, searchChecklists]
    );

    return { searchTheUniverse };
};

const defaultOptions: SearchResult[] = [];

export const Search: React.FC = () => {
    const classes = useStyles();
    const [inputValue, setInputValue] = useState("");
    const debouncedInputValue = useDebounce(inputValue, 500);
    const [options, setOptions] = useState<SearchResult[]>(defaultOptions);
    const showError = useShowError();

    const { searchTheUniverse } = useSearchTheUniverse();
    useEffect(() => {
        (async function search() {
            if (!debouncedInputValue) {
                setOptions(defaultOptions);
                return;
            }

            const matches = await searchTheUniverse(debouncedInputValue);
            setOptions(matches.length ? matches : [{ label: "no matches", type: SearchResultType.NoMatch }]);
        })();
    }, [debouncedInputValue, searchTheUniverse]);

    const history = useHistory();

    return useMemo(
        () => (
            <Autocomplete
                autoHighlight
                clearOnEscape
                style={{ width: 400 }}
                filterOptions={(x) => x}
                options={options}
                value={null}
                onChange={(
                    // eslint-disable-next-line @typescript-eslint/ban-types
                    _event: React.ChangeEvent<{}>,
                    newValue: string | SearchResult | null,
                    _reason: AutocompleteChangeReason
                ) => {
                    if (typeof newValue === "string") {
                        // This should not happen while freeSolo is set to false.
                        // https://material-ui.com/api/autocomplete/#props
                        showError(
                            "There was a problem with autocomplete",
                            new Error(`AutoComplete onChange event received an string type for newValue: "${newValue}"`)
                        );
                    } else if (newValue?.type === SearchResultType.Checklist) {
                        const { checklistId } = newValue;
                        const href = urlToChecklist(checklistId);
                        href && history.push(href);
                    } else if (newValue?.type === SearchResultType.Menu) {
                        const { library, name: menu } = newValue.menu;
                        const href = menuPageHref({ library, menu });
                        href && history.push(href);
                    } else if (newValue?.type === SearchResultType.MenuItem) {
                        const { command } = newValue.menuItem;
                        invokeCommand({ command, history });
                    }
                }}
                onInputChange={(_event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>

                        <InputBase
                            inputProps={{ ...params.inputProps, "aria-label": "search" }}
                            ref={params.InputProps.ref}
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            fullWidth
                        />
                    </div>
                )}
                getOptionLabel={(_option) => ""}
                renderOption={(option) => {
                    if (option.type === SearchResultType.Checklist) {
                        return <div>{`${option.label}`}</div>;
                    }
                    if (option.type === SearchResultType.Menu) {
                        return <div>{`${option.menu.name.toUpperCase()} - ${option.menu.title}`}</div>;
                    }
                    if (option.type === SearchResultType.MenuItem) {
                        return (
                            <div>{`${option.menuItem.shortcut}. ${
                                option.label
                            } - ${option.menu.name.toUpperCase()}, ${option.menu.library.toUpperCase()}`}</div>
                        );
                    }
                    return <></>;
                }}
                groupBy={(option) => option.type}
            />
        ),
        [classes.inputInput, classes.inputRoot, classes.search, classes.searchIcon, history, options, showError]
    );
};
