import { Link, Typography } from "@material-ui/core";
import * as React from "react";
import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useChecklistTemplates } from "../Api/useChecklistTemplates";
import { usePageTitleContext } from "../PageTitleContext";
import { urlToCreateChecklist } from "./checklistRouting";

export const ChecklistCreationTemplatePickerPage: React.FC = () => {
    const { data } = useChecklistTemplates();
    const checklistTemplates = data || [];
    const { setPageTitle } = usePageTitleContext();

    useEffect(() => {
        setPageTitle("- Start checklist");
    }, [setPageTitle]);

    return (
        <>
            <Typography variant="body1" component="p">
                Click on the checklist below that you want to start.
            </Typography>
            <ul>
                {checklistTemplates.map((checklistTemplate, index) => (
                    <li key={index}>
                        <Typography variant="body2">
                            <Link component={RouterLink} to={urlToCreateChecklist(checklistTemplate.id)}>
                                {checklistTemplate.name}
                            </Link>
                        </Typography>
                    </li>
                ))}
            </ul>
        </>
    );
};
