import { useCallback } from "react";
import isEqual from "react-fast-compare";
import { QueryKey, QueryObserverResult, useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { useStorageState } from "react-storage-hooks";
import { useRecoilState, useSetRecoilState } from "recoil";
import { userSettingsState } from "../atoms";
import { useAuth } from "../Auth";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { sessionStorageKeys } from "../sessionStorageKeys";
import { cleanseUserSettings, UserSettings } from "../UserSettings";
import { useAuthorizedFetch } from "./AuthorizedFetch";

const defaultUserSettings = cleanseUserSettings(undefined);

export function useUserSettingsQuery(queryOptions?: UseQueryOptions<UserSettings>): QueryObserverResult<UserSettings> {
    const { isUserLoggedIn } = useAuth();
    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();
    const apiUrl = "user-data";

    const getUserData = useCallback(async () => {
        if (!isUserLoggedIn) {
            return cleanseUserSettings();
        }
        const response = await authorizedFetch(apiUrl);
        const result = await response.json();
        if (response.status >= 200 && response.status < 300) {
            return cleanseUserSettings(result);
        } else {
            showError(ErrorMessage.FailedToLoadUserPreferences, result);
            throw new Error(ErrorMessage.FailedToLoadUserPreferences);
        }
    }, [authorizedFetch, showError, isUserLoggedIn]);

    const query = useQuery(["user-data"], getUserData, queryOptions);

    return query;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUserSettingsMutation() {
    const queryCache = useQueryClient();

    const [, setCache] = useStorageState<UserSettings>(
        sessionStorage,
        sessionStorageKeys.userSettings,
        defaultUserSettings
    );
    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();
    const apiUrl = "user-data";
    const set = useSetRecoilState(userSettingsState);

    const setUserData = useCallback(
        async (userSettings: UserSettings) => {
            const response = await authorizedFetch(apiUrl, {
                method: "PUT",
                body: JSON.stringify(userSettings),
            });
            if (response.status >= 200 && response.status < 300) {
                set(userSettings);
                setCache(userSettings);
                return userSettings;
            } else {
                showError(ErrorMessage.FailedToSaveUserPreferences, response);
            }
        },
        [authorizedFetch, set, setCache, showError]
    );

    const mutation = useMutation(setUserData, {
        onSuccess: (data) => {
            const queryKey: QueryKey = ["user-data"];
            if (data) {
                queryCache.setQueryData(queryKey, data);
            } else {
                queryCache.invalidateQueries(queryKey);
            }
        },
    });

    return mutation;
}

export function useUserSettings(): UserSettings {
    const [cache, setCache] = useStorageState<UserSettings>(
        sessionStorage,
        sessionStorageKeys.userSettings,
        defaultUserSettings
    );
    const [userSettings, setUserSettings] = useRecoilState(userSettingsState);
    const onSuccess = useCallback(
        (data: UserSettings) => {
            if (!isEqual(data, cache)) {
                setCache(data);
            }
            if (!isEqual(data, userSettings)) {
                setUserSettings(data);
            }
        },
        [cache, setCache, userSettings, setUserSettings]
    );
    const { data } = useUserSettingsQuery({ staleTime: Infinity, refetchInterval: 5 * 60 * 1000, onSuccess });

    return data || cache;
}
