export enum ReportListOrderBy {
    CreatedOn = "createdOn",
    Name = "name",
    CreatedByUserId = "createdByUserId",
    CreatedByProcedureLibrary = "createdByProcedureLibrary",
    CreatedByProcedureName = "createdByProcedureName",
    CreatedByProgramLibrary = "createdByProgramLibrary",
    CreatedByProgramName = "createdByProgramName",
}

export enum OrderByDirection {
    Ascending = "ascending",
    Descending = "descending",
}

export type ReportListParameters = {
    pageNumber: number;
    pageSize: number;
    orderBy?: ReportListOrderBy;
    orderDirection?: OrderByDirection;
    createdByUserId?: string;
};

export interface RpgProcedure {
    library: string;
    name: string;
}

export interface RpgProgram {
    library: string;
    name: string;
}

export interface CreateReportInfo {
    name: string;
    createdByUserId: string;
    createdByProcedure: RpgProcedure;
    createdByProgram: RpgProgram;
}

export interface ReportInfo extends CreateReportInfo {
    id: string;
    createdOn: Date;
}
