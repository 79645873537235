import FilterListFilledIcon from "@material-ui/icons/FilterList";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import FilterListSharpIcon from "@material-ui/icons/FilterListSharp";
import FilterListTwoToneIcon from "@material-ui/icons/FilterListTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const filterListIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return FilterListFilledIcon;
        case IconStyle.Outlined:
            return FilterListOutlinedIcon;
        case IconStyle.Rounded:
            return FilterListRoundedIcon;
        case IconStyle.TwoTone:
            return FilterListTwoToneIcon;
        case IconStyle.Sharp:
            return FilterListSharpIcon;
    }
};

export const FilterListIcon = createIconComponent(filterListIconSelector);
