import DirectionsRunFilledIcon from "@material-ui/icons/DirectionsRun";
import DirectionsRunOutlinedIcon from "@material-ui/icons/DirectionsRunOutlined";
import DirectionsRunRoundedIcon from "@material-ui/icons/DirectionsRunRounded";
import DirectionsRunSharpIcon from "@material-ui/icons/DirectionsRunSharp";
import DirectionsRunTwoToneIcon from "@material-ui/icons/DirectionsRunTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const directionsRunIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return DirectionsRunFilledIcon;
        case IconStyle.Outlined:
            return DirectionsRunOutlinedIcon;
        case IconStyle.Rounded:
            return DirectionsRunRoundedIcon;
        case IconStyle.TwoTone:
            return DirectionsRunTwoToneIcon;
        case IconStyle.Sharp:
            return DirectionsRunSharpIcon;
    }
};

export const DirectionsRunIcon = createIconComponent(directionsRunIconSelector);
