import ChevronLeftFilledIcon from "@material-ui/icons/ChevronLeft";
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import ChevronLeftSharpIcon from "@material-ui/icons/ChevronLeftSharp";
import ChevronLeftTwoToneIcon from "@material-ui/icons/ChevronLeftTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const chevronLeftIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return ChevronLeftFilledIcon;
        case IconStyle.Outlined:
            return ChevronLeftOutlinedIcon;
        case IconStyle.Rounded:
            return ChevronLeftRoundedIcon;
        case IconStyle.TwoTone:
            return ChevronLeftTwoToneIcon;
        case IconStyle.Sharp:
            return ChevronLeftSharpIcon;
    }
};

export const ChevronLeftIcon = createIconComponent(chevronLeftIconSelector);
