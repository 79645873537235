import FolderFilledIcon from "@material-ui/icons/Folder";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import FolderRoundedIcon from "@material-ui/icons/FolderRounded";
import FolderSharpIcon from "@material-ui/icons/FolderSharp";
import FolderTwoToneIcon from "@material-ui/icons/FolderTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const folderIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return FolderFilledIcon;
        case IconStyle.Outlined:
            return FolderOutlinedIcon;
        case IconStyle.Rounded:
            return FolderRoundedIcon;
        case IconStyle.TwoTone:
            return FolderTwoToneIcon;
        case IconStyle.Sharp:
            return FolderSharpIcon;
    }
};

export const FolderIcon = createIconComponent(folderIconSelector);
