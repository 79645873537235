import { createStyles, makeStyles, TextField, Theme } from "@material-ui/core";
import { Field, FieldProps } from "formik";
import * as React from "react";
import { useUserSettings } from "../Api";
import { DataFileUtilityFieldLabelLayout } from "../UserSettings";
import { FieldMetadata } from "./DataFileTypes";

export interface DataFileFieldProps extends FieldMetadata {
    onKeyDown?: (event: React.KeyboardEvent<Element>) => void;
    autoFocus?: boolean;
}

/**
 * Compute the width of an input needed to contain the widest possible string - all W's.  For example, the widest
 * rendered three character string using a variable width font is "WWW". This is a total hack job.  "It looks ok
 * on my box."
 */
const inputStyleWidthValueBasedOnAllCharactersAreW = (length: number) => `${Math.ceil(length * 1.6)}ch`;

const enableLayoutDebuggingColors = false;
const textFieldDebuggingStyle = enableLayoutDebuggingColors ? { backgroundColor: "#CF7EDE" /* purple */ } : undefined;
const labelDebuggingStyle = enableLayoutDebuggingColors ? { backgroundColor: "#DEB852" /* beige */ } : undefined;
const inputDebuggingStyle = enableLayoutDebuggingColors ? { backgroundColor: "#68DEB9" /* turquoise */ } : undefined;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            marginRight: theme.spacing(2),
            ...textFieldDebuggingStyle,
        },
        label: {
            whiteSpace: "nowrap",
            ...labelDebuggingStyle,
        },
    })
);

export const DataFileFieldContainer: React.FC<DataFileFieldProps> = (props) => {
    const { dataFileUtilityFieldLabelLayout } = useUserSettings();
    return <DataFileField {...{ ...props, dataFileUtilityFieldLabelLayout }} />;
};

export const DataFileField: React.FC<
    DataFileFieldProps & {
        dataFileUtilityFieldLabelLayout: DataFileUtilityFieldLabelLayout;
    }
> = (props) => {
    const { name, label, length, readonly, onKeyDown, autoFocus, dataFileUtilityFieldLabelLayout } = props;
    const classes = useStyles();

    const helperText =
        dataFileUtilityFieldLabelLayout === DataFileUtilityFieldLabelLayout.NameOnly
            ? ""
            : `max ${length} char${length === 1 ? "" : "s"}`;
    const textFieldWidth = `${Math.max(Math.ceil(length * 1.6), label.length, helperText.length)}ch`;
    return (
        <Field name={name}>
            {(fieldProps: FieldProps): React.ReactNode => {
                const { field, meta } = fieldProps;
                return (
                    <TextField
                        {...field}
                        {...{ label, onKeyDown, autoFocus }}
                        value={field.value || ""}
                        autoComplete="off"
                        error={Boolean(meta.touched && meta.error)}
                        helperText={(meta.touched && meta.error) || helperText}
                        InputProps={{
                            readOnly: readonly,
                            style: {
                                width: textFieldWidth,
                                ...inputDebuggingStyle,
                            },
                        }}
                        inputProps={{
                            style: {
                                width: inputStyleWidthValueBasedOnAllCharactersAreW(length),
                            },
                            maxLength: length,
                        }}
                        classes={{ root: classes.textField }}
                        InputLabelProps={{
                            className: classes.label,
                        }}
                        FormHelperTextProps={{
                            style: {
                                whiteSpace: "nowrap",
                            },
                        }}
                    />
                );
            }}
        </Field>
    );
};
