import SaveFilledIcon from "@material-ui/icons/Save";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";
import SaveSharpIcon from "@material-ui/icons/SaveSharp";
import SaveTwoToneIcon from "@material-ui/icons/SaveTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const saveIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return SaveFilledIcon;
        case IconStyle.Outlined:
            return SaveOutlinedIcon;
        case IconStyle.Rounded:
            return SaveRoundedIcon;
        case IconStyle.TwoTone:
            return SaveTwoToneIcon;
        case IconStyle.Sharp:
            return SaveSharpIcon;
    }
};

export const SaveIcon = createIconComponent(saveIconSelector);
