import { Badge, Collapse, makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
import * as React from "react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import {
    convertRefetchIntervalToQueryConfig,
    HubPageData,
    useChecklistSummaries,
    useHubPages,
    useUserSettings,
} from "./Api";
import { AppDrawer, AppNav } from "./AppLayout";
import { MenuTree } from "./atoms";
import { urlToHubPage } from "./Checklist";
import { ThematicColor } from "./Checklist/ThematicColor";
import {
    AppsIcon,
    DashboardIcon,
    DescriptionIcon,
    ExpandLessIcon,
    ExpandMoreIcon,
    FolderIcon,
    HelpIcon,
    HomeIcon,
    MenuBookIcon,
    MenuIcon,
} from "./Icons";
import { useMainMenuOpen, useSetMainMenuOpen } from "./MainMenuOpenContext";
import { useMenuItemStyles } from "./useMenuItemStyles";
import { MainMenuLayout, WorkflowMainMenuLayout } from "./UserSettings";

export interface NavProps {
    menuTree?: MenuTree;
}

export const useStyles = makeStyles((theme) => ({
    [ThematicColor.Success]: {
        backgroundColor: theme.palette.success.dark,
    },
    [ThematicColor.Grey]: {
        backgroundColor: theme.palette.grey.A200,
    },
    [ThematicColor.Info]: {
        backgroundColor: theme.palette.info.dark,
    },
    [ThematicColor.Warning]: {
        backgroundColor: theme.palette.warning.dark,
    },
    [ThematicColor.Error]: {
        backgroundColor: theme.palette.error.dark,
    },
}));

const ChecklistSummaryCountBadge: React.FC<{ apiUrl: string }> = ({ apiUrl, children }) => {
    const { hubPageRefetchIntervalInSeconds } = useUserSettings();
    const queryConfig = convertRefetchIntervalToQueryConfig(hubPageRefetchIntervalInSeconds);
    const { data } = useChecklistSummaries(apiUrl, queryConfig);
    const lateCount = data?.filter((summary) => summary.isLate).length;
    const allCount = data?.length;

    const color = lateCount ? ThematicColor.Error : ThematicColor.Info;
    const count = lateCount || allCount;
    const classes = useStyles();
    const className = classes[color];

    return count ? (
        <Badge badgeContent={count} color="error" classes={{ badge: className }}>
            {children}
        </Badge>
    ) : (
        <>{children}</>
    );
};

const HubPageMenuItem: React.FC<HubPageData> = ({ spaUrlParam, showBadgeWithCountFromUrl, menuItemText }) => {
    return (
        <ListItem button component={Link} to={urlToHubPage(spaUrlParam)}>
            <ListItemIcon>
                {showBadgeWithCountFromUrl ? (
                    <ChecklistSummaryCountBadge apiUrl={showBadgeWithCountFromUrl}>
                        <AppsIcon />
                    </ChecklistSummaryCountBadge>
                ) : (
                    <AppsIcon />
                )}
            </ListItemIcon>
            <ListItemText primary={menuItemText} />
        </ListItem>
    );
};

export const Nav: React.FC<NavProps> = ({ menuTree }) => {
    const {
        mainMenuLayout,
        workflowMainMenuLayout: hubMenuLayout,
        showApiDashboardInMenu,
        showMenuListInMenu,
        showScreenListInMenu,
    } = useUserSettings();
    const classes = useMenuItemStyles();

    const { mainMenuOpen } = useMainMenuOpen();
    const { setMainMenuOpen } = useSetMainMenuOpen();
    const hubPages = useHubPages();

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const drawer = useMemo(() => {
        const handleChange = (panel: string) => (): void => {
            setExpanded(expanded === panel ? false : panel);
        };

        const hubMenuItems = (divider: boolean) =>
            hubPages.map((hubPage) => (
                <React.Fragment key={`hubPage-${hubPage.spaUrlParam}`}>
                    {divider && <Divider />}

                    <HubPageMenuItem {...hubPage} />
                </React.Fragment>
            ));

        const workflowsMenu =
            hubMenuLayout === WorkflowMainMenuLayout.Hidden ? (
                <></>
            ) : hubMenuLayout === WorkflowMainMenuLayout.Flat ? (
                hubMenuItems(true)
            ) : (
                <>
                    <Divider />
                    <List>
                        <ListItem button onClick={handleChange("hub")}>
                            <ListItemIcon>
                                <MenuBookIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Workflows"} />
                            {expanded === "hub" ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItem>
                        <Collapse in={expanded === "hub"} timeout="auto" unmountOnExit key={`hub`}>
                            {hubMenuItems(false)}
                        </Collapse>
                    </List>
                </>
            );

        const loggedInDrawerContents = !menuTree ? null : (
            <>
                {workflowsMenu}
                {Object.keys(menuTree)
                    .sort()
                    .map((library) => (
                        <div key={`library-${library}`}>
                            <Divider />
                            <List>
                                <ListItem button onClick={handleChange(library)}>
                                    <ListItemIcon>
                                        <MenuBookIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={library} style={{ textTransform: "uppercase" }} />
                                    {expanded === library ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </ListItem>
                                <Collapse in={expanded === library} timeout="auto" unmountOnExit>
                                    {Object.keys(menuTree[library]).map((menu) => {
                                        const menuItem = menuTree[library][menu];
                                        const className = clsx({
                                            [classes.mainMenuItem]: true,
                                            [classes.disabled]: !menuItem.isEnabled,
                                            [classes.emphasized]: menuItem.isEmphasized,
                                        });
                                        return (
                                            <ListItem
                                                key={`menu-${library}-${menu}`}
                                                button
                                                component={Link}
                                                to={`/menus/${library}/${menu}`}
                                            >
                                                <ListItemIcon>
                                                    <ListItemIcon>
                                                        <MenuIcon />
                                                    </ListItemIcon>
                                                </ListItemIcon>
                                                <ListItemText primary={<span className={className}>{menu}</span>} />
                                            </ListItem>
                                        );
                                    })}
                                </Collapse>
                            </List>
                        </div>
                    ))}
                <Divider />
                <ListItem button component={Link} to="/reports">
                    <ListItemIcon>
                        <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reports" />
                </ListItem>
                <Divider />
                <ListItem button component={Link} to="/explorer">
                    <ListItemIcon>
                        <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary="Explorer" />
                </ListItem>
                <Divider />
                <ListItem button component={Link} to="/guestbook">
                    <ListItemIcon>
                        <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary="Guestbook" />
                </ListItem>
                {showMenuListInMenu && (
                    <>
                        <Divider />
                        <ListItem button component={Link} to="/menus">
                            <ListItemIcon>
                                <AppsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Menu list" />
                        </ListItem>
                    </>
                )}
                {showScreenListInMenu && (
                    <>
                        <Divider />
                        <ListItem button component={Link} to="/screens">
                            <ListItemIcon>
                                <AppsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Screen list" />
                        </ListItem>
                    </>
                )}
                {showApiDashboardInMenu && (
                    <>
                        <Divider />
                        <ListItem button component={Link} to="/api-dashboard">
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="API Dashboard" />
                        </ListItem>
                    </>
                )}
                <Divider />
                <ListItem button component={Link} to="/help">
                    <ListItemIcon>
                        <HelpIcon />
                    </ListItemIcon>
                    <ListItemText primary="Help" />
                </ListItem>
            </>
        );

        const drawerContents = (
            <div>
                {/* <AppHeaderSpacer />
            <Divider /> */}
                <ListItem button component={Link} to="/">
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
                {loggedInDrawerContents}
            </div>
        );

        const responsiveDrawer = (
            <>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <AppDrawer
                        variant="temporary"
                        anchor="left"
                        open={mainMenuOpen}
                        onClose={() => setMainMenuOpen(false)}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawerContents}
                    </AppDrawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <AppDrawer variant="permanent" open>
                        {drawerContents}
                    </AppDrawer>
                </Hidden>
            </>
        );

        const persistentDrawer = (
            <AppDrawer open={mainMenuOpen} variant="persistent" anchor="left">
                {drawerContents}
            </AppDrawer>
        );
        return mainMenuLayout === MainMenuLayout.Responsive ? responsiveDrawer : persistentDrawer;
    }, [
        classes.disabled,
        classes.emphasized,
        classes.mainMenuItem,
        expanded,
        hubMenuLayout,
        hubPages,
        mainMenuLayout,
        mainMenuOpen,
        menuTree,
        setMainMenuOpen,
        showApiDashboardInMenu,
        showMenuListInMenu,
        showScreenListInMenu,
    ]);

    return <AppNav aria-label="PAMS libraries">{drawer}</AppNav>;
};
