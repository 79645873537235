import DashboardFilledIcon from "@material-ui/icons/Dashboard";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import DashboardRoundedIcon from "@material-ui/icons/DashboardRounded";
import DashboardSharpIcon from "@material-ui/icons/DashboardSharp";
import DashboardTwoToneIcon from "@material-ui/icons/DashboardTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const dashboardIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return DashboardFilledIcon;
        case IconStyle.Outlined:
            return DashboardOutlinedIcon;
        case IconStyle.Rounded:
            return DashboardRoundedIcon;
        case IconStyle.TwoTone:
            return DashboardTwoToneIcon;
        case IconStyle.Sharp:
            return DashboardSharpIcon;
    }
};

export const DashboardIcon = createIconComponent(dashboardIconSelector);
