import { createStyles, makeStyles } from "@material-ui/core";

export const useRteStyles = makeStyles(() =>
    createStyles({
        rte: {
            fontFamily: "inherit",
        },
        readOnlyRte: {
            fontFamily: "inherit",
        },
        readOnlyRteUncontained: {
            fontFamily: "inherit",
            border: "none",
            backgroundColor: "inherit",
            // See: https://github.com/sstur/react-rte/blob/master/src/RichTextEditor.css#L26
            "& .public-DraftEditor-content": {
                padding: 0,
            },
        },
    })
);
