import md5 from "md5";
import { useMemo } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import { FallbackAvatar } from "../UserSettings";

type Parameters = {
    email: string;
    fallbackAvatar: FallbackAvatar;
};

export function getStandardGravatarUrl({ email, fallbackAvatar }: Parameters): string {
    return `https://www.gravatar.com/avatar/${md5(email?.toLowerCase() || "")}.png?d=${fallbackAvatar}`;
}

export function useVerifiedGravatarUrl(
    { email, fallbackAvatar }: Parameters,
    queryConfig?: UseQueryOptions<string>
): string {
    const query = useQuery(
        ["gravatar-url", email, fallbackAvatar],
        () => getVerifiedGravatarUrl(email, fallbackAvatar),
        {
            staleTime: Infinity,
            ...queryConfig,
        }
    );

    async function getVerifiedGravatarUrl(email: string, fallbackAvatar: FallbackAvatar) {
        const url = getStandardGravatarUrl({ email, fallbackAvatar });
        const response = await fetch(url);
        if (response.status === 404) {
            return "";
        }
        return url;
    }

    return useMemo(() => query.data || "", [query.data]);
}
