import AlarmFilledIcon from "@material-ui/icons/Alarm";
import AlarmOutlinedIcon from "@material-ui/icons/AlarmOutlined";
import AlarmRoundedIcon from "@material-ui/icons/AlarmRounded";
import AlarmSharpIcon from "@material-ui/icons/AlarmSharp";
import AlarmTwoToneIcon from "@material-ui/icons/AlarmTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const alarmIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return AlarmFilledIcon;
        case IconStyle.Outlined:
            return AlarmOutlinedIcon;
        case IconStyle.Rounded:
            return AlarmRoundedIcon;
        case IconStyle.TwoTone:
            return AlarmTwoToneIcon;
        case IconStyle.Sharp:
            return AlarmSharpIcon;
    }
};

export const AlarmIcon = createIconComponent(alarmIconSelector);
