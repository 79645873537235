import DateFnsUtils from "@date-io/date-fns";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useField } from "formik";
import * as React from "react";
import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import isEqual from "react-fast-compare";
import { hasValue } from "../../hasValue";
import { AddIcon, DeleteForeverIcon } from "../../Icons";
import { ChecklistParameterEditorProps } from "./ChecklistParameterEditorProps";

const useDateArrayParameterEditorStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(2),
    },
    arrayContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    addArrayButton: {
        marginTop: theme.spacing(1),
    },
}));

type DateArrayChecklistParameterEditorProps = ChecklistParameterEditorProps<Date[]>;

export const DateArrayChecklistParameterEditor: React.FC<DateArrayChecklistParameterEditorProps> = ({
    label,
    value: initialValue,
    name,
}) => {
    const [, { value }, { setValue }] = useField(name);
    const [dates, setDates] = useState<(Date | null)[]>(initialValue);
    const [open, setOpen] = useState(-1);
    const [autoFocusIndex, setAutoFocusIndex] = useState(-1);

    const classes = useDateArrayParameterEditorStyles();
    useEffect(() => {
        const filteredDates = dates.filter(hasValue);
        if (!isEqual(value, filteredDates)) {
            setValue(filteredDates);
        }
    }, [dates, value, setValue]);

    const handleChange = useCallback(
        (index: number) => (date: MaterialUiPickersDate, _value?: string | null | undefined) => {
            date?.setHours(23, 59, 59);
            setDates((prevState) =>
                prevState.map((prevDate, prevDateIndex) => (index === prevDateIndex ? date : prevDate))
            );
        },
        []
    );

    const handleAddClick: MouseEventHandler = useCallback(
        (event) => {
            event.preventDefault();
            setAutoFocusIndex(dates.length);
            setDates((prevState) => [...prevState, null]);
        },
        [dates, setDates, setAutoFocusIndex]
    );

    return useMemo(() => {
        return (
            <div className={classes.root}>
                <Typography>{label}</Typography>
                <div className={classes.arrayContainer}>
                    {dates.length === 0 && (
                        <Typography variant="body2">
                            <i>No dates entered yet.</i>
                        </Typography>
                    )}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        {dates.map((date, i) => (
                            <React.Fragment key={i}>
                                <Box display="flex" alignItems="center">
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        open={i === open}
                                        onOpen={() => setOpen(i)}
                                        onClose={() => setOpen(-1)}
                                        value={date}
                                        autoOk
                                        format="M/d/yyyy"
                                        mask="__/__/____"
                                        onChange={handleChange(i)}
                                        autoFocus={i === autoFocusIndex}
                                    />
                                    <IconButton
                                        onClick={() => setDates((prevState) => prevState.filter((v, j) => j !== i))}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Box>
                            </React.Fragment>
                        ))}
                    </MuiPickersUtilsProvider>
                    <Box mt={1}>
                        <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddClick}>
                            Add date
                        </Button>
                    </Box>
                </div>
            </div>
        );
    }, [
        classes.arrayContainer,
        classes.root,
        dates,
        setOpen,
        setDates,
        label,
        open,
        autoFocusIndex,
        handleAddClick,
        handleChange,
    ]);
};
