import { KeyboardDatePicker, KeyboardDatePickerProps } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useField } from "formik";
import * as React from "react";
import { ChecklistParameterEditorProps } from "./ChecklistParameterEditorProps";

type DateChecklistParameterEditorProps = ChecklistParameterEditorProps<Date>;

const dateEditorInputProps = { autoComplete: "off" };
const dateEditorKeyboardButtonProps = { "aria-label": "change date" };

export const DateChecklistParameterEditor: React.FC<DateChecklistParameterEditorProps> = ({
    label,
    value,
    name,
    autoFocus,
    onBlur,
    error,
    helperText,
}) => {
    const fieldTuple = useField(name);
    const [, , { setValue }] = fieldTuple;
    const handleChange: (date: MaterialUiPickersDate, value?: string | null | undefined) => void = (date) => {
        date?.setHours(23, 59, 59);
        setValue(date);
    };
    const props: KeyboardDatePickerProps = {
        label,
        value,
        name,
        autoFocus,
        onBlur,
        error,
        helperText,
        onChange: handleChange,
        disableToolbar: true,
        variant: "inline",
        format: "MM/dd/yyyy",
        margin: "normal",
        KeyboardButtonProps: dateEditorKeyboardButtonProps,
        inputProps: dateEditorInputProps,
        autoOk: true,
    };
    return <KeyboardDatePicker {...props} />;
};
