import { BaseTextFieldProps } from "@material-ui/core";
import { Field, FieldProps } from "formik";
import * as React from "react";
import {
    BooleanParameterTypeKey,
    DateListParameterTypeKey,
    DateParameterTypeKey,
    HtmlParameterTypeKey,
    TextAreaParameterTypeKey,
    WebApiChecklistTemplateParameter,
} from "../../Api";
import { BooleanChecklistParameterEditor } from "./BooleanChecklistParameterEditor";
import { ChecklistParameterEditorFieldLabel } from "./ChecklistParameterEditorFieldLabel";
import { DateArrayChecklistParameterEditor } from "./DateArrayChecklistParameterEditor";
import { DateChecklistParameterEditor } from "./DateChecklistParameterEditor";
import { HtmlChecklistParameterEditor } from "./HtmlChecklistParameterEditor";
import { StringChecklistParameterEditor } from "./StringChecklistParameterEditor";

export const ParameterEditorField: React.FC<
    WebApiChecklistTemplateParameter & Pick<BaseTextFieldProps, "autoFocus">
> = ({ name, type, label, isRequired: required, autoFocus }) => (
    <Field name={name}>
        {(fieldProps: FieldProps): React.ReactNode => {
            const {
                field,
                meta: { error: metaError, touched },
            } = fieldProps;
            const commonFieldProps = {
                ...field,
                label: <ChecklistParameterEditorFieldLabel {...{ label, required }} />,
                autoFocus,
                error: Boolean(touched && metaError),
                helperText: touched && metaError,
            };
            return type === DateParameterTypeKey ? (
                <DateChecklistParameterEditor {...commonFieldProps} />
            ) : type === DateListParameterTypeKey ? (
                <DateArrayChecklistParameterEditor {...commonFieldProps} />
            ) : type === TextAreaParameterTypeKey ? (
                <StringChecklistParameterEditor {...commonFieldProps} textArea />
            ) : type === BooleanParameterTypeKey ? (
                <BooleanChecklistParameterEditor {...commonFieldProps} />
            ) : type === HtmlParameterTypeKey ? (
                <HtmlChecklistParameterEditor {...commonFieldProps} />
            ) : (
                // Maybe it's a string. ¯\_(ツ)_/¯
                <StringChecklistParameterEditor {...commonFieldProps} />
            );
        }}
    </Field>
);
