import AddFilledIcon from "@material-ui/icons/Add";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import AddTwoToneIcon from "@material-ui/icons/AddTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const addIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return AddFilledIcon;
        case IconStyle.Outlined:
            return AddOutlinedIcon;
        case IconStyle.Rounded:
            return AddRoundedIcon;
        case IconStyle.TwoTone:
            return AddTwoToneIcon;
        case IconStyle.Sharp:
            return AddSharpIcon;
    }
};

export const AddIcon = createIconComponent(addIconSelector);
