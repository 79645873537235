import { Avatar, Box, Button, Divider, Grid, IconButton, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { TimelineDot } from "@material-ui/lab";
import Timeline from "@material-ui/lab/Timeline";
import * as React from "react";
import { SyntheticEvent, useState } from "react";
import RichTextEditor from "react-rte";
import {
    getStandardGravatarUrl,
    useAddNoteToChecklistStepMutation,
    useAuthorizedFetch,
    useScheduleChecklistStepMutation,
    useUsers,
} from "../../Api";
import { downloadReponseToDisk } from "../../Api/downloadReponseToDisk";
import { formatDate, formatDateTime } from "../../formatDate";
import { AddIcon, CancelIcon, SaveIcon } from "../../Icons";
import { FallbackAvatar } from "../../UserSettings";
import { useRteStyles } from "../../useRteStyles";
import { StepData } from "../ChecklistTypes";
import { DateTimePicker } from "../DateTimePicker";
import { UserDisplayName } from "../UserDisplayName";
import { BadgeForScheduling } from "./BadgeForScheduling";
import { ChecklistProperty, ChecklistPropertyLabel, ChecklistPropertyValue } from "./ChecklistProperty";
import { CollapsedTimelineItem } from "./CollapsedTimelineItem";

export const useStyles = makeStyles((theme) => ({
    notesLabel: {
        marginRight: theme.spacing(1),
        fontWeight: "bold",
    },
    propertyGrid: {
        marginTop: theme.spacing(0),
    },
    addNoteButtonContainerShow: {},
    addNoteButtonContainerHide: {
        display: "none",
    },
    addNoteRteContainerShow: {
        width: "100%",
    },
    addNoteRteContainerHide: {
        display: "none",
    },
    rte: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    addNoteButtonBar: {
        display: "flex",
        flexGrow: 1,
        justifyContent: "flex-end",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
}));

const ChecklistStepContentFC: React.FC<StepData> = (props) => {
    const {
        name,
        needsScheduling,
        checklistId,
        checklistStepId,
        attachments,
        annotations,
        dueDate,
        completedDate,
        assignedToUserId,
        department,
    } = props;
    const classes = useStyles();
    const rteClasses = useRteStyles();
    const { authorizedFetch } = useAuthorizedFetch();

    const addNoteToChecklistStepMutation = useAddNoteToChecklistStepMutation();
    const [rteValue, setRteValue] = useState(RichTextEditor.createEmptyValue());

    const scheduleChecklistStep = useScheduleChecklistStepMutation();
    const verticalContentSeparator = (
        <Grid item xs={12}>
            <Box mt={2} mb={2}>
                <Divider />
            </Box>
        </Grid>
    );

    const [revealAddNote, setRevealAddNote] = useState(false);
    const handleAddNoteClick = (event: SyntheticEvent) => {
        event.preventDefault();
        if (!revealAddNote) {
            setRevealAddNote(true);
        }
    };

    const [addNoteHover, setAddNoteHover] = useState(false);
    const handleAddNoteHover = (hover: boolean) => setAddNoteHover(hover);

    const handleDownload = async (event: SyntheticEvent, attachmentId: string, filename: string) => {
        event.preventDefault();

        const apiUrl = `/attachments/${attachmentId}`;
        const response = await authorizedFetch(apiUrl);
        downloadReponseToDisk(response, filename);
    };

    return (
        <Grid container spacing={0} className={classes.propertyGrid}>
            <Grid item xs={12}>
                <Box mb={2}>
                    <Divider />
                </Box>
            </Grid>
            <Grid item xs={6}>
                <ChecklistProperty>
                    <ChecklistPropertyLabel>Department</ChecklistPropertyLabel>
                    <ChecklistPropertyValue>{department}</ChecklistPropertyValue>
                </ChecklistProperty>
            </Grid>
            <Grid item xs={6}>
                <ChecklistProperty centerVertically>
                    <ChecklistPropertyLabel>Needed by</ChecklistPropertyLabel>
                    <ChecklistPropertyValue omitTypography>
                        <BadgeForScheduling needsScheduling={needsScheduling}>
                            <DateTimePicker
                                initialValue={dueDate}
                                onSubmit={(date) =>
                                    scheduleChecklistStep.mutateAsync({ checklistId, checklistStepId, dueDate: date })
                                }
                            />
                        </BadgeForScheduling>
                    </ChecklistPropertyValue>
                </ChecklistProperty>
            </Grid>
            <Grid item xs={6}>
                <ChecklistProperty>
                    <ChecklistPropertyLabel>Date completed</ChecklistPropertyLabel>
                    <ChecklistPropertyValue>{completedDate ? formatDate(completedDate) : ""}</ChecklistPropertyValue>
                </ChecklistProperty>
            </Grid>
            <Grid item xs={6}>
                <ChecklistProperty>
                    <ChecklistPropertyLabel>Assigned to</ChecklistPropertyLabel>
                    <ChecklistPropertyValue>
                        {assignedToUserId === undefined ? "UNASSIGNED" : <UserDisplayName userId={assignedToUserId} />}
                    </ChecklistPropertyValue>
                </ChecklistProperty>
            </Grid>
            {verticalContentSeparator}
            <Grid item xs={12}>
                <Typography variant="h6" className={classes.notesLabel}>
                    Attachments
                </Typography>
            </Grid>
            {attachments && attachments.length > 0 && (
                <Grid item xs={12}>
                    <ul>
                        {attachments.map((a, index) => (
                            <li key={index}>
                                <Link
                                    href="#"
                                    onClick={(event: SyntheticEvent) => handleDownload(event, a.attachmentId, a.name)}
                                >
                                    {a.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </Grid>
            )}
            {verticalContentSeparator}
            <Typography variant="h6" className={classes.notesLabel}>
                Notes
            </Typography>
            <Grid item xs={12}>
                <Timeline>
                    {(annotations || []).map((n, index) => (
                        <CollapsedTimelineItem
                            key={index}
                            timelineDot={<UserTimelineDot userId={n.createdByUserId} />}
                            timelineContent={
                                <div style={{ marginTop: "13px" }}>
                                    <Typography variant="subtitle1" noWrap>
                                        <UserDisplayName userId={n.createdByUserId} /> commented on{" "}
                                        {formatDateTime(n.createdDate)}
                                    </Typography>
                                    <RichTextEditor
                                        value={RichTextEditor.createValueFromString(n.note || "", "html")}
                                        readOnly
                                        className={`${classes.rte} ${rteClasses.readOnlyRte}`}
                                    />
                                </div>
                            }
                        />
                    ))}
                    <div
                        className={
                            !revealAddNote ? classes.addNoteButtonContainerShow : classes.addNoteButtonContainerHide
                        }
                    >
                        <CollapsedTimelineItem
                            timelineDot={
                                <AddNoteTimelineDot onClick={handleAddNoteClick} onHover={handleAddNoteHover} />
                            }
                            noConnector
                            timelineContent={
                                <div style={{ marginTop: "13px" }}>
                                    <Typography variant="subtitle1" noWrap>
                                        <Link
                                            onClick={handleAddNoteClick}
                                            href="#"
                                            underline={addNoteHover ? "always" : undefined}
                                        >
                                            Add a note
                                        </Link>
                                    </Typography>
                                </div>
                            }
                        />
                    </div>
                </Timeline>
            </Grid>
            <div className={revealAddNote ? classes.addNoteRteContainerShow : classes.addNoteRteContainerHide}>
                <RichTextEditor
                    value={rteValue}
                    placeholder="Add a note."
                    onChange={(value) => {
                        setRteValue(value);
                    }}
                    className={`${classes.rte} ${rteClasses.rte}`}
                />

                <div className={classes.addNoteButtonBar}>
                    <Button
                        startIcon={<CancelIcon />}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setRevealAddNote(false);
                        }}
                    >
                        Cancel note
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            const note = rteValue.toString("html");
                            addNoteToChecklistStepMutation.mutateAsync(
                                {
                                    checklistId,
                                    checklistStepId,
                                    checklistStepName: name,
                                    note,
                                },
                                {
                                    onSuccess: () => {
                                        setRevealAddNote(false);
                                        setRteValue(RichTextEditor.createEmptyValue());
                                    },
                                }
                            );
                        }}
                    >
                        Save note
                    </Button>
                </div>
            </div>
        </Grid>
    );
};

export const ChecklistStepContent = React.memo(ChecklistStepContentFC);

const AddNoteTimelineDot: React.FC<{ onClick?(event: SyntheticEvent): void; onHover(hover: boolean): void }> = ({
    onClick,
    onHover,
}) => {
    const classes = useUserTimelineDotStyles();

    const handleClick = (event: SyntheticEvent) => {
        onClick?.(event);
    };

    return (
        <TimelineDot
            className={[classes.timelineDot, classes.timelineDotAddNote].join(" ")}
            style={{ backgroundColor: "inherit" }}
        >
            <Avatar className={classes.timelineDotAddNote}>
                <IconButton
                    onClick={handleClick}
                    className={classes.avatarAddNote}
                    onMouseMove={() => onHover(true)}
                    onMouseEnter={() => onHover(true)}
                    onMouseLeave={() => onHover(false)}
                >
                    <AddIcon />
                </IconButton>
            </Avatar>
        </TimelineDot>
    );
};

const useUserTimelineDotStyles = makeStyles((theme) => ({
    authAvatar: {
        width: "40px",
        height: "40px",
    },
    anonAccountCircle: {
        width: "40px",
        height: "40px",
    },
    timelineDot: {
        boxShadow: "none",
    },
    timelineDotAddNote: {
        backgroundColor: theme.palette.primary.main,
    },
    avatarAddNote: {
        color: theme.palette.primary.contrastText,
    },
}));

const UserTimelineDot: React.FC<{ userId: string }> = ({ userId }) => {
    const classes = useUserTimelineDotStyles();
    const { data } = useUsers({ userId });
    const user = data && data.length > 0 && data[0];

    const avatar = user ? (
        <Avatar
            className={classes.authAvatar}
            src={getStandardGravatarUrl({ email: user?.emailAddress || "", fallbackAvatar: FallbackAvatar.Retro })}
        />
    ) : (
        <Avatar>
            <AccountCircle color="inherit" className={classes.anonAccountCircle} />
        </Avatar>
    );

    return (
        <TimelineDot className={classes.timelineDot} style={{ backgroundColor: "inherit" }}>
            {avatar}
        </TimelineDot>
    );
};
