import queryString from "query-string";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuthorizedFetch } from "../Api";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { createLogger } from "../log";
import { useSetSnack } from "../SnackContext";
import { useSpinner } from "../SpinnerContext";
import { convertToDataFileMetadata, convertToRecordData } from "./convertModels";
import { DataFileAdd } from "./DataFileAdd";
import { DataFileEdit, Values } from "./DataFileEdit";
import { DataFileModeTabber } from "./DataFileModeTabber";
import { DataFileSearchByExample } from "./DataFileSearchByExample";
import { DataFileSearchByRecordNumber } from "./DataFileSearchByRecordNumber";
import {
    DataFileMenuItemIdentifier,
    DataFileMetadata,
    DataFileMode,
    qbePrefix,
    RecordAbsolutePosition,
    RecordData,
    RecordDirection,
    RecordId,
} from "./DataFileTypes";
import {
    canonicalUrlToDataFile,
    urlToDataFile,
    urlToDataFileAbsolutionPosition,
    urlToDataFileAddRecord,
    urlToDataFileSearchByExample,
    urlToDataFileSearchByRecordNumber,
} from "./urlHelpers";

export const log = createLogger("DataFileContainer");

export type DataFileContainerProps = DataFileMenuItemIdentifier & {
    mode: DataFileMode;
    recordNumber?: number;
    recordId?: string;
    recordDirection?: RecordDirection;
    recordAbsolutePosition?: RecordAbsolutePosition;
};

const getExampleAndRecordTypeFromQueryString: (
    search: string
) => { example: Record<string, string>; recordType: string } | undefined = () => {
    const { search } = window.location;
    if (search?.length === 0) {
        return undefined;
    }
    const query = queryString.parse(window.location.search);
    const { recordType } = query;
    if (typeof recordType !== "string") {
        return undefined;
    }
    const example = Object.keys(query)
        .filter((key) => key.startsWith(qbePrefix))
        .reduce((acc, next) => ({ ...acc, [next.substring(qbePrefix.length)]: query[next] }), {});
    return Object.keys(example).length === 0 ? undefined : { example, recordType };
};

export const DataFileContainer: React.FC<DataFileContainerProps> = (props) => {
    const { dfuName, fileName, mode, recordNumber, recordId, recordDirection, recordAbsolutePosition } = props;
    const { search } = window.location;

    const [metadata, setMetadata] = useState<DataFileMetadata>();
    const [data, setData] = useState<RecordData>();
    const { startSpinner } = useSpinner();

    const dfuId = metadata?.dfuId;
    const fileId = metadata?.fileId;
    const { authorizedFetch } = useAuthorizedFetch();
    const { setSnack } = useSetSnack();
    const showError = useShowError();

    const setValues = useCallback(
        (values: Values) =>
            setData((prevData) => {
                if (prevData === undefined) {
                    showError("Cannot display latest datafile updates, because previous record is unset.");
                    return prevData;
                }
                return { ...prevData, values };
            }),
        [setData, showError]
    );

    const recordExampleAndType = useMemo(() => getExampleAndRecordTypeFromQueryString(search), [search]);

    const history = useHistory();

    useEffect(() => {
        (async function loadMetadata() {
            const apiUrl = `/dfu/${dfuName}/${fileName}`;
            const stopSpinner = startSpinner(apiUrl);
            try {
                try {
                    const response = await authorizedFetch(apiUrl);
                    const result = await response.json();
                    const metadata = convertToDataFileMetadata(dfuName, fileName, result);
                    setMetadata(metadata);
                } catch (ex) {
                    showError(ex);
                }
            } finally {
                stopSpinner();
            }
        })();
    }, [dfuName, fileName, authorizedFetch, showError, startSpinner]);

    useEffect(() => {
        (async function loadData() {
            if (!dfuId || !fileId || mode !== DataFileMode.Edit) {
                return;
            }
            const urlDoesNotIdentifyARecord =
                recordNumber === undefined &&
                recordId === undefined &&
                recordDirection === undefined &&
                recordAbsolutePosition === undefined &&
                recordExampleAndType === undefined;
            if (urlDoesNotIdentifyARecord) {
                // TODO: When the requested data file is empty, user should be kicked over to "Add" mode.
                const firstRecordUrl = urlToDataFileAbsolutionPosition({
                    dfuName,
                    fileName,
                    recordAbsolutePosition: "firstRecord",
                });
                log("urlDoesNotIdentifyARecord - navigating to first record");
                history.replace(firstRecordUrl);
                return;
            }
            const urlIdentifiesASpecificRecordAndItIsLoaded =
                recordId !== undefined && recordDirection === undefined && data?.recordId === recordId;
            if (urlIdentifiesASpecificRecordAndItIsLoaded) {
                return;
            }

            const query =
                recordNumber !== undefined
                    ? { recordNumber }
                    : recordId !== undefined
                    ? { recordId, direction: recordDirection }
                    : recordExampleAndType !== undefined
                    ? { recordType: recordExampleAndType.recordType }
                    : { position: recordAbsolutePosition || "firstRecord" };
            const apiUrl = `/dfu/${dfuId}/${fileId}?${queryString.stringify(query)}`;
            const stopSpinner = startSpinner(apiUrl);
            try {
                const body = recordExampleAndType?.example || {};
                const response = await authorizedFetch(apiUrl, {
                    method: "post",
                    body: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (response.status === 204) {
                    log("recieved no-content response");
                    history.replace(urlToDataFileSearchByRecordNumber({ dfuName, fileName: fileName }));
                } else {
                    const result = await response.json();
                    const recordData = convertToRecordData(result);
                    setData(recordData);
                    const needCanonical = recordId === undefined || recordDirection !== undefined;
                    if (needCanonical) {
                        const url = canonicalUrlToDataFile({
                            dfuName: dfuName,
                            fileName: fileName,
                            recordId: result.recordId || "unknown-id",
                        });
                        log("replacing url with canonical", url);
                        history.replace(url);
                    }
                }
            } finally {
                stopSpinner();
            }
        })();
    }, [
        dfuName,
        dfuId,
        fileId,
        recordId,
        recordNumber,
        recordDirection,
        recordAbsolutePosition,
        recordExampleAndType,
        authorizedFetch,
        data,
        fileName,
        mode,
        startSpinner,
        history,
    ]);

    // Every mode requires metadata, so bail if we don't have it yet.
    if (!metadata) {
        return null;
    }

    const handleClickEdit = () => history.push(urlToDataFile({ dfuName, fileName }));
    const handleClickSearchByRecordNumber = () =>
        history.push(urlToDataFileSearchByRecordNumber({ dfuName, fileName }));
    const handleClickSearchByExample = () => history.push(urlToDataFileSearchByExample({ dfuName, fileName }));
    const handleClickAdd = () => history.push(urlToDataFileAddRecord({ dfuName, fileName }));

    const tabberProps = {
        mode,
        onClickEdit: handleClickEdit,
        onClickSearchByRecordNumber: handleClickSearchByRecordNumber,
        onClickSearchByExample: handleClickSearchByExample,
        onClickAdd: handleClickAdd,
    };

    if (mode === DataFileMode.SearchByRecordNumber) {
        const handleSubmit = (recordNumber: number) => {
            history.push(urlToDataFile({ dfuName, fileName, recordNumber }));
        };
        const handleMoveFirst = () => {
            history.push(urlToDataFile({ dfuName, fileName, recordAbsolutePosition: "firstRecord" }));
        };
        const handleMoveLast = () => {
            history.push(urlToDataFile({ dfuName, fileName, recordAbsolutePosition: "lastRecord" }));
        };

        return (
            <DataFileModeTabber {...tabberProps}>
                <div style={{ flexGrow: 1 }}>
                    <DataFileSearchByRecordNumber
                        onSubmit={handleSubmit}
                        onMoveFirst={handleMoveFirst}
                        onMoveLast={handleMoveLast}
                    ></DataFileSearchByRecordNumber>
                </div>
            </DataFileModeTabber>
        );
    }

    if (mode === DataFileMode.SearchByExample) {
        const handleSubmit = (recordMetadataId: string, example: Record<string, string>) =>
            history.push(urlToDataFileSearchByExample({ dfuName, fileName }, recordMetadataId, example));

        const handleMoveFirst = () =>
            history.push(urlToDataFile({ dfuName, fileName, recordAbsolutePosition: "firstRecord" }));

        const handleMoveLast = () =>
            history.push(urlToDataFile({ dfuName, fileName, recordAbsolutePosition: "lastRecord" }));

        return (
            <DataFileModeTabber {...tabberProps}>
                <div style={{ flexGrow: 1 }}>
                    <DataFileSearchByExample
                        recordMetadatas={metadata.recordMetadata}
                        onSubmit={handleSubmit}
                        onMoveFirst={handleMoveFirst}
                        onMoveLast={handleMoveLast}
                    ></DataFileSearchByExample>
                </div>
            </DataFileModeTabber>
        );
    }

    /**
     * TODO: Refactor this after the api is updated to always return a recordId (and maybe the whole record).
     * @param response response from the append-record-to-data-file api.
     */
    const getNewRecordId: (response: Response) => Promise<string | void> = async (response: Response) => {
        if (response.status === 200) {
            const body: { recordId?: string } = await response.json();
            return body.recordId;
        }
    };

    const saveData: (props: {
        recordId?: RecordId;
        recordMetadataId?: string;
        values: Values;
    }) => Promise<void> = async (props) => {
        const { recordId, recordMetadataId, values } = props;
        if (!recordMetadataId) {
            showError("Cannot save, because recordMetadataId is not set.");
            return;
        }

        setSnack(`Saving ${dfuName}, ${fileName}...`);
        const qs = queryString.stringify({ recordId, recordType: recordMetadataId });
        const apiUrl = `/dfu/${metadata.dfuId}/${metadata.fileId}/post?${qs}`;
        const stopSpinner = startSpinner(apiUrl);
        try {
            try {
                const response = await authorizedFetch(apiUrl, {
                    method: "POST",
                    body: JSON.stringify(values),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (response.status < 200 || response.status >= 300) {
                    showError(ErrorMessage.FailedToSaveDataFile, response);
                } else {
                    setSnack(`Saved ${dfuName}, ${fileName}.`);

                    const isEdit = recordId !== undefined;
                    if (isEdit) {
                        // This will cause the DataFileEdit component to rerender and reset the Formik dirty flag.
                        setValues(values);
                    } else {
                        const newRecordId = await getNewRecordId(response);
                        if (newRecordId) {
                            history.push(canonicalUrlToDataFile({ dfuName, fileName, recordId: newRecordId }));
                        } else {
                            // TODO: Refactor this after the api is updated to always return a recordId (and maybe the whole record).
                            history.push(
                                urlToDataFileAbsolutionPosition({
                                    dfuName,
                                    fileName,
                                    recordAbsolutePosition: "lastRecord",
                                })
                            );
                        }
                    }
                }
            } catch (ex) {
                showError(ex);
            }
        } finally {
            stopSpinner();
        }
    };

    if (mode === DataFileMode.Add) {
        const handleSubmit = (recordMetadataId: string, values: Record<string, string>) => {
            saveData({ recordMetadataId, values });
        };

        return (
            <DataFileModeTabber {...tabberProps}>
                <DataFileAdd recordMetadatas={metadata.recordMetadata} onSubmit={handleSubmit} />
            </DataFileModeTabber>
        );
    }

    const recordMetadata =
        data === undefined ? undefined : metadata.recordMetadata.find((rm) => rm.recordType === data?.recordType);
    if (data !== undefined && recordMetadata === null) {
        throw new Error(`Could not find metadata for recordType ${data.recordType}`);
    }

    if (!(recordMetadata && metadata && data)) {
        return <DataFileModeTabber {...tabberProps}></DataFileModeTabber>;
    }

    const { keyFields, fields } = recordMetadata;
    const { values } = data;

    const handleMoveNext: (values?: Values) => Promise<void> = async (values) => {
        const recordLocator = data === undefined ? { recordId, recordNumber } : { recordId: data.recordId };
        if (values) {
            if (!recordId) {
                showError("Cannot save, because recordId is not set.");
                return;
            }
            await saveData({
                values,
                recordId,
                recordMetadataId: recordMetadata.recordType,
            });
        }
        history.push(urlToDataFile({ dfuName, fileName, ...recordLocator, recordDirection: "next" }));
    };

    const handleMovePrevious: (values?: Values) => Promise<void> = async (values) => {
        if (values) {
            if (!recordId) {
                showError("Cannot save, because recordId is not set.");
                return;
            }
            await saveData({
                values,
                recordId,
                recordMetadataId: recordMetadata.recordType,
            });
        }
        history.push(urlToDataFile({ dfuName, fileName, recordId, recordNumber, recordDirection: "previous" }));
    };

    const handleSave: (values: Values) => Promise<void> = async (values) => {
        if (!recordId) {
            showError("Cannot save, because recordId is not set.");
            return;
        }
        await saveData({
            values,
            recordId,
            recordMetadataId: recordMetadata.recordType,
        });
    };

    return (
        <DataFileModeTabber {...tabberProps}>
            <DataFileEdit
                mode={DataFileMode.Edit}
                dataFile={dfuName}
                file={fileName}
                recordMetadataId={data.recordType}
                recordId={recordId}
                keyFields={keyFields || []}
                fields={fields || []}
                values={values || {}}
                onMoveNext={handleMoveNext}
                onMovePrevious={handleMovePrevious}
                onSave={handleSave}
            />
        </DataFileModeTabber>
    );
};
