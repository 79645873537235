import SendFilledIcon from "@material-ui/icons/Send";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import SendSharpIcon from "@material-ui/icons/SendSharp";
import SendTwoToneIcon from "@material-ui/icons/SendTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const sendIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return SendFilledIcon;
        case IconStyle.Outlined:
            return SendOutlinedIcon;
        case IconStyle.Rounded:
            return SendRoundedIcon;
        case IconStyle.TwoTone:
            return SendTwoToneIcon;
        case IconStyle.Sharp:
            return SendSharpIcon;
    }
};

export const SendIcon = createIconComponent(sendIconSelector);
