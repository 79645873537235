import PrintFilledIcon from "@material-ui/icons/Print";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import PrintSharpIcon from "@material-ui/icons/PrintSharp";
import PrintTwoToneIcon from "@material-ui/icons/PrintTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const printIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return PrintFilledIcon;
        case IconStyle.Outlined:
            return PrintOutlinedIcon;
        case IconStyle.Rounded:
            return PrintRoundedIcon;
        case IconStyle.TwoTone:
            return PrintTwoToneIcon;
        case IconStyle.Sharp:
            return PrintSharpIcon;
    }
};

export const PrintIcon = createIconComponent(printIconSelector);
