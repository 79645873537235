import { makeStyles } from "@material-ui/core";
import {
    TimelineConnector,
    TimelineContent,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from "@material-ui/lab";
import * as React from "react";
import { ReactNode } from "react";

export const useStyles = makeStyles((_theme) => ({
    collapsedTimelineOppositeContent: {
        flex: 0,
        padding: 0,
    },
}));

const CollapsedTimelineItemFC: React.VFC<{
    timelineDot: ReactNode;
    timelineContent: ReactNode;
    noConnector?: boolean;
}> = ({ timelineDot, timelineContent, noConnector }) => {
    const classes = useStyles();

    return (
        <TimelineItem>
            <TimelineOppositeContent className={classes.collapsedTimelineOppositeContent} />
            <TimelineSeparator>
                {timelineDot}
                {!noConnector && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>{timelineContent}</TimelineContent>
        </TimelineItem>
    );
};

export const CollapsedTimelineItem = React.memo(CollapsedTimelineItemFC);
