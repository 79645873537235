import { lazy } from "react";

export const ApiDashboard = lazy(() => import("./ApiDashboard"));
export const ChecklistCreationParameterPage = lazy(() => import("./ChecklistCreationParameterPage"));
export const ChecklistCreationTemplatePickerPage = lazy(() => import("./ChecklistCreationTemplatePickerPage"));
export const ChecklistDetailPage = lazy(() => import("./ChecklistDetailPage"));
export const DataFilePage = lazy(() => import("./DataFilePage"));
export const Explorer = lazy(() => import("./Explorer"));
export const Help = lazy(() => import("./Help"));
export const Home = lazy(() => import("./Home"));
export const HubPage = lazy(() => import("./HubPage"));
export const MenuListPage = lazy(() => import("./MenuListPage"));
export const MenuPage = lazy(() => import("./MenuPage"));
export const ProcedurePage = lazy(() => import("./ProcedurePage"));
export const Profile = lazy(() => import("./Profile"));
export const ScreenListPage = lazy(() => import("./ScreenListPage"));
export const ScreenPage = lazy(() => import("./ScreenPage"));
export const ReportListPage = lazy(() => import("./ReportListPage"));
export const ReportPage = lazy(() => import("./ReportPage"));
