import { useMemo } from "react";
import * as React from "react";
import { useUsers, WebApiUser } from "./Api";
import { FilterListIcon, ArrowDropDownIcon } from "./Icons";
import { SplitButton, SplitButtonOptionProps, SplitButtonProps } from "./SplitButton";

type UserPickerProps = Omit<SplitButtonProps, "options"> & {
    /** Label that represents "no user" was explicitly chosen.  If undefined, "no user" is not an available option. */
    noUserDisplayName?: string;
    /** Style as a filter. */
    isFilter?: boolean;
    /** Callback when the user clicks on a user. */
    onClick(user: WebApiUser | null): void;
};

function compareUsersMeFirstThenAlphabetical(myUserId?: string) {
    return function (a: WebApiUser, b: WebApiUser) {
        if (a.id === b.id) {
            return 0;
        }
        if (myUserId !== undefined && a.id === myUserId) {
            return -1;
        }
        if (myUserId !== undefined && b.id === myUserId) {
            return 1;
        }
        return a.name > b.name ? 1 : -1;
    };
}

/** A split button used to pick a single user. */
export const UserPicker: React.VFC<UserPickerProps> = (props) => {
    const { noUserDisplayName, onClick, isFilter, ...splitButtonProps } = props;
    const { data: allUsers } = useUsers();
    const { data: selfUsers } = useUsers("self");

    return useMemo(() => {
        const selfUser = selfUsers?.[0];
        const users =
            allUsers
                ?.map((user) => (user.id === selfUser?.id ? { ...user, name: "Me" } : user))
                .sort(compareUsersMeFirstThenAlphabetical(selfUser?.id)) || [];
        const userOptions = users.map<SplitButtonOptionProps>((user) => ({
            value: user.name,
            onClick: () => {
                const actualUserWithoutTheMeJunk = user.id === selfUser?.id ? selfUser : user;
                onClick(actualUserWithoutTheMeJunk);
            },
        }));
        const options =
            noUserDisplayName !== undefined
                ? [
                      {
                          value: noUserDisplayName,
                          onClick: () => {
                              onClick(null);
                          },
                      },
                      ...userOptions,
                  ]
                : userOptions;
        return (
            <SplitButton
                startIcon={isFilter ? <FilterListIcon /> : undefined}
                endIcon={isFilter ? undefined : <ArrowDropDownIcon />}
                {...splitButtonProps}
                options={options}
            />
        );
    }, [selfUsers, allUsers, noUserDisplayName, isFilter, splitButtonProps, onClick]);
};
