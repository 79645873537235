import queryString from "query-string";
import { useCallback } from "react";
import { QueryObserverResult, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSpinnerEffect } from "../SpinnerContext";
import { useAuthorizedFetch } from "./AuthorizedFetch";
import { PagedResult } from "./PagedResult";
import { parsePagedResultReportInfo } from "./parsePagedResultReportInfo";
import { OrderByDirection, ReportInfo, ReportListParameters } from "./types";

function apiUrl({
    pageNumber,
    pageSize,
    orderBy: originalOrderBy,
    orderDirection,
    createdByUserId,
}: ReportListParameters) {
    const orderBy =
        originalOrderBy === undefined
            ? undefined
            : `${originalOrderBy}${orderDirection === OrderByDirection.Descending ? "Desc" : ""}`;
    const qs = queryString.stringify({ pageNumber, pageSize, createdByUserId, orderBy });
    return `reports${qs ? `?${qs}` : ""}`;
}

function useFetchReportListQueryFn() {
    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();

    const fetchReportListQueryFn = useCallback(
        async (apiUrl: string) => {
            const response = await authorizedFetch(apiUrl);
            if (response.status >= 200) {
                const json = await response.text();
                try {
                    return parsePagedResultReportInfo(json);
                } catch (ex) {
                    showError(ErrorMessage.FailedToParseReportList, response);
                    throw ex;
                }
            } else {
                showError(ErrorMessage.FailedToLoadReportList, response);
                throw new Error(ErrorMessage.FailedToLoadReportList);
            }
        },
        [authorizedFetch, showError]
    );

    return { fetchReportListQueryFn };
}

export function useReportList(
    parameters: ReportListParameters,
    queryOptions?: UseQueryOptions<PagedResult<ReportInfo>>
): QueryObserverResult<PagedResult<ReportInfo>, unknown> {
    const { fetchReportListQueryFn } = useFetchReportListQueryFn();

    const url = apiUrl(parameters);
    const query = useQuery([url], () => fetchReportListQueryFn(url), queryOptions);

    useSpinnerEffect(query.isLoading, url);

    return query;
}

export function useFetchReportList(
    queryOptions?: UseQueryOptions<PagedResult<ReportInfo>>
): { fetchReportList(parameters: ReportListParameters): Promise<PagedResult<ReportInfo>> } {
    const { fetchReportListQueryFn } = useFetchReportListQueryFn();
    const queryCache = useQueryClient();

    const fetchReportList = useCallback(
        (parameters: ReportListParameters) => {
            const url = apiUrl(parameters);
            return queryCache.fetchQuery([url], () => fetchReportListQueryFn(url), queryOptions);
        },
        [fetchReportListQueryFn, queryCache, queryOptions]
    );

    return {
        fetchReportList,
    };
}
