import { atom } from "recoil";
import { MenuContent } from "./MenuContext";
import { sessionStorageKeys } from "./sessionStorageKeys";
import { SnackState } from "./SnackState";
import { cleanseUserSettings } from "./UserSettings";

const atomKeys = {
    snack: "snack",
    mainMenuOpen: "mainMenuOpen",
    userSettings: "userSettings",
    menuTree: "menuTree",
};

export const snackState = atom<SnackState | undefined>({
    key: atomKeys.snack,
    default: undefined,
});

function readStorage<T>(storage: Storage, key: string, defaultValue: T) {
    try {
        const item = storage.getItem(key);
        if (item !== null) {
            const result = JSON.parse(item) as T;
            return result;
        }
        // eslint-disable-next-line no-empty
    } catch {}
    return defaultValue;
}

export const mainMenuOpenState = atom({
    key: atomKeys.mainMenuOpen,
    default: readStorage(sessionStorage, sessionStorageKeys.mainMenuOpen, true),
});

const defaultUserSettings = cleanseUserSettings(undefined);

export const userSettingsState = atom({
    key: atomKeys.userSettings,
    default: readStorage(sessionStorage, sessionStorageKeys.userSettings, defaultUserSettings),
});

/**
 * Menus organized by library name and menu name.  Root (first) level properties are named for libraries.  Second level
 * properties are named for menus.  The objects in that second level are menu definitions with library name and menu
 * name omitted.  For example:
 *
 * ```
 * {
 *   "utlibr": {
 *     "utb": { title: "Utilities Billing", items: [...] }
 *   }
 * }
 * ```
 */
export type MenuTree = Record<string, Record<string, MenuContent>>;

export const defaultMenuTree: MenuTree = {};

export const menuTreeState = atom({
    key: atomKeys.menuTree,
    default: readStorage(sessionStorage, sessionStorageKeys.menuTree, defaultMenuTree),
});
