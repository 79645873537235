import { makeStyles, Typography } from "@material-ui/core";
import { useField } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import RichTextEditor from "react-rte";
import { ChecklistParameterEditorProps } from "./ChecklistParameterEditorProps";

export const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(2),
    },
}));

export const HtmlChecklistParameterEditor: React.FC<ChecklistParameterEditorProps<string>> = ({
    label,
    name,
    autoFocus,
}) => {
    const [, { value }, { setValue }] = useField(name);
    const [rteValue, setRteValue] = useState(
        value ? RichTextEditor.createValueFromString(value, "html") : RichTextEditor.createEmptyValue()
    );
    const classes = useStyles();

    useEffect(() => {
        const html = rteValue.toString("html");
        if (value !== html) {
            setValue(html);
        }
    }, [rteValue, setValue, value]);

    return (
        <div className={classes.root}>
            <Typography gutterBottom>{label}</Typography>
            <RichTextEditor
                value={rteValue}
                onChange={(value) => {
                    setRteValue(value);
                }}
                autoFocus={autoFocus}
            />
        </div>
    );
};
