/* eslint-disable @typescript-eslint/no-redeclare */
/**
 * This file describes the responses returned from the hub / checklist related apis.
 */

/**
 * Describes the result from calling /checklist-summaries
 *
 * Generated using https://app.quicktype.io?share=0ALhmulu99bP4Shl83Md
 */

// TODO: Consider separate types for scheduled versus unscheduled.
export interface WebApiChecklistSummary {
    checklistId: string;
    checklistName: string;
    isLate: boolean;
    dueDate: Date;
    completedDate?: Date;
    steps: WebApiChecklistSummaryStep[];
    isScheduled: boolean;
    isStarted: boolean;
}

// TODO: Consider separate types for scheduled versus unscheduled.
export interface WebApiChecklistSummaryStep {
    checklistStepId: string;
    isCurrentStep: boolean;
    sequence: number;
    description: string;
    dueDate: Date;
    approvingDepartment: string;
    isLate: boolean;
    assignedToUserId?: string;
    completedByUserId?: string;
}

/**
 * Describes the result from calling /checklists
 *
 * Generated using https://app.quicktype.io?share=uL8Y5WL4fruPfcwbFCvd
 */
// TODO: Consider separate types for scheduled versus unscheduled.
export interface WebApiChecklist {
    id: string;
    createdByUserId: string;
    scheduledByUserId?: string;
    name?: string;
    dateCreated: Date;
    startDate?: Date;
    dueDate?: Date;
    completedDate?: Date;
    currentStepId: string;
    steps: WebApiChecklistStep[];
    parameters: WebApiChecklistTemplateParameter[];
    isLate: boolean;
    isScheduled: boolean;
    isStarted: boolean;
}

// TODO: Consider separate types for scheduled versus unscheduled.
export interface WebApiChecklistStep {
    id: string;
    description?: string;
    sequence: number;
    dueDate?: Date;
    completedDate?: Date;
    completedByUserId?: string;
    assignmentHistory: unknown;
    annotations?: WebApiChecklistStepAnnotation[];
    attachmentDescriptors: WebApiChecklistStepAttachmentDescriptor[];
    approvingDepartment: string;
    assignedToUserId: string;
    isLate: boolean;
    canComplete: boolean;
    canAssign: boolean;
    canAttachItem: boolean;
}

export interface WebApiChecklistStepAnnotation {
    id: string;
    note?: string;
    createdDate: Date;
    createdByUserId: string;
}

export interface WebApiChecklistStepAttachmentDescriptor {
    id: string;
    name: string;
    contentType: string;
    attachedDate: Date;
    attachedByUserId: string;
    attachmentId: string;
}

export interface WebApiChecklistTemplate {
    id: string;
    name: string;
    steps: WebApiChecklistTemplateStep[];
    parameters: WebApiChecklistTemplateParameter[];
    instanceNameMacro: string;
}

export interface WebApiChecklistTemplateStep {
    id: string;
    sequence: number;
    description: string;
    daysFromDueDate: number;
    approvingDepartment: string;
}

export type DateParameterTypeKey = "date";
export const DateParameterTypeKey = "date";

export type StringParameterTypeKey = "string";
export const StringParameterTypeKey = "string";

export type NumberParameterTypeKey = "number";
export const NumberParameterTypeKey = "number";

export type BooleanParameterTypeKey = "boolean";
export const BooleanParameterTypeKey = "boolean";

export type DateListParameterTypeKey = "dateList";
export const DateListParameterTypeKey = "dateList";

export type TextAreaParameterTypeKey = "textArea";
export const TextAreaParameterTypeKey = "textArea";

export type HtmlParameterTypeKey = "html";
export const HtmlParameterTypeKey = "html";

export type WebApiChecklistTemplateParameterTypeKey =
    | DateParameterTypeKey
    | StringParameterTypeKey
    | NumberParameterTypeKey
    | BooleanParameterTypeKey
    | DateListParameterTypeKey
    | TextAreaParameterTypeKey
    | HtmlParameterTypeKey;

type ParameterValueType<T extends WebApiChecklistTemplateParameterTypeKey> = T extends DateParameterTypeKey
    ? Date
    : T extends DateListParameterTypeKey
    ? Date[]
    : T extends NumberParameterTypeKey
    ? number
    : T extends BooleanParameterTypeKey
    ? boolean
    : string;

export interface WebApiChecklistTemplateParameterValueBase<T extends WebApiChecklistTemplateParameterTypeKey> {
    type: T;
    value?: ParameterValueType<T>;
}

export interface WebApiChecklistTemplateParameterBase<T extends WebApiChecklistTemplateParameterTypeKey>
    extends WebApiChecklistTemplateParameterValueBase<T> {
    name: string;
    label: string;
    isUserQueried: boolean;
    isRequired?: boolean;
}

export type WebApiChecklistTemplateParameterValue =
    | WebApiChecklistTemplateParameterValueBase<DateParameterTypeKey>
    | WebApiChecklistTemplateParameterValueBase<StringParameterTypeKey>
    | WebApiChecklistTemplateParameterValueBase<NumberParameterTypeKey>
    | WebApiChecklistTemplateParameterValueBase<BooleanParameterTypeKey>
    | WebApiChecklistTemplateParameterValueBase<DateListParameterTypeKey>
    | WebApiChecklistTemplateParameterValueBase<TextAreaParameterTypeKey>
    | WebApiChecklistTemplateParameterValueBase<HtmlParameterTypeKey>;

export type WebApiChecklistTemplateParameter =
    | WebApiChecklistTemplateParameterBase<DateParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<StringParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<NumberParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<BooleanParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<DateListParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<TextAreaParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<HtmlParameterTypeKey>;
