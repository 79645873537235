import queryString, { StringifiableRecord, StringifyOptions } from "query-string";

export function buildUrlWithQuery(
    relativePath: string,
    object: StringifiableRecord,
    options?: StringifyOptions
): string {
    const qs = queryString.stringify(object, options);
    return `${relativePath}${qs ? `?${qs}` : ""}`;
}
