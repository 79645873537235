import queryString from "query-string";
import { QueryKey, useMutation, useQueryClient } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSetSnack } from "../SnackContext";
import { useAuthorizedFetch } from "./AuthorizedFetch";
import { parseJsonWebApiChecklist } from "./parseJsonWebApiChecklist";

interface Parameters {
    checklistId: string;
    checklistStepId: string;
    assigneeId?: string;
    assigneeName?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useAssignChecklistStepMutation() {
    const queryCache = useQueryClient();

    const mutation = useMutation(assignChecklistStep, {
        onSuccess: (data, mutationVariables) => {
            const queryKey: QueryKey = ["checklist", mutationVariables.checklistId];
            if (data) {
                queryCache.setQueryData(queryKey, data);
            } else {
                queryCache.invalidateQueries(queryKey);
            }
        },
    });

    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();
    const { setSnack } = useSetSnack();

    async function assignChecklistStep({ checklistId, checklistStepId, assigneeId, assigneeName }: Parameters) {
        const qs = queryString.stringify({ userId: assigneeId });
        const apiUrl = `checklists/${checklistId}/steps/${checklistStepId}/assignments${qs ? `?${qs}` : ""}`;
        const response = await authorizedFetch(apiUrl, {
            method: "POST",
        });
        if (response.status >= 200 && response.status < 300) {
            setSnack(assigneeName ? `Assigned step to ${assigneeName}.` : `Assigned step.`);
            const json = await response.text();
            try {
                return parseJsonWebApiChecklist(json);
            } catch (ex) {
                showError(ErrorMessage.FailedToParseChecklist, response);
                throw ex;
            }
        } else {
            showError(ErrorMessage.FailedToAssignStep, response);
        }
    }

    return mutation;
}
