import HelpFilledIcon from "@material-ui/icons/Help";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";
import HelpRoundedIcon from "@material-ui/icons/HelpRounded";
import HelpSharpIcon from "@material-ui/icons/HelpSharp";
import HelpTwoToneIcon from "@material-ui/icons/HelpTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const helpIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return HelpFilledIcon;
        case IconStyle.Outlined:
            return HelpOutlinedIcon;
        case IconStyle.Rounded:
            return HelpRoundedIcon;
        case IconStyle.TwoTone:
            return HelpTwoToneIcon;
        case IconStyle.Sharp:
            return HelpSharpIcon;
    }
};

export const HelpIcon = createIconComponent(helpIconSelector);
