import WarningFilledIcon from "@material-ui/icons/Warning";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import WarningSharpIcon from "@material-ui/icons/WarningSharp";
import WarningTwoToneIcon from "@material-ui/icons/WarningTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const warningIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return WarningFilledIcon;
        case IconStyle.Outlined:
            return WarningOutlinedIcon;
        case IconStyle.Rounded:
            return WarningRoundedIcon;
        case IconStyle.TwoTone:
            return WarningTwoToneIcon;
        case IconStyle.Sharp:
            return WarningSharpIcon;
    }
};

export const WarningIcon = createIconComponent(warningIconSelector);
