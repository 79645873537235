import { makeStyles } from "@material-ui/core";

export const useMenuItemStyles = makeStyles(() => ({
    mainMenuItem: {
        textTransform: "uppercase",
    },
    emphasized: {
        fontWeight: "bolder",
    },
    disabled: {
        textDecoration: "line-through",
        fontWeight: "lighter",
    },
    button: {},
    emphasizedButton: {
        fontWeight: "bolder",
        fontStyle: "italic",
    },
    disabledButton: {},
    green: {},
    emphasizedGreen: {
        fontWeight: "bolder",
        fontStyle: "italic",
    },
    disabledGreen: {
        fontWeight: "lighter",
        color: "rgb(11, 173, 20)",
    },
    link: {},
    emphasizedLink: {
        fontWeight: "bolder",
        fontStyle: "italic",
    },
    disabledLink: {},
}));
