import queryString from "query-string";
import * as React from "react";
import { QueryKey, useMutation, useQueryClient } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSetSnack } from "../SnackContext";
import { useAuthorizedFetch } from "./AuthorizedFetch";
import { parseJsonWebApiChecklist } from "./parseJsonWebApiChecklist";

interface Parameters {
    checklistId: string;
    checklistStepId: string;
    checklistStepName?: string;
    file: File;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useAttachFileToChecklistStepMutation() {
    const queryCache = useQueryClient();

    const mutation = useMutation(attachFileToChecklistStep, {
        onSuccess: (data) => {
            if (!data) {
                return;
            }
            const queryKey: QueryKey = ["checklist", data.id];
            queryCache.setQueryData(queryKey, data);
        },
    });

    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();
    const { setSnack } = useSetSnack();

    async function attachFileToChecklistStep({ checklistId, checklistStepId, checklistStepName, file }: Parameters) {
        const qs = queryString.stringify({ name: file.name });
        const apiUrl = `checklists/${checklistId}/steps/${checklistStepId}/attachments?${qs}`;
        const formData = new FormData();
        formData.append("content", file);
        const response = await authorizedFetch(apiUrl, {
            method: "POST",
            body: formData,
        });
        if (response.status >= 200 && response.status < 300) {
            setSnack(checklistStepName ? <>Attached file: &ldquo;{file.name}&rdquo;.</> : `Attached file.`);
            const json = await response.text();
            try {
                return parseJsonWebApiChecklist(json);
            } catch (ex) {
                showError(ErrorMessage.FailedToParseChecklist, response);
                throw ex;
            }
        } else {
            showError("Failed to attach file.", response);
        }
    }

    return mutation;
}
