import { SvgIconProps } from "@material-ui/core/SvgIcon/SvgIcon";
import * as React from "react";
import { useUserSettings } from "../Api";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const IconContainer: React.FC<
    {
        selector: IconSelector;
    } & SvgIconProps
> = ({ selector, ...props }) => {
    const { iconStyle } = useUserSettings();
    return <Icon {...{ selector, iconStyle, ...props }} />;
};

export const Icon: React.FC<
    {
        iconStyle: IconStyle;
        selector: IconSelector;
    } & SvgIconProps
> = ({ selector, iconStyle, ...props }) => {
    const Icon = selector(iconStyle);
    return <Icon {...props} />;
};
