import { Button, createStyles, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import * as React from "react";
import { useState } from "react";
import { formatDateTime } from "../formatDate";
import { GuestbookEntry } from "./types";

/** Use the styling system provided by the material-ui react component library. */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        guestbook: {
            // Nicely space out all direct children.
            "& > *": {
                marginRight: theme.spacing(2),
                marginBottom: theme.spacing(2),
            },
        },
        // Some of these are just placeholders now for components I expect to style soon.
        title: {},
        entry: {},
        form: {
            "& > *": {
                marginRight: theme.spacing(2),
                marginBottom: theme.spacing(2),
            },
        },
        signatureInput: {
            width: "40ch",
        },
    })
);

/** Component used to render an existing guestbook entry. */
const EntryComponent: React.FC<GuestbookEntry> = ({ visitorName, createDate }) => {
    const classes = useStyles();
    return (
        <div className={classes.entry}>
            <Typography variant="body1" gutterBottom>
                Guest name: {visitorName}
            </Typography>
            <Typography variant="body2">Visited: {formatDateTime(createDate)}</Typography>
        </div>
    );
};

/** Component used to add a new guestbook entry. */
const AddEntryComponent: React.FC<{ onSaveSignature(visitorName: string): void }> = ({ onSaveSignature }) => {
    const [visitorName, setVisitorName] = useState("");
    const classes = useStyles();

    /** Persist signature changes to this component's react state. */
    function handleSignatureChange(event: React.ChangeEvent<HTMLInputElement>): void {
        setVisitorName(event.currentTarget.value);
    }

    /** Save a new signature by invoking a method property passed to this component from its parent. */
    function handleSaveSignatureClick() {
        console.log("[GuestbookNewEntryComponent]", "GB Saving signature", visitorName);
        if (visitorName) {
            onSaveSignature(visitorName);
        }
        setVisitorName("");
    }

    return (
        <form noValidate autoComplete="off" className={classes.form} onSubmit={handleSaveSignatureClick}>
            <TextField
                label="Enter your signature here"
                value={visitorName}
                onChange={handleSignatureChange}
                className={classes.signatureInput}
                autoFocus
            />
            <div>
                <Button
                    variant="contained"
                    startIcon={<CreateIcon />}
                    color="primary"
                    onClick={handleSaveSignatureClick}
                >
                    Save signature
                </Button>
            </div>
        </form>
    );
};

/**
 * Presentational component for the guestbook.
 */
export const Guestbook: React.FC<{ entries: GuestbookEntry[] } & { onSaveSignature(visitorName: string): void }> = ({
    entries,
    onSaveSignature,
}) => {
    const classes = useStyles();
    const [isNewEntryVisible, setIsNewEntryVisible] = useState(false);

    function handleAddMySignatureClick() {
        setIsNewEntryVisible(true);
    }

    function handleSaveSignature(visitorName: string) {
        console.log("[Guestbook]", "Saving signature", visitorName);

        if (visitorName) {
            onSaveSignature(visitorName);
        }
        setIsNewEntryVisible(false);
    }

    return (
        <div className={classes.guestbook}>
            <Typography variant="h5" className={classes.title}>
                Guestbook
            </Typography>
            {entries.map((entry, index) => (
                <EntryComponent {...entry} key={index} />
            ))}
            {!isNewEntryVisible && (
                <>
                    <div>
                        <Button
                            variant="contained"
                            startIcon={<CreateIcon />}
                            color="primary"
                            onClick={handleAddMySignatureClick}
                        >
                            Add my signature
                        </Button>
                    </div>
                </>
            )}
            {isNewEntryVisible && <AddEntryComponent onSaveSignature={handleSaveSignature} />}
        </div>
    );
};

Guestbook.whyDidYouRender = true;
