import ZoomInFilledIcon from "@material-ui/icons/ZoomIn";
import ZoomInOutlinedIcon from "@material-ui/icons/ZoomInOutlined";
import ZoomInRoundedIcon from "@material-ui/icons/ZoomInRounded";
import ZoomInSharpIcon from "@material-ui/icons/ZoomInSharp";
import ZoomInTwoToneIcon from "@material-ui/icons/ZoomInTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const zoomInIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return ZoomInFilledIcon;
        case IconStyle.Outlined:
            return ZoomInOutlinedIcon;
        case IconStyle.Rounded:
            return ZoomInRoundedIcon;
        case IconStyle.TwoTone:
            return ZoomInTwoToneIcon;
        case IconStyle.Sharp:
            return ZoomInSharpIcon;
    }
};

export const ZoomInIcon = createIconComponent(zoomInIconSelector);
