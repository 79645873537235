import AppsFilledIcon from "@material-ui/icons/Apps";
import AppsOutlinedIcon from "@material-ui/icons/AppsOutlined";
import AppsRoundedIcon from "@material-ui/icons/AppsRounded";
import AppsSharpIcon from "@material-ui/icons/AppsSharp";
import AppsTwoToneIcon from "@material-ui/icons/AppsTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const appsIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return AppsFilledIcon;
        case IconStyle.Outlined:
            return AppsOutlinedIcon;
        case IconStyle.Rounded:
            return AppsRoundedIcon;
        case IconStyle.TwoTone:
            return AppsTwoToneIcon;
        case IconStyle.Sharp:
            return AppsSharpIcon;
    }
};

export const AppsIcon = createIconComponent(appsIconSelector);
