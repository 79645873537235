import queryString from "query-string";

const convertToLocalStorageVariableName = (name: string) =>
    `TwinOak.Debug.${name.substr(0, 1).toUpperCase()}${name.substr(1)}`;

function ensureLocalStorageVariableExists(name: string): void {
    const variableName = convertToLocalStorageVariableName(name);
    if (localStorage.getItem(variableName) === null) {
        localStorage.setItem(variableName, "false");
    }
}

function isLocalStorageVariableTrue(name: string) {
    const variableName = convertToLocalStorageVariableName(name);
    const variableValue = localStorage.getItem(variableName);
    return variableValue === true.toString();
}

function queryParameterExistsIgnoreCase(name: string) {
    const query = queryString.parse(window.location.search);
    const parameters = Object.keys(query);
    const hasParameter = parameters.some((p) => p.toLowerCase() === name.toLowerCase());
    return hasParameter;
}

function isFlagEnabled(name: string) {
    return isLocalStorageVariableTrue(name) || queryParameterExistsIgnoreCase(name);
}

// http://localhost:3000/?whyDidYouRender

interface DiagnosticFeatureFlags {
    whyDidYouRender: boolean;
    reactQueryDevtools: boolean;
    reactQueryDisableRefetchOnWindowFocus: boolean;
    logSpinReasons: boolean;
    logMsal: boolean;
    logDateTimePicker: boolean;
    mockChecklistCreation: boolean;
}

/**
 * For all diagnostic feature flags, the default is false.  This is the behavior expected in production.  Setting a
 * flag to true indicates you want more diagnostics or a more developer friendly configuration.
 *
 * Why create an object where all the values are false?  It simplifies the implementation of buildFeatureFlagObject.
 */
const defaultDiagnosticFeatureFlags = {
    whyDidYouRender: false,
    reactQueryDevtools: false,
    reactQueryDisableRefetchOnWindowFocus: false,
    logSpinReasons: false,
    logMsal: false,
    logDateTimePicker: false,
    mockChecklistCreation: false,
};

/**
 * Read feature flag settings from local storage and the current url.  The values are only ever read once when the app
 * starts.  A flag is enabled if either of the following are true:
 *
 * - a query parameter exists with the same name as the flag (case-insensitive), or
 * - a local storage variable exists named TwinOak.Debug.{flag name pascal case} is set to the exact string "true".
 *
 * The naming of the local storage variables is a weird vanity thing, so let's initialize them all to "false" so it's
 * easier for a developer to find them.
 */
function buildFeatureFlagObject(): DiagnosticFeatureFlags {
    const flagNames = Object.keys(defaultDiagnosticFeatureFlags).map((x) => x as keyof DiagnosticFeatureFlags);
    flagNames.forEach((k) => ensureLocalStorageVariableExists(k));
    const flagKvps = flagNames.map((name) => ({ name, value: isFlagEnabled(name) }));
    flagKvps.filter((f) => f.value).forEach((f) => console.log(`Diagnostic feature enabled: ${f.name}`));
    return flagKvps.reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {}) as DiagnosticFeatureFlags;
}

export const diagnosticFeatureFlags = buildFeatureFlagObject();
