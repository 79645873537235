import { SvgIconProps } from "@material-ui/core";
import * as React from "react";
import { useUserSettings } from "../Api";
import {
    AddIcon,
    AlarmIcon,
    CheckBoxOutlineBlankIcon,
    CheckBoxOutlinedIcon,
    DirectionsRunIcon,
    DoneAllIcon,
    DoneIcon,
    EventIcon,
    FlagIcon,
    HourglassEmptyIcon,
    PanToolIcon,
    PriorityHighIcon,
    SendIcon,
} from "../Icons";
import {
    ChecklistStepCompletedIconEnum,
    ChecklistStepInProgressIconEnum,
    ChecklistStepNotReadyIconEnum,
    ChecklistStepWaitingForAssignmentIconEnum,
} from "../UserSettings";

export const ChecklistStepAllCompletedIconContainer = DoneAllIcon;

export const ChecklistStepCompletedIconContainer: React.FC<SvgIconProps> = (props) => {
    const { checklistStepCompletedIcon } = useUserSettings();
    return <ChecklistStepCompletedIcon {...{ checklistStepCompletedIcon, ...props }} />;
};

export const ChecklistStepCompletedIcon: React.FC<
    { checklistStepCompletedIcon: ChecklistStepCompletedIconEnum } & SvgIconProps
> = ({ checklistStepCompletedIcon, ...props }) => {
    let Icon: React.FC<SvgIconProps>;

    switch (checklistStepCompletedIcon) {
        default:
        case ChecklistStepCompletedIconEnum.OneCheck:
            Icon = DoneIcon;
            break;
        case ChecklistStepCompletedIconEnum.CheckedCheckbox:
            Icon = CheckBoxOutlinedIcon;
            break;
    }

    return <Icon {...props} />;
};

export const ChecklistStepInProgressIconContainer: React.FC<SvgIconProps> = (props) => {
    const { checklistStepInProgressIcon } = useUserSettings();
    return <ChecklistStepInProgressIcon {...{ checklistStepInProgressIcon, ...props }} />;
};

export const ChecklistStepInProgressIcon: React.FC<
    { checklistStepInProgressIcon: ChecklistStepInProgressIconEnum } & SvgIconProps
> = ({ checklistStepInProgressIcon, ...props }) => {
    let Icon: React.FC<SvgIconProps>;

    switch (checklistStepInProgressIcon) {
        default:
        case ChecklistStepInProgressIconEnum.RunningPerson:
            Icon = DirectionsRunIcon;
            break;
        case ChecklistStepInProgressIconEnum.ExclationPoint:
            Icon = PriorityHighIcon;
            break;
        case ChecklistStepInProgressIconEnum.PaperAirplane:
            Icon = SendIcon;
            break;
    }

    return <Icon {...props} />;
};

export const ChecklistStepWaitingForPrerequisitesIconContainer: React.FC<SvgIconProps> = (props) => {
    const { checklistStepNotReadyIcon } = useUserSettings();
    return <ChecklistStepWaitingForPrerequisitesIcon {...{ checklistStepNotReadyIcon, ...props }} />;
};

export const ChecklistStepWaitingForPrerequisitesIcon: React.FC<
    { checklistStepNotReadyIcon: ChecklistStepNotReadyIconEnum } & SvgIconProps
> = ({ checklistStepNotReadyIcon, ...props }) => {
    let Icon: React.FC<SvgIconProps>;

    switch (checklistStepNotReadyIcon) {
        default:
        case ChecklistStepNotReadyIconEnum.UncheckedCheckbox:
            Icon = CheckBoxOutlineBlankIcon;
            break;
    }

    return <Icon {...props} />;
};

export const ChecklistStepWaitingForAssignmentIconContainer: React.FC<SvgIconProps> = (props) => {
    const { checklistStepWaitingForAssignmentIcon } = useUserSettings();

    return <ChecklistStepWaitingForAssignmentIcon {...{ checklistStepWaitingForAssignmentIcon, ...props }} />;
};

export const ChecklistStepWaitingForAssignmentIcon: React.FC<
    { checklistStepWaitingForAssignmentIcon: ChecklistStepWaitingForAssignmentIconEnum } & SvgIconProps
> = ({ checklistStepWaitingForAssignmentIcon, ...props }) => {
    let Icon: React.FC<SvgIconProps>;

    switch (checklistStepWaitingForAssignmentIcon) {
        default:
        case ChecklistStepWaitingForAssignmentIconEnum.ExclamationPoint:
            Icon = PriorityHighIcon;
            break;
        case ChecklistStepWaitingForAssignmentIconEnum.Hourglass:
            Icon = HourglassEmptyIcon;
            break;
        case ChecklistStepWaitingForAssignmentIconEnum.AlarmClock:
            Icon = AlarmIcon;
            break;
        case ChecklistStepWaitingForAssignmentIconEnum.Hand:
            Icon = PanToolIcon;
            break;
    }

    return <Icon {...props} />;
};

export const ChecklistStepCreatedIconContainer = AddIcon;

export const ChecklistStepWaitingForScheduleIconContainer = EventIcon;

export const ChecklistStepWaitingForStartIconContainer = FlagIcon;
