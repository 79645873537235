import { useCallback } from "react";
import { QueryObserverResult, useQuery, UseQueryOptions } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSpinnerEffect } from "../SpinnerContext";
import { useAuthorizedFetch } from "./AuthorizedFetch";
import { buildUrlWithQuery } from "./buildUrlWithQuery";
import { DataFileSource } from "./DataFileSource";

function apiUrl(fileSource: DataFileSource) {
    return buildUrlWithQuery("files", { fileSource });
}

export function useDataFileList(
    fileSource: DataFileSource,
    queryOptions?: UseQueryOptions<string[]>
): QueryObserverResult<string[]> {
    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();

    const getDataFiles = useCallback(
        async (fileSource: DataFileSource) => {
            const url = apiUrl(fileSource);
            const response = await authorizedFetch(url);
            if (response.status >= 200 && response.status < 300) {
                return (await response.json()) as string[];
            } else {
                showError(ErrorMessage.FailedToLoadDataFileList, response);
                throw new Error(ErrorMessage.FailedToLoadDataFileList);
            }
        },
        [authorizedFetch, showError]
    );

    const url = apiUrl(fileSource);
    const query = useQuery(["file-list", fileSource], () => getDataFiles(fileSource), {
        staleTime: 1 * 60 * 1000,
        ...queryOptions,
    });

    useSpinnerEffect(query.isLoading, url);

    return query;
}
