import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Box,
    Link,
    makeStyles,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TimelineDot from "@material-ui/lab/TimelineDot";
import * as React from "react";
import { SyntheticEvent, useCallback, useEffect, useRef } from "react";
import { useUserSettings } from "../../Api";
import { ExpandMoreIcon } from "../../Icons";
import { createLogger } from "../../log";
import { ChecklistStepIconColors } from "../../UserSettings";
import { ChecklistStepTimelineItemProps, isExpandableProps, TemporalPosition } from "../ChecklistTypes";
import { LateBadge } from "../LateBadge";
import { ChecklistCurrentStepStatus, ChecklistOnlyStatus, ChecklistStepStatus } from "../Status";
import { ThematicColor } from "../ThematicColor";
import { BadgeForScheduling } from "./BadgeForScheduling";
import { ChecklistStepTitle } from "./ChecklistStepTitle";
import { CollapsedTimelineItem } from "./CollapsedTimelineItem";

export const log = createLogger("ChecklistStepTimelineItem");

export const useStyles = makeStyles((theme) => ({
    timelineDotContainer: {
        marginTop: "8px",
    },
    timelineDotGrey: {
        backgroundColor: theme.palette.grey.A200,
    },
    timelineDotSuccess: {
        backgroundColor: theme.palette.success.dark,
    },
    timelineDotInfo: {
        backgroundColor: theme.palette.info.dark,
    },
    stepDoneTitle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: "6px 16px",
    },
    accordion: {
        display: "block",
    },
    accordionSummary: {},
    accordionDetails: {
        flexDirection: "column",
        textAlign: "left",
    },
    accordionActions: {},
    [ThematicColor.Success]: {
        backgroundColor: theme.palette.success.dark,
    },
    [ThematicColor.Grey]: {
        backgroundColor: theme.palette.grey.A200,
    },
    [ThematicColor.Info]: {
        backgroundColor: theme.palette.info.dark,
    },
    [ThematicColor.Warning]: {
        backgroundColor: theme.palette.warning.dark,
    },
    [ThematicColor.Error]: {
        backgroundColor: theme.palette.error.dark,
    },
}));

export function useGetChecklistStepIconColor(): (options: {
    checklistStepStatus: ChecklistStepStatus;
    temporalPosition: TemporalPosition;
}) => ThematicColor {
    const { checklistStepIconColors } = useUserSettings();
    return useCallback(
        ({ checklistStepStatus, temporalPosition }) => {
            const mustBeGrey =
                (checklistStepIconColors === ChecklistStepIconColors.OnlyCurrentStepHasColor &&
                    temporalPosition !== TemporalPosition.Present) ||
                (checklistStepIconColors === ChecklistStepIconColors.CurrentStepAndCompletedStepsHaveColor &&
                    temporalPosition === TemporalPosition.Future);
            if (mustBeGrey) {
                return ThematicColor.Grey;
            }

            const mustBeWarning =
                temporalPosition === TemporalPosition.Present &&
                checklistStepStatus === ChecklistCurrentStepStatus.StepWaitingForAssignment;
            if (mustBeWarning) {
                return ThematicColor.Warning;
            }

            if (
                checklistStepStatus === ChecklistOnlyStatus.ChecklistCreated ||
                temporalPosition === TemporalPosition.Past ||
                (temporalPosition !== TemporalPosition.Future &&
                    checklistStepStatus === ChecklistOnlyStatus.ChecklistCompleted)
            ) {
                return ThematicColor.Success;
            } else if (temporalPosition === TemporalPosition.Future) {
                return ThematicColor.Grey;
            } else {
                return ThematicColor.Info;
            }
        },
        [checklistStepIconColors]
    );
}

const ChecklistStepTimelineItemFC: React.FC<ChecklistStepTimelineItemProps> = (props) => {
    const {
        checklistStepStatus,
        temporalPosition,
        icon,
        needsScheduling,
        isLate,
        title,
        subtitle,
        scrollIntoView,
        noConnector,
        isChecklistScheduled,
    } = props;
    const getChecklistStepIconColor = useGetChecklistStepIconColor();
    const classes = useStyles();
    const timelineDotClassName = classes[getChecklistStepIconColor({ checklistStepStatus, temporalPosition })];

    const Icon = icon;

    const currentStepRef = useRef<HTMLElement>(null);
    useEffect(
        function scrollIntoViewEffect() {
            if (scrollIntoView) {
                // log("scrolling into view", currentStepRef.current);
                currentStepRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                });
            }
        },
        [scrollIntoView, currentStepRef]
    );

    const stepTitle = (
        <ChecklistStepTitle
            title={title}
            subtitle={subtitle}
            ref={currentStepRef}
            useTabularLayout={!isChecklistScheduled}
        />
    );
    const timelineContent = !isExpandableProps(props) ? (
        <Paper elevation={3} className={classes.stepDoneTitle}>
            {stepTitle}
        </Paper>
    ) : (
        <Accordion
            expanded={props.expanded}
            className={classes.accordion}
            onChange={() => props.setExpanded(!props.expanded)}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
                {stepTitle}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>{props.expandedContent}</AccordionDetails>
            {props.actionsContent !== undefined && (
                <AccordionActions className={classes.accordionActions}>{props.actionsContent}</AccordionActions>
            )}
        </Accordion>
    );

    return (
        <CollapsedTimelineItem
            timelineDot={
                <Box className={classes.timelineDotContainer}>
                    <LateBadge isLate={isLate} childType="ChecklistStepIcon">
                        <BadgeForScheduling needsScheduling={needsScheduling === true}>
                            <Link
                                href="#"
                                onClick={(event: SyntheticEvent) => {
                                    event.preventDefault();
                                    isExpandableProps(props) && props.setExpanded(!props.expanded);
                                }}
                            >
                                <TimelineDot className={timelineDotClassName}>
                                    <Icon />
                                </TimelineDot>
                            </Link>
                        </BadgeForScheduling>
                    </LateBadge>
                </Box>
            }
            timelineContent={timelineContent}
            noConnector={noConnector}
        />
    );
};

export const ChecklistStepTimelineItem = React.memo(ChecklistStepTimelineItemFC);
