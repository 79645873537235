import * as React from "react";
import { diagnosticFeatureFlags } from "./diagnosticFeatureFlags";

/** Load why-did-you-render if the diagnostic feature flag is enabled. */
export async function setupWdyr(): Promise<void> {
    if (diagnosticFeatureFlags.whyDidYouRender) {
        const whyDidYouRender = await import("@welldone-software/why-did-you-render");
        whyDidYouRender.default(React, {
            trackAllPureComponents: true,
        });
    }
}
