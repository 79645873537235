import { Divider } from "@material-ui/core";
import * as React from "react";
import { useMemo } from "react";
import { useChecklistSummaries, useUserSettings } from "../../Api";
import { convertRefetchIntervalToQueryConfig } from "../../Api/convertRefetchIntervalToQueryConfig";
import { convertChecklistSummaryApiResultToProps } from "../convertApiResultToProps";
import { ChecklistSummaryList } from "./ChecklistSummaryList";
import { ChecklistSummaryListItem } from "./ChecklistSummaryListItem";
import { HubGridItem } from "./HubGrid";

interface InboxContainerProps {
    title: string;
    apiUrl: string;
    colorIndex: number;
}

export const InboxContainer: React.FC<InboxContainerProps> = ({ title, colorIndex, apiUrl }) => {
    const { hubPageRefetchIntervalInSeconds } = useUserSettings();
    const queryConfig = convertRefetchIntervalToQueryConfig(hubPageRefetchIntervalInSeconds);
    const { data } = useChecklistSummaries(apiUrl, queryConfig);

    return useMemo(
        () => (
            <HubGridItem>
                <ChecklistSummaryList colorIndex={colorIndex} title={title}>
                    {data?.map((webApiChecklistSummary, index) => (
                        <React.Fragment key={index}>
                            {index !== 0 ? <Divider component="li" variant="inset" /> : <></>}

                            <ChecklistSummaryListItem
                                {...convertChecklistSummaryApiResultToProps(webApiChecklistSummary)}
                            ></ChecklistSummaryListItem>
                        </React.Fragment>
                    ))}
                </ChecklistSummaryList>
            </HubGridItem>
        ),
        [title, colorIndex, data]
    );
};
