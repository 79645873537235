import NotificationsActiveFilledIcon from "@material-ui/icons/NotificationsActive";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import NotificationsActiveRoundedIcon from "@material-ui/icons/NotificationsActiveRounded";
import NotificationsActiveSharpIcon from "@material-ui/icons/NotificationsActiveSharp";
import NotificationsActiveTwoToneIcon from "@material-ui/icons/NotificationsActiveTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const notificationsActiveIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return NotificationsActiveFilledIcon;
        case IconStyle.Outlined:
            return NotificationsActiveOutlinedIcon;
        case IconStyle.Rounded:
            return NotificationsActiveRoundedIcon;
        case IconStyle.TwoTone:
            return NotificationsActiveTwoToneIcon;
        case IconStyle.Sharp:
            return NotificationsActiveSharpIcon;
    }
};

export const NotificationsActiveIcon = createIconComponent(notificationsActiveIconSelector);
