import { useStorageState } from "react-storage-hooks";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { mainMenuOpenState } from "./atoms";
import { sessionStorageKeys } from "./sessionStorageKeys";

export function useMainMenuOpen(): { mainMenuOpen: boolean } {
    const mainMenuOpen = useRecoilValue(mainMenuOpenState);
    return { mainMenuOpen };
}

export function useSetMainMenuOpen(): { setMainMenuOpen: (mainMenuOpen: boolean) => void } {
    const [cache, setCache] = useStorageState<boolean>(sessionStorage, sessionStorageKeys.mainMenuOpen, true);
    const setMainMenuOpen = useRecoilCallback(
        ({ set }) => (mainMenuOpen: boolean) => {
            set(mainMenuOpenState, mainMenuOpen);
            if (mainMenuOpen !== cache) {
                setCache(mainMenuOpen);
            }
        },
        [setCache]
    );
    return {
        setMainMenuOpen,
    };
}
