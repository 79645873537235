import { ChecklistCurrentStepStatus, ChecklistOnlyStatus } from "./Status";

export function getChecklistStatusDisplayString(
    checklistStatus: ChecklistOnlyStatus | ChecklistCurrentStepStatus
): string {
    switch (checklistStatus) {
        default:
        case ChecklistOnlyStatus.ChecklistCreated:
            return "Created";

        case ChecklistOnlyStatus.ChecklistWaitingForSchedule:
            return "Schedule pending";

        case ChecklistOnlyStatus.ChecklistWaitingForStart:
            return "Start pending";

        case ChecklistCurrentStepStatus.StepWaitingForAssignment:
            return "Assignment pending";

        case ChecklistCurrentStepStatus.StepInProgress:
            return "In progress";

        case ChecklistOnlyStatus.ChecklistCompleted:
            return "Completed";
    }
}
