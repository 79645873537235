import { formatDateTime } from "../formatDate";
import {
    ChecklistStepTimelineItemProps,
    isPseudoStepData,
    PseudoStepData,
    StepData,
    TemporalPosition,
} from "./ChecklistTypes";
import { getChecklistStepIcon } from "./getChecklistStepIcon";
import { getStepStatusOfChecklistStepProp } from "./getStepStatusOfChecklistStepProp";

export const convertStepDataToDisplayProps: (options: {
    checklistDueDate?: Date;
    isChecklistScheduled: boolean;
    currentStepId: string;
    step: StepData | PseudoStepData;
}) => ChecklistStepTimelineItemProps = ({ checklistDueDate, currentStepId, isChecklistScheduled, step }) =>
    isPseudoStepData(step)
        ? {
              isChecklistScheduled: isChecklistScheduled,
              checklistStepStatus: getStepStatusOfChecklistStepProp(step),
              needsScheduling: false,
              checklistStepId: step.checklistStepId,
              temporalPosition:
                  currentStepId === step.checklistStepId
                      ? TemporalPosition.Present
                      : step.completedDate !== undefined
                      ? TemporalPosition.Past
                      : TemporalPosition.Future,
              icon: getChecklistStepIcon(getStepStatusOfChecklistStepProp(step)),
              isLate: checklistDueDate !== undefined && (step.completedDate || new Date()) > checklistDueDate,
              title: step.completedDate && step.completedName ? step.completedName : step.name,
              subtitle:
                  step.subtitle !== undefined
                      ? step.subtitle
                      : step.completedDate
                      ? `on ${formatDateTime(step.completedDate)}`
                      : null,
          }
        : {
              isChecklistScheduled: isChecklistScheduled,
              checklistStepStatus: getStepStatusOfChecklistStepProp(step),
              needsScheduling: !isChecklistScheduled && step.dueDate === undefined,
              checklistStepId: step.checklistStepId,
              temporalPosition:
                  currentStepId === step.checklistStepId
                      ? TemporalPosition.Present
                      : step.completedDate !== undefined
                      ? TemporalPosition.Past
                      : TemporalPosition.Future,
              icon: getChecklistStepIcon(getStepStatusOfChecklistStepProp(step)),
              isLate: step.isLate,
              title: step.name,
              subtitle: "",
          };
