import PanToolFilledIcon from "@material-ui/icons/PanTool";
import PanToolOutlinedIcon from "@material-ui/icons/PanToolOutlined";
import PanToolRoundedIcon from "@material-ui/icons/PanToolRounded";
import PanToolSharpIcon from "@material-ui/icons/PanToolSharp";
import PanToolTwoToneIcon from "@material-ui/icons/PanToolTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const panToolIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return PanToolFilledIcon;
        case IconStyle.Outlined:
            return PanToolOutlinedIcon;
        case IconStyle.Rounded:
            return PanToolRoundedIcon;
        case IconStyle.TwoTone:
            return PanToolTwoToneIcon;
        case IconStyle.Sharp:
            return PanToolSharpIcon;
    }
};

export const PanToolIcon = createIconComponent(panToolIconSelector);
