import { Checkbox, FormControlLabel } from "@material-ui/core";
import * as React from "react";
import { ChecklistParameterEditorProps } from "./ChecklistParameterEditorProps";

type BooleanChecklistParameterEditorProps = ChecklistParameterEditorProps<boolean>;

export const BooleanChecklistParameterEditor: React.FC<BooleanChecklistParameterEditorProps> = ({
    label,
    value,
    name,
    onChange,
}) => {
    return <FormControlLabel control={<Checkbox value={value} onChange={onChange} name={name} />} label={label} />;
};
