import FeedbackFilledIcon from "@material-ui/icons/Feedback";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import FeedbackRoundedIcon from "@material-ui/icons/FeedbackRounded";
import FeedbackSharpIcon from "@material-ui/icons/FeedbackSharp";
import FeedbackTwoToneIcon from "@material-ui/icons/FeedbackTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const feedbackIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return FeedbackFilledIcon;
        case IconStyle.Outlined:
            return FeedbackOutlinedIcon;
        case IconStyle.Rounded:
            return FeedbackRoundedIcon;
        case IconStyle.TwoTone:
            return FeedbackTwoToneIcon;
        case IconStyle.Sharp:
            return FeedbackSharpIcon;
    }
};

export const FeedbackIcon = createIconComponent(feedbackIconSelector);
