import ChevronRightFilledIcon from "@material-ui/icons/ChevronRight";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import ChevronRightSharpIcon from "@material-ui/icons/ChevronRightSharp";
import ChevronRightTwoToneIcon from "@material-ui/icons/ChevronRightTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const chevronRightIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return ChevronRightFilledIcon;
        case IconStyle.Outlined:
            return ChevronRightOutlinedIcon;
        case IconStyle.Rounded:
            return ChevronRightRoundedIcon;
        case IconStyle.TwoTone:
            return ChevronRightTwoToneIcon;
        case IconStyle.Sharp:
            return ChevronRightSharpIcon;
    }
};

export const ChevronRightIcon = createIconComponent(chevronRightIconSelector);
