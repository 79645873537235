import { QueryClient } from "react-query";
import { diagnosticFeatureFlags } from "../diagnosticFeatureFlags";

const refetchOnWindowFocus = !diagnosticFeatureFlags.reactQueryDisableRefetchOnWindowFocus;

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus,
        },
    },
});
