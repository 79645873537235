export enum ChecklistOnlyStatus {
    ChecklistCreated = "ChecklistCreated",
    ChecklistWaitingForSchedule = "ChecklistWaitingForSchedule",
    ChecklistWaitingForStart = "ChecklistWaitingForStart",
    ChecklistCompleted = "ChecklistCompleted",
}

export enum ChecklistCurrentStepStatus {
    StepWaitingForAssignment = "StepWaitingForAssignment",
    StepInProgress = "StepInProgress",
}

export enum ChecklistNotCurrentStepStatus {
    StepWaitingForPrerequisites = "StepWaitingForPrerequisites",
    StepCompleted = "StepCompleted",
}

export type ChecklistStepStatus = ChecklistOnlyStatus | ChecklistCurrentStepStatus | ChecklistNotCurrentStepStatus;

export type ChecklistStatus = ChecklistOnlyStatus | ChecklistCurrentStepStatus;
