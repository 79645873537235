import MenuFilledIcon from "@material-ui/icons/Menu";
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import MenuSharpIcon from "@material-ui/icons/MenuSharp";
import MenuTwoToneIcon from "@material-ui/icons/MenuTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const menuIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return MenuFilledIcon;
        case IconStyle.Outlined:
            return MenuOutlinedIcon;
        case IconStyle.Rounded:
            return MenuRoundedIcon;
        case IconStyle.TwoTone:
            return MenuTwoToneIcon;
        case IconStyle.Sharp:
            return MenuSharpIcon;
    }
};

export const MenuIcon = createIconComponent(menuIconSelector);
