import { dateTimeReviver } from "../dateTimeReviver";
import { cleanseWebApiChecklist } from "./cleanseWebApiChecklist";
import { WebApiChecklist } from "./WebApiTypes";

const jsonRevivers = dateTimeReviver(["startDate", "dueDate", "completedDate", "dateCreated", "createdDate"]);

export const parseJsonWebApiChecklist: (text: string) => WebApiChecklist = (text) => {
    const parsed = JSON.parse(text, jsonRevivers) as WebApiChecklist;
    const cleansed = cleanseWebApiChecklist(parsed);
    return cleansed;
};
