import DescriptionFilledIcon from "@material-ui/icons/Description";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import DescriptionRoundedIcon from "@material-ui/icons/DescriptionRounded";
import DescriptionSharpIcon from "@material-ui/icons/DescriptionSharp";
import DescriptionTwoToneIcon from "@material-ui/icons/DescriptionTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const descriptionIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return DescriptionFilledIcon;
        case IconStyle.Outlined:
            return DescriptionOutlinedIcon;
        case IconStyle.Rounded:
            return DescriptionRoundedIcon;
        case IconStyle.TwoTone:
            return DescriptionTwoToneIcon;
        case IconStyle.Sharp:
            return DescriptionSharpIcon;
    }
};

export const DescriptionIcon = createIconComponent(descriptionIconSelector);
