import {
    WebApiChecklist,
    WebApiChecklistStep,
    WebApiChecklistStepAnnotation,
    WebApiChecklistStepAttachmentDescriptor,
    WebApiChecklistSummary,
} from "../Api/WebApiTypes";
import { createLogger } from "../log";
import { urlToChecklist } from "./checklistRouting";
import {
    ChecklistProps,
    ChecklistSummaryListItemProps,
    CompleteChecklistSummaryListItemProps,
    IncompleteChecklistSummaryListItemProps,
    StepData,
} from "./ChecklistTypes";
import {
    PseudoChecklistCompletedStepId,
    PseudoChecklistScheduleStepId,
    PseudoChecklistStartStepId,
} from "./Detail/PseudoChecklistStepIds";
import { getStepStatusOfChecklistSummary } from "./getStepStatusOfChecklistSummary";

export const log = createLogger("convertApiResultToProps");

/**
 * Functions that convert web api results to component props.
 */

/**
 * Convert a checklist summary returned from the web api to a props object used by a component.
 * @param checklistSummary a checklist summary returned from the web api
 */
export const convertChecklistSummaryApiResultToProps: (
    checklistSummary: WebApiChecklistSummary
) => ChecklistSummaryListItemProps = (checklistSummary) => {
    const { checklistId, checklistName, completedDate, dueDate, isLate } = checklistSummary;
    const linkTo = urlToChecklist(checklistId);
    const base = {
        checklistName,
        linkTo,
        dueDate,
        isLate,
    };
    const checklistStatus = getStepStatusOfChecklistSummary(checklistSummary);
    if (completedDate !== undefined) {
        const props: CompleteChecklistSummaryListItemProps = {
            ...base,
            checklistStatus,
            completedDate,
        };
        // log("convertChecklistSummaryApiResultToProps", { input: checklistSummary, output: props });
        return props;
    } else {
        const currentStepDescription = checklistSummary.steps[0].description;
        const currentStepAssignee = checklistSummary.steps[0].assignedToUserId;
        const props: IncompleteChecklistSummaryListItemProps = {
            ...base,
            checklistStatus,
            currentStepDescription,
            currentStepAssignee,
        };
        // log("convertChecklistSummaryApiResultToProps", { input: checklistSummary, output: props });
        return props;
    }
};

/**
 * Convert a checklist returned from the web api to a props object used by a component.
 * @param checklist a checklist returned from the web api
 */
export const convertChecklistApiResultToProps: (checklist: WebApiChecklist) => ChecklistProps = (checklist) => {
    const { currentStepId: originalCurrentStepId } = checklist;

    // TODO: Remove this workaround that copes with invalid values for currentStepId.
    const currentStepId = !checklist.isStarted ? undefined : originalCurrentStepId;

    return {
        checklistId: checklist.id,
        createdByUserId: checklist.createdByUserId,
        scheduledByUserId: checklist.scheduledByUserId,
        currentStepId: !checklist.isScheduled
            ? PseudoChecklistScheduleStepId
            : !checklist.isStarted
            ? PseudoChecklistStartStepId
            : checklist.completedDate !== undefined
            ? PseudoChecklistCompletedStepId
            : checklist.currentStepId,
        name: checklist.name,
        createDate: checklist.dateCreated,
        startDate: checklist.startDate,
        completedDate: checklist.completedDate,
        dueDate: checklist.dueDate,
        isScheduled: checklist.isScheduled,
        isStarted: checklist.isStarted,
        parameters: checklist.parameters || [],
        steps: checklist.steps.map(
            (step: WebApiChecklistStep): StepData => ({
                checklistId: checklist.id,
                checklistStepId: step.id,
                needsScheduling: !checklist.isScheduled && step.dueDate === undefined,
                isCurrentStep: step.id === currentStepId,
                name: step.description || "",
                dueDate: step.dueDate,
                completedDate: step.completedDate,
                assignedToUserId: step.assignedToUserId,
                completedByUserId: step.completedByUserId,
                annotations: (step.annotations || []).map((a) => cleanseWebApiChecklistStepAnnotation(a)),
                department: step.approvingDepartment,
                attachments: (step.attachmentDescriptors || []).map((a) =>
                    cleanseWebApiChecklistStepAttachmentDescriptor(a)
                ),
                isLate: step.isLate,
                canComplete: step.canComplete,
                canAssign: step.canAssign,
                canAttachItem: step.canAttachItem,
            })
        ),
    } as ChecklistProps;
};

const defaultDate = new Date("2020-1-1");

const defaultWebApiChecklistStepAnnotation: WebApiChecklistStepAnnotation = {
    id: "missing-annotation-id",
    createdDate: defaultDate,
    createdByUserId: "missing-annotation-createdByUserId",
    note: "",
};

const cleanseWebApiChecklistStepAnnotation: (
    data: WebApiChecklistStepAnnotation | Record<string, unknown>
) => WebApiChecklistStepAnnotation = (data) => ({
    ...defaultWebApiChecklistStepAnnotation,
    ...data,
});

const defaultWebApiChecklistStepAttachmentDescriptor: WebApiChecklistStepAttachmentDescriptor = {
    id: "",
    attachmentId: "",
    name: "FakeReportName",
    contentType: "text/plain",
    attachedDate: defaultDate,
    attachedByUserId: "",
};

const cleanseWebApiChecklistStepAttachmentDescriptor: (
    dirty: WebApiChecklistStepAttachmentDescriptor | Record<string, unknown>
) => WebApiChecklistStepAttachmentDescriptor = (dirty) => {
    const filled = {
        ...defaultWebApiChecklistStepAttachmentDescriptor,
        ...dirty,
    };
    const { id, attachmentId, name, contentType, attachedDate, attachedByUserId } = filled;
    const filledAndPruned = {
        id,
        attachmentId,
        name,
        contentType,
        attachedDate,
        attachedByUserId,
    };

    if (dirty !== undefined && JSON.stringify(dirty) !== JSON.stringify(filledAndPruned)) {
        console.warn("cleanseWebApiChecklistStepAttachmentDescriptor", { before: dirty, after: filledAndPruned });
    }

    return filledAndPruned;
};
