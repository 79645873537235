import { makeStyles, Paper, Tab, Tabs, Theme } from "@material-ui/core";
import { TabsProps } from "@material-ui/core/Tabs";
import * as React from "react";
import { ChangeEvent } from "react";
import { useUserSettings } from "../Api";
import { DataFileUtilityTabOrientation } from "../UserSettings";
import { DataFileMode } from "./DataFileTypes";

interface DataFileModeTabberProps {
    mode: DataFileMode;
    onClickEdit(): void;
    onClickSearchByExample(): void;
    onClickSearchByRecordNumber(): void;
    onClickAdd(): void;
}

const useStyles = makeStyles((theme: Theme) => ({
    horizontalRoot: {
        display: "flex",
        flexDirection: "column",
        gap: `${theme.spacing(3)}px`,
    },
    horizontalTabsContainer: {},
    horizontalTabs: {},
    horizontalDataFile: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    verticalRoot: {
        display: "flex",
        flexGrow: 1,
        gap: `${theme.spacing(3)}px`,
    },
    verticalTabsContainer: {
        flexGrow: 0,
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        height: 224,
    },
    verticalTabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    verticalTabsWrapper: {
        whiteSpace: "nowrap",
    },
    verticalDataFile: {
        width: "100%",
    },
}));

const dfuTabLabels = ["Edit", "Go to record #", "Search", "Add"];

export const DataFileModeTabber: React.FC<DataFileModeTabberProps> = ({
    mode,
    onClickEdit,
    onClickSearchByRecordNumber,
    onClickSearchByExample,
    onClickAdd,
    children,
}) => {
    const { dataFileUtilityTabOrientation: tabOrientation } = useUserSettings();
    const classes = useStyles();
    if (tabOrientation === DataFileUtilityTabOrientation.Hidden) {
        return <>{children}</>;
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
        const newMode = newValue as DataFileMode;
        if (mode === newMode) {
            return;
        }
        switch (newMode) {
            case DataFileMode.Edit:
                onClickEdit();
                break;
            case DataFileMode.SearchByRecordNumber:
                onClickSearchByRecordNumber();
                break;
            case DataFileMode.SearchByExample:
                onClickSearchByExample();
                break;
            case DataFileMode.Add:
                onClickAdd();
                break;
            default:
                throw new Error(`Unrecognized DataFileMode value in handleTabChange: ${newValue}`);
        }
    };

    const tabsProps: TabsProps =
        tabOrientation === DataFileUtilityTabOrientation.Vertical
            ? {
                  orientation: "vertical",
                  variant: "scrollable",
                  className: classes.verticalTabs,
              }
            : {
                  centered: true,
                  indicatorColor: "primary",
                  textColor: "primary",
                  className: classes.horizontalTabs,
              };
    const tabProps =
        tabOrientation === DataFileUtilityTabOrientation.Vertical
            ? {
                  classes: {
                      wrapper: classes.verticalTabsWrapper,
                  },
              }
            : {};

    const workspaceClassName =
        tabOrientation === DataFileUtilityTabOrientation.Vertical ? classes.verticalRoot : classes.horizontalRoot;
    const tabPaperClassName =
        tabOrientation === DataFileUtilityTabOrientation.Vertical
            ? classes.verticalTabsContainer
            : classes.horizontalTabsContainer;
    return (
        <div className={workspaceClassName}>
            <Paper className={tabPaperClassName}>
                <Tabs value={mode} onChange={handleTabChange} {...tabsProps}>
                    {dfuTabLabels.map((s, i) => (
                        <Tab key={i} label={s} {...tabProps} />
                    ))}
                </Tabs>
            </Paper>
            <div
                className={
                    tabOrientation === DataFileUtilityTabOrientation.Vertical
                        ? classes.verticalDataFile
                        : classes.horizontalDataFile
                }
            >
                {children}
            </div>
        </div>
    );
};
