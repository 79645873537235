import { DataFileMetadata, FieldMetadata, KeyFieldMetadata, RecordData } from "./DataFileTypes";

/**
 * Convert web api response models to models used in this app.
 */

interface WebApiDfuMetadata {
    dfuId: string;
    fileId: string;
    recordTypes: WebApiRecordType[];
}

interface WebApiRecordType {
    recordType: string;
    keyFields: WebApiField[];
    fields: WebApiField[];
}

interface WebApiField {
    fieldName: string;
    label: string;
    ordinalPosition: number;
    length: number;
    decimalPlaces: number;
    isNumeric: boolean;
    row: number;
    column: number;
    isOptional: boolean;
    canBeEmpty: boolean;
}

export const convertToKeyFieldMetadata: (field: WebApiField) => KeyFieldMetadata = (kf) => ({
    name: kf.fieldName,
    label: kf.label?.replace(/:$/, ""),
    type: kf.isNumeric ? "number" : "string",
    length: kf.length,
});

export const convertToFieldMetadata: (field: WebApiField) => FieldMetadata = (f) => ({
    name: f.fieldName,
    label: f.label?.replace(/:$/, ""),
    type: f.isNumeric ? "number" : "string",
    length: f.length,
    readonly: false,
    hidden: false,
});

export const convertToDataFileMetadata: (
    dataFile: string,
    file: string,
    metadata: WebApiDfuMetadata
) => DataFileMetadata = (dataFile, file, { dfuId, fileId, recordTypes }) => {
    return {
        dataFile,
        file,
        dfuId,
        fileId,
        recordMetadata: recordTypes.map((rt) => ({
            recordType: rt.recordType,
            keyFields: rt.keyFields
                .sort((a, b) => a.ordinalPosition - b.ordinalPosition)
                .map(convertToKeyFieldMetadata),
            fields: rt.fields.sort((a, b) => a.ordinalPosition - b.ordinalPosition).map(convertToFieldMetadata),
        })),
    };
};

interface WebAPIDfuData {
    recordId: string;
    fileId: string;
    recordType: string;
    fieldValues: FieldValue[];
}

interface FieldValue {
    fieldName: string;
    value: string;
}

export const convertToRecordData: (record: WebAPIDfuData) => RecordData = ({ recordId, recordType, fieldValues }) => ({
    recordId,
    recordType,
    values: fieldValues.reduce((acc, next) => ({ ...acc, [next.fieldName]: next.value }), {}),
});
