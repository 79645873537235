import SaveAltFilledIcon from "@material-ui/icons/SaveAlt";
import SaveAltOutlinedIcon from "@material-ui/icons/SaveAltOutlined";
import SaveAltRoundedIcon from "@material-ui/icons/SaveAltRounded";
import SaveAltSharpIcon from "@material-ui/icons/SaveAltSharp";
import SaveAltTwoToneIcon from "@material-ui/icons/SaveAltTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const saveAltIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return SaveAltFilledIcon;
        case IconStyle.Outlined:
            return SaveAltOutlinedIcon;
        case IconStyle.Rounded:
            return SaveAltRoundedIcon;
        case IconStyle.TwoTone:
            return SaveAltTwoToneIcon;
        case IconStyle.Sharp:
            return SaveAltSharpIcon;
    }
};

export const SaveAltIcon = createIconComponent(saveAltIconSelector);
