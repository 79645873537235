import SentimentVeryDissatisfiedFilledIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentVeryDissatisfiedOutlinedIcon from "@material-ui/icons/SentimentVeryDissatisfiedOutlined";
import SentimentVeryDissatisfiedRoundedIcon from "@material-ui/icons/SentimentVeryDissatisfiedRounded";
import SentimentVeryDissatisfiedSharpIcon from "@material-ui/icons/SentimentVeryDissatisfiedSharp";
import SentimentVeryDissatisfiedTwoToneIcon from "@material-ui/icons/SentimentVeryDissatisfiedTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const sentimentVeryDissatisfiedIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return SentimentVeryDissatisfiedFilledIcon;
        case IconStyle.Outlined:
            return SentimentVeryDissatisfiedOutlinedIcon;
        case IconStyle.Rounded:
            return SentimentVeryDissatisfiedRoundedIcon;
        case IconStyle.TwoTone:
            return SentimentVeryDissatisfiedTwoToneIcon;
        case IconStyle.Sharp:
            return SentimentVeryDissatisfiedSharpIcon;
    }
};

export const SentimentVeryDissatisfiedIcon = createIconComponent(sentimentVeryDissatisfiedIconSelector);
