import * as React from "react";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useAuth } from "../Auth";
import { AppConfigQueryKey } from "./useAppConfigQuery";

export const ClearQueryCacheOnAuthChange: React.FC = ({ children }) => {
    const { isUserLoggedIn } = useAuth();
    const queryClient = useQueryClient();
    useEffect(
        function clearCacheEffect() {
            queryClient.removeQueries({
                predicate: (q) => q.queryKey.length < 1 || q.queryKey[0] !== AppConfigQueryKey,
            });
        },
        [isUserLoggedIn, queryClient]
    );

    return <>{children}</>;
};
