import EventFilledIcon from "@material-ui/icons/Event";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import EventRoundedIcon from "@material-ui/icons/EventRounded";
import EventSharpIcon from "@material-ui/icons/EventSharp";
import EventTwoToneIcon from "@material-ui/icons/EventTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const eventIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return EventFilledIcon;
        case IconStyle.Outlined:
            return EventOutlinedIcon;
        case IconStyle.Rounded:
            return EventRoundedIcon;
        case IconStyle.TwoTone:
            return EventTwoToneIcon;
        case IconStyle.Sharp:
            return EventSharpIcon;
    }
};

export const EventIcon = createIconComponent(eventIconSelector);
