import { ErrorMessage } from "../ErrorMessage";
import { ProblemDetails } from "../ProblemDetails";
import { ApiResponseError } from "./ApiResponseError";

export const createApiResponseError: (
    userMessage: ErrorMessage | string,
    source?: Error | Response
) => Promise<ApiResponseError> = async (userMessage, source) => {
    if (source instanceof Response) {
        try {
            const error = (await source.json()) as ProblemDetails;
            return { _type: "ApiResponseError", userMessage, source: error };
        } catch (ex) {
            console.log("[createApiResponseError]", "Failed to load json from response body.", ex);
        }
    }
    return { _type: "ApiResponseError", userMessage };
};
