import PlayArrowFilledIcon from "@material-ui/icons/PlayArrow";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";
import PlayArrowSharpIcon from "@material-ui/icons/PlayArrowSharp";
import PlayArrowTwoToneIcon from "@material-ui/icons/PlayArrowTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const playArrowIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return PlayArrowFilledIcon;
        case IconStyle.Outlined:
            return PlayArrowOutlinedIcon;
        case IconStyle.Rounded:
            return PlayArrowRoundedIcon;
        case IconStyle.TwoTone:
            return PlayArrowTwoToneIcon;
        case IconStyle.Sharp:
            return PlayArrowSharpIcon;
    }
};

export const PlayArrowIcon = createIconComponent(playArrowIconSelector);
