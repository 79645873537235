import { useCallback } from "react";
import { QueryObserverResult, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSpinnerEffect } from "../SpinnerContext";
import { useAuthorizedFetch } from "./AuthorizedFetch";
import { parseJsonWebApiChecklistSummaries } from "./parseJsonWebApiChecklistSummaries";
import { WebApiChecklistSummary } from "./WebApiTypes";

type Parameters = string;
const useChecklistSummariesQueryFn: () => {
    getChecklistSummaries: (apiUrl: Parameters) => Promise<WebApiChecklistSummary[]>;
} = () => {
    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();

    const getChecklistSummaries = useCallback(
        async (apiUrl: string) => {
            const response = await authorizedFetch(apiUrl);
            if (response.status === 204) {
                return [];
            } else if (response.status >= 200 && response.status < 300) {
                const json = await response.text();
                try {
                    return parseJsonWebApiChecklistSummaries(json);
                } catch (ex) {
                    showError(ErrorMessage.FailedToParseChecklistSummaries, response);
                    throw ex;
                }
            } else {
                showError(ErrorMessage.FailedToLoadChecklistSummaries, response);
                throw new Error(ErrorMessage.FailedToLoadChecklistSummaries);
            }
        },
        [authorizedFetch, showError]
    );

    return { getChecklistSummaries };
};

export function useChecklistSummaries(
    apiUrl: Parameters,
    queryOptions?: UseQueryOptions<WebApiChecklistSummary[]>
): QueryObserverResult<WebApiChecklistSummary[]> {
    const { getChecklistSummaries } = useChecklistSummariesQueryFn();
    const query = useQuery(["checklist-summaries", apiUrl], () => getChecklistSummaries(apiUrl), queryOptions);

    useSpinnerEffect(query.isLoading, apiUrl);

    return query;
}

export function useMyActiveChecklistSummaries(
    queryOptions?: UseQueryOptions<WebApiChecklistSummary[]>
): QueryObserverResult<WebApiChecklistSummary[]> {
    return useChecklistSummaries("checklist-summaries/mylist?filter=notCompleted", queryOptions);
}

export function useChecklistSummariesImperative(
    queryOptions?: UseQueryOptions<WebApiChecklistSummary[], unknown> | undefined
): { queryChecklistSummaries(apiUrl: Parameters): Promise<WebApiChecklistSummary[]> } {
    const { getChecklistSummaries } = useChecklistSummariesQueryFn();
    const queryCache = useQueryClient();

    const queryChecklistSummaries = useCallback(
        (apiUrl) =>
            queryCache.fetchQuery(["checklist-summaries", apiUrl], () => getChecklistSummaries(apiUrl), {
                ...queryOptions,
            }),
        [queryCache, getChecklistSummaries, queryOptions]
    );

    return {
        queryChecklistSummaries,
    };
}
