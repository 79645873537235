import HourglassEmptyFilledIcon from "@material-ui/icons/HourglassEmpty";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import HourglassEmptyRoundedIcon from "@material-ui/icons/HourglassEmptyRounded";
import HourglassEmptySharpIcon from "@material-ui/icons/HourglassEmptySharp";
import HourglassEmptyTwoToneIcon from "@material-ui/icons/HourglassEmptyTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const hourglassEmptyIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return HourglassEmptyFilledIcon;
        case IconStyle.Outlined:
            return HourglassEmptyOutlinedIcon;
        case IconStyle.Rounded:
            return HourglassEmptyRoundedIcon;
        case IconStyle.TwoTone:
            return HourglassEmptyTwoToneIcon;
        case IconStyle.Sharp:
            return HourglassEmptySharpIcon;
    }
};

export const HourglassEmptyIcon = createIconComponent(hourglassEmptyIconSelector);
