/**
 * A character-based position on the screen.  For example, the top left corner of the screen is
 * { row: 24, column: 80 } and the bottom right corner of the screen is { row: 24, column: 80 }.
 */
export interface ScreenCoordinate {
    /**
     * The row number of the screen coordinate.  The top-most (first) row of the page is row number 1.  Rows numbers
     * increase as you move positions down the page.  The bottom-most (last) row of the page is row number 24.
     */
    row: number;
    /**
     * The column number of the screen coordinate.  The left-most (first) column of the page is column number 1.
     * Column numbers increase as you move positions along the page.  The right-most (last) column of the page is
     * column number 80.
     */
    column: number;
}

export enum ScreenControlType {
    Label = "label",
    TextBox = "textBox",
    Hidden = "hidden",
}

export enum ScreenControlDataType {
    Number = "number",
    String = "string",
}

export enum ScreenControlBehavior {
    Password = "password",
    Signed = "signed",
}

/**
 * A read-only label displayed on the screen.  In html terms, this might be a <span> element.
 */
export interface ScreenLabel extends ScreenCoordinate {
    controlType: ScreenControlType.Label;
    /** name of the label */
    name: string;
    /** text of the label */
    text: string;
    /** length of the text.  If the text is actually shorter, then space pad the end.  This is visible in titles. */
    length: number;
    /** style of the label */
    style?: string;
    /** index of data start in output screen buffer */
    outputBufferIndex?: number;
}

export interface ScreenTextBox extends ScreenCoordinate {
    controlType: ScreenControlType.TextBox;
    dataType: ScreenControlDataType;
    behavior?: ScreenControlBehavior;
    /** name of the input */
    name: string;
    /** text of the input */
    text: string;
    /** length (width) of the input */
    length: number;
    /** index of data start in output screen buffer */
    outputBufferIndex?: number;
    /** index of data start in input screen buffer */
    inputBufferIndex?: number;
}

export interface ScreenHiddenInput {
    controlType: ScreenControlType.Hidden;
    name: string;
    text: string;
    length: number;
    inputBufferIndex: number;
}

type ScreenControl = ScreenLabel | ScreenTextBox | ScreenHiddenInput;

export function isScreenLabel(control: ScreenControl): control is ScreenLabel {
    return control.controlType === ScreenControlType.Label;
}

export function isScreenTextBox(control: ScreenControl): control is ScreenTextBox {
    return control.controlType === ScreenControlType.TextBox;
}

export function isScreenHiddenInput(control: ScreenControl): control is ScreenHiddenInput {
    return control.controlType === ScreenControlType.Hidden;
}

export interface ScreenDefinition {
    library: string;
    screen: string;
    commandKeys: string[];
    controls: ScreenControl[];
}

const defaultScreenDefinition: ScreenDefinition = {
    library: "",
    screen: "",
    commandKeys: [],
    controls: [],
};

/**
 * Return a "cleansed" version of the provided screen definition, that
 * - has all the required properties,
 * - has no unexpected properties,
 * - has textBoxes that all have unique "name" property (this really needs to happen before the screen definitions are
 *   uploaded to Azure storage)
 */
export const cleanseScreenDefinition = (
    screenDefinition: ScreenDefinition | Record<string, unknown>
): ScreenDefinition => {
    // fill in missing properties
    const filled = {
        ...defaultScreenDefinition,
        ...screenDefinition,
    };

    // Remove excess properties.
    const { library, screen, controls, commandKeys } = filled;
    const filledAndPruned = { library, screen, controls, commandKeys };

    // Guarantee all textBoxes have unique, not-blank names.  If a name is used multiple times, only the first
    // occurence retains its original name.
    // TODO: After processing is moved to earlier in the process, just throw an exception here instead for some
    // problems.
    const usedNames: string[] = [];
    const textBoxNameReducer: (accumulator: ScreenControl[], value: ScreenControl, index: number) => ScreenControl[] = (
        acc,
        value,
        index
    ) => {
        if (!isScreenTextBox(value)) {
            return [...acc, value];
        }
        const originalName = value.name.trim();
        const name = !originalName
            ? `no-name-${index}`
            : usedNames.indexOf(originalName) !== -1
            ? `conflicting-name-${index}`
            : originalName;
        usedNames.push(name);
        return [...acc, { ...value, name }];
    };
    const filledPrunedAndTextBoxesRenamed = {
        ...filledAndPruned,
        controls: filledAndPruned.controls.reduce(textBoxNameReducer, [] as ScreenTextBox[]),
    };

    if (
        screenDefinition !== undefined &&
        JSON.stringify(screenDefinition) !== JSON.stringify(filledPrunedAndTextBoxesRenamed)
    ) {
        console.warn("fixScreenDefinition", { before: screenDefinition, after: filledPrunedAndTextBoxesRenamed });
    }

    return filledPrunedAndTextBoxesRenamed;
};
