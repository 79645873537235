import { Link } from "@material-ui/core";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { DataFileMenuItemIdentifier } from "./DataFileTypes";
import { urlToDataFile } from "./urlHelpers";

interface DataFileListProps {
    dataFiles: DataFileMenuItemIdentifier[];
}

export const DataFileList: React.FC<DataFileListProps> = ({ dataFiles }) => (
    <>
        {dataFiles.map((dataFile, index) => (
            <p key={index}>
                <Link component={RouterLink} to={urlToDataFile(dataFile)}>
                    {dataFile.dfuName}
                </Link>
            </p>
        ))}
    </>
);
