import * as React from "react";
import { useEffect, useMemo } from "react";
import { useChecklist } from "../../Api";
import { usePageTitleContext } from "../../PageTitleContext";
import { convertChecklistApiResultToProps } from "../convertApiResultToProps";
import { Checklist } from "./ChecklistDetail";

interface ChecklistContainerProps {
    checklistId: string;
}

export const ChecklistContainer: React.FC<ChecklistContainerProps> = ({ checklistId }) => {
    const { data: checklist } = useChecklist(checklistId);
    const checklistProps = useMemo(() => (checklist ? convertChecklistApiResultToProps(checklist) : undefined), [
        checklist,
    ]);
    const { setPageTitle } = usePageTitleContext();

    const checklistName = checklistProps?.name;
    useEffect(() => {
        setPageTitle(`Checklist - ${checklistName || "loading..."}`);
    }, [setPageTitle, checklistName]);

    if (!checklistProps) {
        return null;
    }

    return <Checklist {...checklistProps} />;
};
