import * as React from "react";
import { PropsWithChildren, useEffect } from "react";
import AzureAD, { AuthenticationState } from "react-aad-msal";
import { SecurityScheme, useAppConfig } from "../AppConfig";
import { useMsalAuthProvider } from "./AzureAd";
import { UnsecuredRequireAuth } from "./Unsecured";

const OidcRequireAuth = UnsecuredRequireAuth;

// eslint-disable-next-line @typescript-eslint/ban-types
const AzureADRequireAuthFC: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const msalAuthProvider = useMsalAuthProvider();
    useEffect(() => {
        if (msalAuthProvider.authenticationState === AuthenticationState.Unauthenticated) {
            msalAuthProvider.login();
        }
    }, [msalAuthProvider]);
    return (
        <AzureAD provider={msalAuthProvider} forceLogin={true}>
            {children}
        </AzureAD>
    );
};
export const AzureADRequireAuth = React.memo(AzureADRequireAuthFC);

// eslint-disable-next-line @typescript-eslint/ban-types
const RequireAuthFC: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const { securityScheme } = useAppConfig();
    if (securityScheme === SecurityScheme.Unsecured) {
        return <UnsecuredRequireAuth>{children}</UnsecuredRequireAuth>;
    } else if (securityScheme === SecurityScheme.AzureAD) {
        return <AzureADRequireAuth>{children}</AzureADRequireAuth>;
    } else {
        return <OidcRequireAuth>{children}</OidcRequireAuth>;
    }
};
export const RequireAuth = React.memo(RequireAuthFC);
