import {
    BooleanParameterTypeKey,
    DateListParameterTypeKey,
    DateParameterTypeKey,
    HtmlParameterTypeKey,
    NumberParameterTypeKey,
    StringParameterTypeKey,
    TextAreaParameterTypeKey,
    WebApiChecklistTemplateParameter,
    WebApiChecklistTemplateParameterValue,
} from "./WebApiTypes";

function cleanseParameterValue(param: WebApiChecklistTemplateParameterValue): WebApiChecklistTemplateParameterValue {
    switch (param.type) {
        case StringParameterTypeKey:
        case TextAreaParameterTypeKey:
        case HtmlParameterTypeKey:
        case NumberParameterTypeKey:
        case BooleanParameterTypeKey:
            return param;
        case DateParameterTypeKey:
            return { type: param.type, value: typeof param.value === "string" ? new Date(param.value) : param.value };
        case DateListParameterTypeKey:
            return {
                type: param.type,
                value: Array.isArray(param.value)
                    ? param.value.map((v) => (typeof v === "string" ? new Date(v) : v))
                    : param.value,
            };
    }
}

export function cleanseWebApiChecklistTemplateParameter(
    param: WebApiChecklistTemplateParameter
): WebApiChecklistTemplateParameter {
    return {
        ...param,
        ...cleanseParameterValue(param),
    } as WebApiChecklistTemplateParameter;
}
