import DoneFilledIcon from "@material-ui/icons/Done";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import DoneSharpIcon from "@material-ui/icons/DoneSharp";
import DoneTwoToneIcon from "@material-ui/icons/DoneTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const doneIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return DoneFilledIcon;
        case IconStyle.Outlined:
            return DoneOutlinedIcon;
        case IconStyle.Rounded:
            return DoneRoundedIcon;
        case IconStyle.TwoTone:
            return DoneTwoToneIcon;
        case IconStyle.Sharp:
            return DoneSharpIcon;
    }
};

export const DoneIcon = createIconComponent(doneIconSelector);
