import constate from "constate";
import { Reducer, useCallback, useEffect, useReducer, useRef } from "react";
import { diagnosticFeatureFlags } from "./diagnosticFeatureFlags";
import { createLogger, noopLogger } from "./log";

const log = diagnosticFeatureFlags.logSpinReasons ? createLogger("SpinnerContext") : noopLogger;

interface SpinnerState {
    reasons: symbol[];
}

const initialState: SpinnerState = {
    reasons: [],
};

interface StopSpinner {
    (): void;
}

const SpinnerActionIncrement = "increment";
// eslint-disable-next-line @typescript-eslint/no-redeclare
type SpinnerActionIncrement = "increment";

const SpinnerActionDecrement = "decrement";
// eslint-disable-next-line @typescript-eslint/no-redeclare
type SpinnerActionDecrement = "decrement";

type SpinnerAction = { type: SpinnerActionIncrement | SpinnerActionDecrement; reason: symbol };

const reducer: Reducer<SpinnerState, SpinnerAction> = (state, { type, reason }) => {
    switch (type) {
        case SpinnerActionIncrement:
            if (state.reasons.indexOf(reason) !== -1) {
                // throw new Error(`Cannot add a duplicate spin reason: "${reason.description}"`);
                console.error(`Added a duplicate spin reason: "${reason.description}"`);
            }
            return { reasons: [...state.reasons, reason] };
        case SpinnerActionDecrement:
            if (state.reasons.indexOf(reason) === -1) {
                // throw new Error(`Cannot remove spin reason that is not in the reason list: "${reason}"`);
                console.error(`Tried to remove a spin reason that is not in the reason list: "${reason.description}"`);
            }
            return { reasons: state.reasons.filter((r) => r !== reason) };
        default:
            throw new Error();
    }
};

function incrementReasons(reason: symbol): SpinnerAction {
    return { type: SpinnerActionIncrement, reason };
}

function decrementReasons(reason: symbol): SpinnerAction {
    return { type: SpinnerActionDecrement, reason };
}

const useSpinnerState = (): {
    spinning: boolean;
    /** Imperative control over spinner */
    startSpinner(reason: string): StopSpinner;
    reasons: string[];
} => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { reasons } = state;
    const spinning = reasons.length > 0;
    const startSpinner = useCallback((reason: string): StopSpinner => {
        // log(`startSpinner - ${reason}`);
        const reasonSymbol = Symbol(reason);
        dispatch(incrementReasons(reasonSymbol));
        return () => {
            // log(`stopSpinner - ${reason}`);
            dispatch(decrementReasons(reasonSymbol));
        };
    }, []);
    const reasonMessage = `reasons: ${JSON.stringify(
        reasons.map((reason) => (reason.description === undefined ? "" : reason.description))
    )}`;
    useEffect(
        function logSpinReason() {
            if (diagnosticFeatureFlags.logSpinReasons) {
                log(reasonMessage);
            }
        },
        [reasonMessage]
    );
    return {
        startSpinner,
        spinning,
        reasons: reasons.map((reason) => (reason.description === undefined ? "" : reason.description)),
    };
};

const [SpinnerProvider, useSpinner] = constate(useSpinnerState);

/** Declarative control over spinner */
export const useSpinnerEffect: (spin: boolean, reason: string) => void = (spin, reason) => {
    // spin && log("useSpinnerEffect", { spin, reason });

    const { startSpinner } = useSpinner();
    const stopSpinnerRef = useRef<StopSpinner>();
    useEffect(
        function spinEffect() {
            // log("useSpinnerEffect", { spin, reason });
            function safeStart() {
                if (!stopSpinnerRef.current) {
                    // log("useSpinnerEffect", "safeStart", { spin, reason });
                    stopSpinnerRef.current = startSpinner(reason);
                } else {
                    // log("useSpinnerEffect", "safeStart NOOP", { spin, reason });
                }
            }
            function safeStop() {
                if (stopSpinnerRef.current) {
                    // log("useSpinnerEffect stopping", { spin, reason });
                    stopSpinnerRef.current();
                    stopSpinnerRef.current = undefined;
                } else {
                    // log("useSpinnerEffect stopping NOOP", { spin, reason });
                }
            }
            if (spin) {
                safeStart();
            } else {
                safeStop();
            }

            return () => {
                // log("useSpinnerEffect stopping effect-callback", { spin, reason });
                safeStop();
            };
        },
        [spin, reason, startSpinner, stopSpinnerRef]
    );
};

export { SpinnerProvider, useSpinner };
