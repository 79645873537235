import { SvgIconProps } from "@material-ui/core";
import { ReactNode } from "react";
import {
    WebApiChecklistStepAnnotation,
    WebApiChecklistStepAttachmentDescriptor,
    WebApiChecklistTemplateParameter,
} from "../Api";
import {
    PseudoChecklistCompletedStepId,
    PseudoChecklistCreateStepId,
    PseudoChecklistScheduleStepId,
    PseudoChecklistStartStepId,
} from "./Detail/PseudoChecklistStepIds";
import { ChecklistCurrentStepStatus, ChecklistOnlyStatus, ChecklistStepStatus } from "./Status";

export interface StepData {
    checklistId: string;
    checklistStepId: ChecklistStepId;
    needsScheduling: boolean;
    isCurrentStep: boolean;
    name: string;
    dueDate?: Date;
    completedDate?: Date;
    assignedToUserId?: string;
    completedByUserId?: string;
    annotations?: StepNote[];
    attachments: WebApiChecklistStepAttachmentDescriptor[];
    department: string;
    isLate: boolean;
    canComplete: boolean;
    canAssign: boolean;
    canAttachItem: boolean;
}

export type PseudoStepData = {
    checklistStepId:
        | typeof PseudoChecklistCreateStepId
        | typeof PseudoChecklistScheduleStepId
        | typeof PseudoChecklistStartStepId
        | typeof PseudoChecklistCompletedStepId;
    completedName?: string;
    subtitle?: ReactNode;
    expandedContent?: ReactNode;
} & Pick<StepData, "name" | "completedDate">;

export enum TemporalPosition {
    /** This step was completed in the past. */
    Past = "past",
    /** This step is current.  It may or may not be assigned. */
    Present = "present",
    /** This step is set in the future, because it has unfulfilled requirements. */
    Future = "future",
}

export const isPseudoStepData = (x: StepData | PseudoStepData): x is PseudoStepData => !("canComplete" in x);

export type StepNote = WebApiChecklistStepAnnotation;

export interface ChecklistProps {
    checklistId: ChecklistId;
    createdByUserId: string;
    scheduledByUserId?: string;
    currentStepId: string;
    name: string;
    createDate: Date;
    startDate?: Date;
    completedDate?: Date;
    dueDate?: Date;
    isScheduled: boolean;
    isStarted: boolean;
    steps: StepData[];
    parameters: WebApiChecklistTemplateParameter[];
}

export type InboxId = string;
export type HubPageId = string;
export type ChecklistId = string;
export type ChecklistStepId = string;

export interface BaseChecklistSummaryListItemProps {
    checklistStatus: ChecklistOnlyStatus | ChecklistCurrentStepStatus;
    checklistName: string;
    dueDate: Date;
    isLate: boolean;
    linkTo: string;
}

export interface CompleteChecklistSummaryListItemProps extends BaseChecklistSummaryListItemProps {
    completedDate: Date;
}

export interface IncompleteChecklistSummaryListItemProps extends BaseChecklistSummaryListItemProps {
    currentStepDescription: string;
    currentStepAssignee?: string;
}

export type ChecklistSummaryListItemProps =
    | CompleteChecklistSummaryListItemProps
    | IncompleteChecklistSummaryListItemProps;

export const isCompleteChecklistSummaryListItemProps = (
    o: ChecklistSummaryListItemProps
): o is CompleteChecklistSummaryListItemProps => "completedDate" in o;

export interface ChecklistStepTimelineItemSimpleProps {
    checklistStepStatus: ChecklistStepStatus;
    temporalPosition: TemporalPosition;
    icon: React.FC<SvgIconProps>;
    needsScheduling: boolean;
    isLate: boolean;
    title: ReactNode;
    subtitle?: ReactNode;
    scrollIntoView?: boolean;
    noConnector?: boolean;
    isChecklistScheduled: boolean;
}

export interface ChecklistStepTimelineItemExpandableProps extends ChecklistStepTimelineItemSimpleProps {
    expanded: boolean;
    setExpanded: (newValue: boolean) => void;
    expandedContent: ReactNode;
    actionsContent: ReactNode;
}

export const isExpandableProps = (
    x: ChecklistStepTimelineItemSimpleProps | ChecklistStepTimelineItemExpandableProps
): x is ChecklistStepTimelineItemExpandableProps => "expandedContent" in x;

export type ChecklistStepTimelineItemProps =
    | ChecklistStepTimelineItemSimpleProps
    | ChecklistStepTimelineItemExpandableProps;
