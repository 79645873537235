import NotificationsNoneFilledIcon from "@material-ui/icons/NotificationsNone";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import NotificationsNoneRoundedIcon from "@material-ui/icons/NotificationsNoneRounded";
import NotificationsNoneSharpIcon from "@material-ui/icons/NotificationsNoneSharp";
import NotificationsNoneTwoToneIcon from "@material-ui/icons/NotificationsNoneTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const notificationsNoneIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return NotificationsNoneFilledIcon;
        case IconStyle.Outlined:
            return NotificationsNoneOutlinedIcon;
        case IconStyle.Rounded:
            return NotificationsNoneRoundedIcon;
        case IconStyle.TwoTone:
            return NotificationsNoneTwoToneIcon;
        case IconStyle.Sharp:
            return NotificationsNoneSharpIcon;
    }
};

export const NotificationsNoneIcon = createIconComponent(notificationsNoneIconSelector);
