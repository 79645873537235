import { useCallback } from "react";
import { QueryObserverResult, useMutation, useQuery, UseQueryOptions } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSpinnerEffect } from "../SpinnerContext";
import { useAuthorizedFetch } from "./AuthorizedFetch";
import { downloadReponseToDisk } from "./downloadReponseToDisk";
import { encodeURIPath } from "./encodeURIPath";

function apiUrl(container: string, path: string) {
    return `blobs/${container}/${encodeURIPath(path)}`;
}

export function useBlob(
    container: string,
    path: string,
    queryOptions?: UseQueryOptions<string>
): QueryObserverResult<string> {
    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();

    const getBlob = useCallback(
        async (container: string, path: string) => {
            const url = apiUrl(container, path);
            const response = await authorizedFetch(url);

            if (response.status >= 200 && response.status < 300) {
                return (await response.text()) as string;
            } else {
                showError(ErrorMessage.FailedToLoadDataFile, response);
                throw new Error(ErrorMessage.FailedToLoadChecklist);
            }
        },
        [authorizedFetch, showError]
    );

    const query = useQuery(["blob", container, path], () => getBlob(container, path), {
        staleTime: 1 * 60 * 1000,
        ...queryOptions,
    });

    const url = apiUrl(container, path);
    useSpinnerEffect(query.isLoading, url);

    return query;
}

interface DownloadBlobParameters {
    container: string;
    path: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDownloadBlobMutation() {
    const { authorizedFetch } = useAuthorizedFetch();

    const downloadBlob = useCallback(
        async ({ container, path }: DownloadBlobParameters) => {
            const downloadUrl = apiUrl(container, path);
            const response = await authorizedFetch(downloadUrl, { query: { asAttachment: true } });
            await downloadReponseToDisk(response);
        },
        [authorizedFetch]
    );

    const mutation = useMutation(downloadBlob);

    return mutation;
}
