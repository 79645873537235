import { WebApiChecklistSummary } from "../Api";
import { ChecklistCurrentStepStatus, ChecklistOnlyStatus } from "./Status";

export function getStepStatusOfChecklistSummary(
    summary: WebApiChecklistSummary
): ChecklistOnlyStatus | ChecklistCurrentStepStatus {
    if (!summary.isScheduled) {
        return ChecklistOnlyStatus.ChecklistWaitingForSchedule;
    } else if (!summary.isStarted) {
        return ChecklistOnlyStatus.ChecklistWaitingForStart;
    } else if (summary.completedDate) {
        return ChecklistOnlyStatus.ChecklistCompleted;
    } else {
        const currentStep = summary.steps.find((s) => s.isCurrentStep);
        if (!currentStep) {
            console.log(
                "[getChecklistSummaryStatus]",
                `Failed to locate current step of checklist summary ${summary.checklistId}.`,
                summary
            );
        }
        return currentStep?.assignedToUserId
            ? ChecklistCurrentStepStatus.StepInProgress
            : ChecklistCurrentStepStatus.StepWaitingForAssignment;
    }
}
