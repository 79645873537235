import { Grid, Link, ListItem, ListItemText, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { formatDate } from "../../formatDate";
import {
    ChecklistSummaryListItemProps,
    CompleteChecklistSummaryListItemProps,
    IncompleteChecklistSummaryListItemProps,
    isCompleteChecklistSummaryListItemProps,
} from "../ChecklistTypes";
import { getChecklistStatusDisplayString } from "../getChecklistStatusDisplayString";
import { UserDisplayName } from "../UserDisplayName";
import { ChecklistSummaryListItemIcon } from "./ChecklistSummaryIcon";

const useStyles = makeStyles((theme) => ({
    listItem: {
        backgroundColor: theme.palette.background.paper,
    },
    listItemText: {},
    inline: {
        display: "inline",
    },
    propertyGrid: {},
    stepProperty: { display: "block" },
    stepPropertyLabel: {
        marginRight: theme.spacing(1),
        fontWeight: "bold",
    },
    link: {},
}));

const StepPropertyGridContainer: React.FC = ({ children }) => {
    const classes = useStyles();
    return (
        <Grid container spacing={0} className={classes.propertyGrid}>
            {children}
        </Grid>
    );
};

const StepPropertyGridItem: React.FC = ({ children }) => {
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <div className={classes.stepProperty}>{children}</div>
        </Grid>
    );
};

const StepPropertyLabel: React.FC = ({ children }) => {
    const classes = useStyles();
    return (
        <Typography variant="body2" display="inline" className={classes.stepPropertyLabel}>
            {children}:
        </Typography>
    );
};

const StepPropertyValue: React.FC<{ omitTypography?: boolean }> = ({ omitTypography, children }) =>
    omitTypography ? (
        <>{children}</>
    ) : (
        <Typography variant="body2" display="inline">
            {children}
        </Typography>
    );

const CompletedChecklistDetailsFC: React.FC<CompleteChecklistSummaryListItemProps> = ({ completedDate }) => (
    <StepPropertyGridContainer>
        <StepPropertyGridItem>
            <StepPropertyLabel>Completed</StepPropertyLabel>
            <StepPropertyValue>{formatDate(completedDate)}</StepPropertyValue>
        </StepPropertyGridItem>
    </StepPropertyGridContainer>
);

const CompletedChecklistDetails = React.memo(CompletedChecklistDetailsFC);

const IncompletedChecklistDetailsFC: React.FC<IncompleteChecklistSummaryListItemProps> = ({
    dueDate,
    checklistStatus,
    currentStepDescription,
    currentStepAssignee,
}) => (
    <StepPropertyGridContainer>
        <StepPropertyGridItem>
            <StepPropertyLabel>Needed by</StepPropertyLabel>
            <StepPropertyValue>{formatDate(dueDate)}</StepPropertyValue>
        </StepPropertyGridItem>
        <StepPropertyGridItem>
            <StepPropertyLabel>Status</StepPropertyLabel>
            <StepPropertyValue>{getChecklistStatusDisplayString(checklistStatus)}</StepPropertyValue>
        </StepPropertyGridItem>
        <StepPropertyGridItem>
            <StepPropertyLabel>Current step</StepPropertyLabel>
            <StepPropertyValue>{currentStepDescription}</StepPropertyValue>
        </StepPropertyGridItem>
        {currentStepAssignee !== undefined && (
            <StepPropertyGridItem>
                <StepPropertyLabel>Assigned to</StepPropertyLabel>
                <StepPropertyValue>
                    <UserDisplayName userId={currentStepAssignee} />
                </StepPropertyValue>
            </StepPropertyGridItem>
        )}
        {/* <div>
                {props.currentStepDescription}
                {" — "}
                {props.currentStepAssignee ? (
                    <UserDisplayName userId={props.currentStepAssignee} />
                ) : (
                    "UNASSIGNED"
                )}
            </div> */}
    </StepPropertyGridContainer>
);

const IncompletedChecklistDetails = React.memo(IncompletedChecklistDetailsFC);

export const ChecklistSummaryListItem: React.FC<ChecklistSummaryListItemProps> = (props) => {
    const { checklistName, checklistStatus, isLate, linkTo } = props;
    const classes = useStyles();
    const [hover, setHover] = useState(false);

    const extraContent = isCompleteChecklistSummaryListItemProps(props) ? (
        <CompletedChecklistDetails {...props} />
    ) : (
        <IncompletedChecklistDetails {...props} />
    );

    // We want the entire list item child to be a link, but only the checklist name exhibits the link styling.  That's
    // why we style it like a link, but it's not really an anchor element.
    const checklistNameContent = useMemo(
        () => (
            <Link component="span" underline={hover ? "always" : "none"}>
                {checklistName}
            </Link>
        ),
        [hover, checklistName]
    );

    return (
        <Link
            component={RouterLink}
            to={linkTo}
            underline="none"
            variant="inherit"
            color="textPrimary"
            className={classes.link}
            onMouseMove={() => setHover(true)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <ListItem className={classes.listItem}>
                <ChecklistSummaryListItemIcon {...{ checklistStatus, isLate }} />
                <ListItemText className={classes.listItemText}>
                    {checklistNameContent}
                    {extraContent}
                </ListItemText>
            </ListItem>
        </Link>
    );
};
