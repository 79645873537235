import EditFilledIcon from "@material-ui/icons/Edit";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const editIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return EditFilledIcon;
        case IconStyle.Outlined:
            return EditOutlinedIcon;
        case IconStyle.Rounded:
            return EditRoundedIcon;
        case IconStyle.TwoTone:
            return EditTwoToneIcon;
        case IconStyle.Sharp:
            return EditSharpIcon;
    }
};

export const EditIcon = createIconComponent(editIconSelector);
