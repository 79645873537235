import { Badge, BadgeProps, makeStyles } from "@material-ui/core";
import * as React from "react";
import { WarningIcon } from "../../Icons";

export const useStyles = makeStyles((theme) => ({
    unscheduledWarning: {
        color: theme.palette.warning.dark,
    },
}));

export const BadgeForScheduling: React.FC<{ needsScheduling: boolean } & Pick<BadgeProps, "badgeContent">> = ({
    needsScheduling,
    ...badgeProps
}) => {
    const classes = useStyles();

    return !needsScheduling ? (
        <>{badgeProps.children}</>
    ) : (
        <Badge badgeContent={<WarningIcon className={classes.unscheduledWarning} />} {...badgeProps} />
    );
};
