import DateFnsUtils from "@date-io/date-fns";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
    BooleanParameterTypeKey,
    DateListParameterTypeKey,
    DateParameterTypeKey,
    HtmlParameterTypeKey,
    NumberParameterTypeKey,
    StringParameterTypeKey,
    TextAreaParameterTypeKey,
    useChecklistTemplates,
    useCreateChecklistMutation,
    WebApiChecklistTemplateParameter,
    WebApiChecklistTemplateParameterBase,
} from "../Api";
import { diagnosticFeatureFlags } from "../diagnosticFeatureFlags";
import { SendIcon } from "../Icons";
import { usePageTitleContext } from "../PageTitleContext";
import { urlToChecklist } from "./checklistRouting";
import {
    getChecklistParameterDefaultValue,
    getYupObjectSchemaForChecklistParameterList,
    ParameterEditorField,
} from "./Parameters";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%",
    },
    formDivContainer: {
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    buttonBar: {
        marginTop: theme.spacing(2),
    },
}));

type Values = Record<string, unknown>;

type InlineParameter =
    | WebApiChecklistTemplateParameterBase<DateParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<StringParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<NumberParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<BooleanParameterTypeKey>;

type BlockParameter =
    | WebApiChecklistTemplateParameterBase<DateListParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<TextAreaParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<HtmlParameterTypeKey>;

const ChecklistCreationParametersFC: React.FC<{
    checklistTemplateId: string;
    checklistTemplateName: string;
    userQueriedParameters: WebApiChecklistTemplateParameter[];
}> = (props) => {
    const { userQueriedParameters, checklistTemplateId, checklistTemplateName } = props;
    const classes = useStyles();
    const createChecklistMutation = useCreateChecklistMutation();
    const history = useHistory();

    const formikInitialValues = userQueriedParameters.reduce(
        (acc, entry) => ({
            ...acc,
            [entry.name]: getChecklistParameterDefaultValue(entry.type),
        }),
        {} as Values
    );

    const validationSchema = useMemo(() => getYupObjectSchemaForChecklistParameterList(userQueriedParameters), [
        userQueriedParameters,
    ]);

    const handleSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => Promise<void> = useCallback(
        async (parameters, helpers) => {
            if (diagnosticFeatureFlags.mockChecklistCreation) {
                console.log("[ChecklistCreationParameters]", parameters);
                helpers.setSubmitting(false);
                return;
            }

            await createChecklistMutation.mutateAsync(
                {
                    checklistTemplateId,
                    checklistTemplateName,
                    parameters,
                },
                {
                    onSettled: (data, error) => {
                        console.log("[ChecklistCreationParameters]", "[mutate]", "[onSettled]", { data, error });
                        helpers.setSubmitting(false);
                        if (data && !error) {
                            history.push(urlToChecklist(data.id));
                        }
                    },
                }
            );
        },
        [checklistTemplateId, checklistTemplateName, createChecklistMutation, history]
    );

    const parametersGroupsByBlockiness = useMemo(
        () =>
            userQueriedParameters.reduce<(BlockParameter | InlineParameter[])[]>((acc, value) => {
                if (
                    value.type === DateListParameterTypeKey ||
                    value.type === TextAreaParameterTypeKey ||
                    value.type === HtmlParameterTypeKey
                ) {
                    return [...acc, value];
                } else if (acc.length === 0) {
                    return [[value]];
                } else {
                    const lastItem = acc[acc.length - 1];
                    if (Array.isArray(lastItem)) {
                        return [...acc.slice(0, acc.length - 1), [...lastItem, value]];
                    } else {
                        return [...acc, [value]];
                    }
                }
            }, []),
        [userQueriedParameters]
    );

    return (
        <>
            <Typography variant="body1" component="p" gutterBottom>
                Fill in the values below to start the checklist.
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Formik
                    initialValues={formikInitialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            {parametersGroupsByBlockiness.map((paramOrGroup, index) => {
                                if (Array.isArray(paramOrGroup)) {
                                    const group = paramOrGroup;
                                    return (
                                        <div className={classes.formDivContainer} key={index}>
                                            {group.map((param, nestedIndex) => (
                                                <ParameterEditorField
                                                    {...param}
                                                    key={nestedIndex}
                                                    autoFocus={index === 0 && nestedIndex === 0}
                                                />
                                            ))}
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className={classes.formDivContainer} key={index}>
                                            <ParameterEditorField {...paramOrGroup} autoFocus={index === 0} />
                                        </div>
                                    );
                                }
                            })}
                            <div className={classes.buttonBar}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    startIcon={<SendIcon />}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Start checklist
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </MuiPickersUtilsProvider>
        </>
    );
};
const ChecklistCreationParameters = React.memo(ChecklistCreationParametersFC);

export const ChecklistCreationParameterPage: React.FC = () => {
    const { template } = useParams<{ template: string }>();
    const { data } = useChecklistTemplates();
    const { setPageTitle } = usePageTitleContext();
    const checklistTemplate = useMemo(() => {
        return (data || []).find((t) => t.id === template);
    }, [data, template]);

    useEffect(() => {
        setPageTitle(checklistTemplate ? `- Start checklist - ${checklistTemplate.name}` : "- Start a checklist");
    }, [checklistTemplate, setPageTitle]);

    const userQueriedParameters = useMemo(() => {
        return checklistTemplate?.parameters.filter((param) => param.isUserQueried) || [];
    }, [checklistTemplate]);

    const checklistTemplateId = checklistTemplate?.id;
    const checklistTemplateName = checklistTemplate?.id;

    if (!(checklistTemplate && checklistTemplateId && checklistTemplateName)) {
        return null;
    }

    return <ChecklistCreationParameters {...{ userQueriedParameters, checklistTemplateId, checklistTemplateName }} />;
};
