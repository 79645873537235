import { useCallback } from "react";
import { QueryObserverResult, useQuery, UseQueryOptions } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { cleanseScreenDefinition, ScreenDefinition } from "../Screen/ScreenDefinition";
import { useSpinnerEffect } from "../SpinnerContext";
import { useAuthorizedFetch } from "./AuthorizedFetch";

interface Parameters {
    library: string;
    screen: string;
}

export function useScreenDefinition(
    parameters: Parameters,
    queryOptions?: UseQueryOptions<ScreenDefinition>
): QueryObserverResult<ScreenDefinition> {
    const { library, screen } = parameters;
    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();
    const apiUrl = ({ library, screen }: Parameters) => `screens/${library}/${screen}`;

    const getScreen = useCallback(
        async function (library: string, screen: string) {
            const response = await authorizedFetch(apiUrl({ library, screen }));
            if (response.status >= 200 && response.status < 300) {
                const screenDefinition = (await response.json()) as ScreenDefinition;
                const fixedScreenDefinition = cleanseScreenDefinition(screenDefinition);
                return fixedScreenDefinition;
            } else {
                showError(ErrorMessage.FailedToLoadChecklist, response);
                throw new Error(ErrorMessage.FailedToLoadChecklist);
            }
        },
        [authorizedFetch, showError]
    );

    const query = useQuery(["screen", library, screen], () => getScreen(library, screen), {
        staleTime: 5 * 60 * 1000,
        ...queryOptions,
    });

    useSpinnerEffect(query.isLoading, apiUrl(parameters));

    return query;
}
